import { getToken } from 'services/Authentication';
import { EnvApiUrls } from 'UI/constants/defaults';
import { FormSchema } from 'UI/constants/entityTypes';

import useFetchAssignmentSheetSchema from './useFetchAssignmentSheetSchema';

const useAssignmentSheetSchema = () => {
  const [state, actions] = useFetchAssignmentSheetSchema({
    schemaName: FormSchema.JobOrderDataSheet
  });

  return [
    {
      ...state,
      httpOptions: {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      },
      baseUrl: `${EnvApiUrls.MAIN}/`
    },
    {
      actions
    }
  ];
};

export default useAssignmentSheetSchema;
