export const COMMAND_CENTER_BASE_URL = '/command-center';

const ACTIVITY_BASE_PATH = 'activity';
const FINANCE_BASE_PATH = 'finance';
const INTERVIEWS_BASE_PATH = 'sendouts/interviews';
const INVENTORY_BASE_PATH = 'inventory';
const PRODUCTIVITY_BASE_PATH = 'productivity';

export const ENDPOINTS = {
  overallPerformance: 'command-center/overall-performance',

  // Activity
  activityHistory: `${ACTIVITY_BASE_PATH}/history`,
  activityPlacementsHistory: `${ACTIVITY_BASE_PATH}/placements/history`,
  activityPlacementsSummary: `${ACTIVITY_BASE_PATH}/placements/summary`,
  activitySendoutsHistory: `${ACTIVITY_BASE_PATH}/sendouts/history`,
  activitySummary: `${ACTIVITY_BASE_PATH}/summary`,
  activityTrendsHistory: `${ACTIVITY_BASE_PATH}/trends/history`,
  activityWeek: `${ACTIVITY_BASE_PATH}/week`,
  activityPhoneCallsHistory: `${ACTIVITY_BASE_PATH}/phones/calls/hourly-trends-history`,
  activityPhoneCallsSummary: `${ACTIVITY_BASE_PATH}/phones/calls/summary`,
  recruitersActivity: '/recruiters/:userId/activities',

  // Interviews
  debriefingCalls: `${INTERVIEWS_BASE_PATH}/debriefing-calls`,
  preparationCalls: `${INTERVIEWS_BASE_PATH}/preparation-calls`,

  // Follow Up Tasks
  unconvertedSendovers: 'sendovers/unconverted',
  unsignedAgreements: 'fee-agreements/unsigned',

  // Users
  tenures: 'users/tenures',

  // Inventory
  inventorySummary: `${INVENTORY_BASE_PATH}/summary`,
  inventorySummaryByType: `${INVENTORY_BASE_PATH}/:type/summary`,

  // Hot Inventory
  hotInventoryStatsSummary: 'urgency-verification/inventories/estimated-comissions',
  hotInventoryCandidates: 'urgency-verification/candidates',
  hotInventoryJobOrders: 'urgency-verification/job-orders',

  // Finance
  financeSummary: `${FINANCE_BASE_PATH}/summary`,
  financeRecruiterSummary: `${FINANCE_BASE_PATH}/recruiters/summary`,
  financeSummaryHistory: `${FINANCE_BASE_PATH}/summary/history`,
  financeHealthSummary: `${FINANCE_BASE_PATH}/health/summary`,

  // Productivity
  productivitySummary: `${PRODUCTIVITY_BASE_PATH}/summary`,
  productivityRecruiter: `${PRODUCTIVITY_BASE_PATH}/recruiter`,
  productivityRecruiterPerformance: `${PRODUCTIVITY_BASE_PATH}/recruiters/performance`,
  productivityTeam: `${PRODUCTIVITY_BASE_PATH}/team`,
  productivityWeek: `${PRODUCTIVITY_BASE_PATH}/week`,
  productivityTotals: `${PRODUCTIVITY_BASE_PATH}/totals`
};
