// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { getId, REQUIRED_VALIDATION } from 'UI/utils';

import { FormFieldsMap } from '../CompanyForm/fields';

import PhoneNumbersListing from './PhoneNumbersListing/PhoneNumbersListing';
import PhoneFieldsRow from './PhoneFieldsRow';
import { useStyles } from './styles';
import {
  checkDuplicatePhoneEntries,
  DEFAULT_PHONE_ROW,
  formatPhoneNumbers,
  PHONE_FORMAT_MODES,
  PHONE_ROW_PREFIX
} from './utils';

type PhoneNumbersFormProps = {
  isEditing?: boolean,
  disabled?: boolean,
  isRequired?: boolean
};

const PhoneNumbersForm = ({ isEditing, disabled, isRequired }: PhoneNumbersFormProps) => {
  const classes = useStyles();

  const { register, unregister, setValue, getValues, watch } = useFormContext();

  const formValues = watch();

  const phoneNumbers =
    formValues[FormFieldsMap.Phones.key]?.length > 0
      ? formValues[FormFieldsMap.Phones.key]
      : [DEFAULT_PHONE_ROW];

  const defaultPhoneId = phoneNumbers.find(item => item.isDefault)?.id || null;

  const defaultPhoneNumber = getValues(`${PHONE_ROW_PREFIX.Phone}${defaultPhoneId}`);

  const defaultPhoneExt = getValues(`${PHONE_ROW_PREFIX.Ext}${defaultPhoneId}`);

  const handleRadioChange = event => {
    const id = parseInt(event.target.value, 10);
    const newValues = phoneNumbers.map(item => ({
      ...item,
      isDefault: item.id === id
    }));
    setValue(FormFieldsMap.Phones.key, newValues);
    setValue(PHONE_ROW_PREFIX.IsDefault, id);
  };

  const addPhoneRow = () => {
    const newPhone = { ...DEFAULT_PHONE_ROW, id: getId(), isDefault: false };
    const currentPhones = getValues(FormFieldsMap.Phones.key);
    setValue(FormFieldsMap.Phones.key, [...currentPhones, newPhone]);
  };

  const handleRemovePhoneRow = (row: Phone) => {
    const newValues = [...phoneNumbers];
    const isDefaultPhone = row.isDefault;
    const indexToRemove = newValues.findIndex(phone => phone.id === row.id);

    if (isDefaultPhone) {
      newValues[0].isDefault = true;
      setValue(PHONE_ROW_PREFIX.IsDefault, newValues[0].id);
    }
    newValues.splice(indexToRemove, 1);

    setValue(
      FormFieldsMap.Phones.key,
      getValues(FormFieldsMap.Phones.key).filter(each => each.id !== row.id)
    );
  };

  const areDuplicatePhones = checkDuplicatePhoneEntries(
    formatPhoneNumbers(formValues, PHONE_FORMAT_MODES.submit)
  );

  useEffect(() => {
    register({ name: FormFieldsMap.Phone.key });
    register({ name: FormFieldsMap.Ext.key });
    register({ name: PHONE_ROW_PREFIX.IsDefault });

    setValue(PHONE_ROW_PREFIX.IsDefault, defaultPhoneId);

    return () => {
      unregister(PHONE_ROW_PREFIX.IsDefault);
      unregister(FormFieldsMap.Phone.key);
    };
  }, [defaultPhoneId, register, setValue, unregister]);

  useEffect(() => {
    setValue(FormFieldsMap.Phone.key, defaultPhoneNumber);
    setValue(FormFieldsMap.Ext.key, defaultPhoneExt);
  }, [defaultPhoneExt, defaultPhoneNumber, setValue, defaultPhoneId]);

  return (
    <Grid container item className="creation-form-layout">
      <Grid container item className={classes.titleContainer}>
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1">
              {strings.inventory.phoneNumbers.component.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container>
        <RadioGroup
          aria-label="default phone"
          name="defaultPhoneId"
          value={defaultPhoneId}
          onChange={handleRadioChange}
          className={classes.phoneRow}
        >
          <PhoneNumbersListing
            phoneNumbers={phoneNumbers}
            renderRow={({ index, item, catalogues }) => {
              return (
                <PhoneFieldsRow
                  key={item.id}
                  item={item}
                  onRemovePhoneRow={handleRemovePhoneRow}
                  rowsCount={phoneNumbers.length}
                  index={index}
                  isEditing={isEditing}
                  disabled={disabled}
                  isRequired={isRequired}
                  catalogues={catalogues}
                />
              );
            }}
          />
        </RadioGroup>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {areDuplicatePhones && (
            <>
              <Typography variant="caption" color="error">
                {strings.shared.validations.duplicatePhones}
              </Typography>
              <br />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <input
            ref={register({
              ...REQUIRED_VALIDATION,
              validate: value => JSON.parse(value) === true
            })}
            type="hidden"
            name="validPhones"
            value={!areDuplicatePhones}
          />
        </Grid>
        <Grid item xs={12}>
          <FPActionButton
            text={strings.inventory.phoneNumbers.component.addNewPhoneBtn}
            variant="outlined"
            type="button"
            onClick={addPhoneRow}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

PhoneNumbersForm.defaultProps = {
  isEditing: false,
  disabled: false,
  isRequired: false
};

export default PhoneNumbersForm;
