import strings from 'strings';

import { CallsRelatedContactsDrawerContent } from './CallsRelatedContactsDrawerContent';

const { relatedContacts } = strings.commandCenter.drawers;

export const RelatedContactsConfig = {
  title: relatedContacts.title,
  component: CallsRelatedContactsDrawerContent
};
