import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Toolbar } from 'features/command-center/components/shared';
import { StatsCard } from 'features/command-center/components/shared/StatsCard';
import { extractObjectFromDataTable, getColumnsToRender } from 'hooks/datatable';
import DataTable from 'UI/components/organisms/DataTable';

import useActivitiesListing from '../hooks/useActivitiesListing';

import ActivitiesForm from './ActivitiesForm';
import { useTableStyles } from './ActivitiesListing.styles';

export const ActivitiesListing = ({
  enableCustomFilters,
  entity,
  entityConfig,
  endpoint,
  filters,
  columnsDefinitions,
  canExpandRow
}) => {
  const classes = useTableStyles();

  const {
    dataTable,
    finalData,
    handleExpansion,
    handleFormCancel,
    handleSubmitSucceed,
    expandedRows,
    outcomeOptions
  } = useActivitiesListing({
    columns: entityConfig?.columns,
    pageKey: entityConfig?.persistenceKey,
    formatResponse: entityConfig?.formatResponse,
    orderByOptions: entityConfig?.orderBy,
    endpoint,
    initialFilters: filters,
    entity
  });

  const { entityIdKey = '', title = '' } = entityConfig;

  const {
    columnPreferences,
    count,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersToggle,
    handleFiltersChange,
    handlePeriodChange,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState
  } = dataTable;

  const { isLoading, showWholeSkeleton, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const finalColumns = getColumnsToRender(columns);

  const renderExpandableRow = (rowData, rowMeta) => {
    const colSpan = rowData.length + 1;
    const item = extractObjectFromDataTable(
      columnsDefinitions,
      [
        entityIdKey,
        'activityLogId',
        'activityOutcomeComments',
        'activityOutcomeTypeId',
        'activityOutcomeObj'
      ],
      rowData
    );
    return canExpandRow ? (
      <TableRow aria-label="collection actions">
        <TableCell colSpan={colSpan}>
          <ActivitiesForm
            outcomeOptions={outcomeOptions}
            activityId={item.activityLogId}
            outcome={item.activityOutcomeObj}
            outcomeComments={item.activityOutcomeComments}
            entityId={item?.[entityIdKey]}
            entity={entity}
            onCancel={handleFormCancel}
            onSubmitSucceed={handleSubmitSucceed}
            rowIndex={rowMeta.rowIndex}
          />
        </TableCell>
      </TableRow>
    ) : null;
  };

  return (
    <>
      <StatsCard header={title} paddingContent={0}>
        <DataTable
          className={classes.denseCell}
          columnOrder={columnOrder?.length ? columnOrder : undefined}
          columns={finalColumns}
          components={{ TableToolbar: Toolbar }}
          count={count}
          data={finalData}
          draggableColumns={{ enabled: true }}
          enableCustomFilters={enableCustomFilters}
          expandableRows={canExpandRow}
          expandableRowsHeader={false}
          elevation={0}
          filter={false}
          isExpandable
          isRowExpandable={() => true}
          loading={showWholeSkeleton}
          onColumnDisplayClick={handleColumnDisplayChange}
          onColumnOrderChange={handleColumnOrderChange}
          onColumnSortChange={handleColumnSortChange}
          onFiltersChange={handleFiltersChange}
          onMenuToggle={handleFiltersToggle}
          onPageClick={handlePageChange}
          onPeriodChange={handlePeriodChange}
          onPerPageClick={handlePerPageChange}
          onRowExpansionChange={handleExpansion}
          onSearchTextChange={handleKeywordChange}
          onToggleFilters={handleFiltersToggle}
          page={page}
          refreshing={isLoading}
          rowsExpanded={expandedRows}
          rowsPerPage={perPage}
          searchText={keyword}
          selectableRows="none"
          sortOrder={{ name: orderBy, direction }}
          renderExpandableRow={renderExpandableRow}
        />
      </StatsCard>
    </>
  );
};
