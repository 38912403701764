// @flow
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import usePageTitle from 'hooks/usePageTitle';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TitleLabel from 'UI/components/atoms/TitleLabel';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { AppName } from 'UI/constants/defaults';
import { UIStatus } from 'UI/constants/status';
import { SvgNoResults } from 'UI/res';

import { useStyles } from './styles';

type DualPanelLayoutProps = {
  leftPanel: {
    header: {
      title: string,
      Actions?: React.ReactNode,
      Header?: React.ReactNode
    },
    Content: React.ReactNode
  },
  rightPanel: {
    header: {
      HeaderTitle: React.ReactNode,
      Actions?: React.ReactNode
    },
    Content: React.ReactNode
  },

  uiStatus?: string,
  error?: {
    title?: string,
    subtitle?: string,
    onClick?: () => void
  }
};

const DualPanelLayout = ({ uiStatus, leftPanel, rightPanel, error }: DualPanelLayoutProps) => {
  const classes = useStyles();
  const [isExpandedLayout, setIsExpandedLayout] = useState(true);

  const { title, Header: LeftHeader, Actions: LeftActions } = leftPanel.header;
  const { HeaderTitle: RightHeaderTitle, Actions: RightActions } = rightPanel.header;

  usePageTitle({ title: `${AppName} | ${title}` });

  const toggleExpandedLayout = () => setIsExpandedLayout(prev => !prev);

  const handleClickRetry = () => {
    error?.onClick && error.onClick();
  };

  return (
    <ContentPageLayout mode="customLayout">
      {uiStatus === UIStatus.Error ? (
        <EmptyPlaceholder
          title={error?.title || strings.shared.errors.serverError.title}
          subtitle={error?.subtitle || strings.shared.errors.serverError.subtitle}
          customEmptyState={<SvgNoResults height={400} />}
        >
          {error?.onClick && <FPActionButton text="Try again" onClick={handleClickRetry} />}
        </EmptyPlaceholder>
      ) : (
        <Grid container>
          {isExpandedLayout && (
            <Grid item xs={4}>
              <div className={classes.panelContainer}>
                <div className={classes.leftHeaderContainer}>
                  <div className={classes.leftHeader}>
                    <TitleLabel text={title} />

                    {LeftActions && <div>{LeftActions}</div>}
                  </div>

                  {LeftHeader && LeftHeader}
                </div>

                <div className={classes.leftContent}>{leftPanel?.Content}</div>
              </div>
            </Grid>
          )}

          <Grid item xs={isExpandedLayout ? 8 : 12}>
            <div className={classes.panelContainer}>
              <div className={classes.rightHeaderContainer}>
                <div className={classes.rightHeader}>
                  <FPIconButton aria-label="show/hide message list" onClick={toggleExpandedLayout}>
                    <MenuIcon color="primary" />
                  </FPIconButton>
                  {RightHeaderTitle && (
                    <div className={classes.rightHeaderTitle}>{RightHeaderTitle}</div>
                  )}
                </div>

                {RightActions && <div>{RightActions}</div>}
              </div>

              {rightPanel.Content}
            </div>
          </Grid>
        </Grid>
      )}
    </ContentPageLayout>
  );
};

export default DualPanelLayout;
