// @flow
import { useState } from 'react';
import useProfile from 'hooks/profileActions';
import useFetchJsonSchema from 'hooks/useFetchJsonSchema';
import isEqual from 'lodash/isEqual';
import { useUserContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import { canUserOrStaffEditEntity } from 'services/Authorization';
import { CandidateIncludes } from 'services/includes';
import { EntityType, FormSchema } from 'UI/constants/entityTypes';
import { RequestStatus, UIStatus } from 'UI/constants/status';

import useCandidateDataSheetForm from './useCandidateDataSheetForm';
import useFetchCandidateDataSheet from './useFetchCandidateDataSheet';

type UseCandidateDataSheetParams = {
  profileId: string,
  history: {
    push: Function
  }
};
const useCandidateDataSheet = ({ profileId, history }: UseCandidateDataSheetParams) => {
  const [isDraftDialogOpen, setIsDraftDialogOpen] = useState(false);
  const [currentUser] = useUserContext();
  const { status: profileRequestStatus, results: profile } = useProfile({
    entity: EntityType.Candidate,
    profileId,
    includes: CandidateIncludes
  });

  const toggleDraftDialog = (value: boolean) => () => {
    value !== isDraftDialogOpen && setIsDraftDialogOpen(value);
  };

  const {
    dataSheet,
    status: candidateDataSheetStatus,
    updateDataSheet
  } = useFetchCandidateDataSheet(profileId);

  const { status: schemaStatus, schemaData } = useFetchJsonSchema(
    FormSchema.CandidateDataSheetFull
  );

  const uiStatus = (function getStatus() {
    const isLoading = [profileRequestStatus, candidateDataSheetStatus, schemaStatus].some(
      requestStatus => requestStatus === RequestStatus.Loading
    );

    const isThereError = [profileRequestStatus, schemaStatus].some(
      requestStatus => requestStatus === UIStatus.Error
    );

    if (isThereError) {
      return UIStatus.Error;
    }

    if (isLoading) {
      return UIStatus.Loading;
    }

    return UIStatus.Default;
  })();

  const canUserEdit = canUserOrStaffEditEntity(currentUser, profile) || !profile;

  const {
    formData,
    formStatus,
    commonStyledFormProps,
    handleOnChange,
    getHandleOnSubmit,
    handleOnSave,
    canSave,
    getHandleOnError
  } = useCandidateDataSheetForm({
    blueSheet:
      profileRequestStatus === UIStatus.Success && candidateDataSheetStatus !== UIStatus.Loading
        ? profile?.blueSheets?.[0]
        : null,
    dataSheet,
    blueSheetStatus: profile?.status,
    updateDataSheet,
    toggleDraftDialog
  });

  const finalSchemaData =
    dataSheet && candidateDataSheetStatus === RequestStatus.Success ? dataSheet.schema : schemaData;

  const navigateToProfile = () =>
    history.push(EntityRoutes.CandidateProfile.replace(':id', profileId));

  const handleOnSubmit = getHandleOnSubmit({
    candidate: profile,
    schemaVersion: finalSchemaData?.version,
    navigateToProfile
  });

  const handleOnError = getHandleOnError({
    candidate: profile
  });

  const handleCancel = isEqual(dataSheet?.data, formData) ? navigateToProfile : toggleDraftDialog(true);

  const handleOnConfirm = async ok => {
    ok && (await handleOnSave({ formData }));
  };

  return {
    uiStatus,
    parsedSchemaData: finalSchemaData,
    formData,
    commonStyledFormProps,
    dataSheetProgress: dataSheet?.progress,
    canSave,
    isSaving: formStatus === UIStatus.Saving,
    isDraftDialogOpen,
    canUserEdit,
    handleOnChange,
    handleOnSubmit,
    handleClose: navigateToProfile,
    handleOnConfirm,
    handleCancel,
    handleOnSave,
    handleOnError,
    toggleDraftDialog
  };
};

export default useCandidateDataSheet;
