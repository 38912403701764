// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { showAlert } from 'actions/app';
import { EntityRoutes } from 'routes/constants';
import { addToSearchProject } from 'services/searchProjects';
import strings from 'strings';
import type { AddToSearchProjectDrawerProps } from 'types/app';
import ProjectList from 'UI/components/organisms/ProjectList';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';

import { useStyles } from './styles';

const { addItemsToSearchProject: formCopies } = strings.searchProjects.forms;

const AddToSearchProjectDrawer = ({
  bulkId = null,
  createdFrom = 'other',
  currentSearchProjectId,
  itemsToAdd,
  moveCopiedItems,
  onAddItemsToSP,
  onClose,
  onRefreshSearchProjectsTab,
  open,
  queryParams,
  shouldRedirectToSearchProjectPreview = true
}: AddToSearchProjectDrawerProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedSearchProjectId, setSelectedSearchProjectId] = useState(null);
  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const handleAddToSearchProjectClick = searchProj => setSelectedSearchProjectId(searchProj);

  const handleSubmit = async e => {
    e.preventDefault();
    setUiState({ isSaving: true, isSuccess: false });
    const { alert, success } = await addToSearchProject({
      bulkId,
      createdFrom,
      currentSearchProjectId,
      itemsToAdd,
      moveCopiedItems,
      queryParams,
      selectedSearchProjectId
    });
    dispatch(showAlert(alert));
    setUiState({ isSaving: false, isSuccess: success });

    if (!success) return;
    onAddItemsToSP && onAddItemsToSP(true, 'add');
    !shouldRedirectToSearchProjectPreview &&
      onRefreshSearchProjectsTab &&
      onRefreshSearchProjectsTab(false);

    shouldRedirectToSearchProjectPreview &&
      setTimeout(() => {
        history.push(EntityRoutes.SearchProjectPreview.replace(':id', selectedSearchProjectId));
      }, 1);
  };

  return (
    <DrawerContentLayout
      title={formCopies.title}
      drawerProps={{
        open,
        classes: { paper: classes.drawerRoot }
      }}
      onSubmit={handleSubmit}
      onClose={onClose}
      primaryProps={{
        disabled: !selectedSearchProjectId || uiState.isSaving
      }}
      uiState={uiState}
      contentProps={{
        className: classes.draweContent
      }}
    >
      <ProjectList
        itemsToAdd={itemsToAdd}
        queryParams={queryParams}
        onRowClick={handleAddToSearchProjectClick}
        createdFrom={createdFrom}
        searchProjectId={currentSearchProjectId}
      />
    </DrawerContentLayout>
  );
};

AddToSearchProjectDrawer.defaultProps = {
  onAddItemsToSP: undefined,
  shouldRedirectToSearchProjectPreview: true
};

export default AddToSearchProjectDrawer;
