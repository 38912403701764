// @flow

import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from 'actions/app';
import useDatatable, {
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import useMultipleSelection from 'hooks/multipleSelection';
import { rowClickBuilder } from 'hooks/profileActions';
import { useSearchProject } from 'hooks/searchProject';
import { useQuickViewPreview } from 'hooks/useQuickViewPreview';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { SEARCH_PROJECT_CREATED_FROM_VARIANTS } from 'services/searchProjects';
import { When } from 'UI/components/atoms/When';
import DataTable from 'UI/components/organisms/DataTable';
import { Endpoints } from 'UI/constants/endpoints';
import { SearchProjectEntities, SearchProjectItemTypeIds } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getProfileQuickViewByEntity } from 'UI/constants/LogRocketCustomEvents';
import { useFixedDatatableStyles } from 'UI/globalStyles/DataTableStyles';
import { formatResponseBuilder } from 'UI/utils/inventory';

import FPQuickView from '../inventoryProfiles/FPQuickView';

import ActionsToolbar from './ActionsToolbar/ActionsToolbar';
import { getColumnsDefinitionByStatType } from './StatsColumns';

const OrderByOptions = {
  column: 'timestamp',
  direction: 'desc'
};

const SearchProjectTypes = {
  [SearchProjectItemTypeIds.Candidate]: SearchProjectEntities.Candidate,
  [SearchProjectItemTypeIds.HiringAuthority]: SearchProjectEntities.HiringAuthority,
  [SearchProjectItemTypeIds.Name]: SearchProjectEntities.Contact,
  [SearchProjectItemTypeIds.NameCandidate]: SearchProjectEntities.Contact,
  [SearchProjectItemTypeIds.NameHA]: SearchProjectEntities.Contact,
  [SearchProjectItemTypeIds.Company]: SearchProjectEntities.Company
};

const TableSelectionModes = {
  None: 'none',
  Multiple: 'multiple'
};

const keywordsDefinition = [
  {
    entry: 'id',
    valuesToAttach: [{ path: 'item_id' }]
  },
  {
    entry: 'item_search_project_type',
    valuesToAttach: [{ path: 'search_project_type' }]
  }
];

const handleResponseChange = response => formatResponseBuilder(response)(keywordsDefinition);

type StatsListingProps = {
  emailId: number,
  quickViewHandlerProps: {
    onQuickViewClick: () => {},
    enableNavigationControls: boolean,
    shouldCloseQuickView: boolean
  },
  status: String
};

const StatsListing = ({
  emailId,
  quickViewHandlerProps: { onQuickViewClick, enableNavigationControls },
  status
}: StatsListingProps) => {
  const classes = useFixedDatatableStyles();

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const hasSearchProjectActionsEnabled = checkIfFeatureFlagEnabled(
    FeatureFlags.BulkStatsTablesActions
  )
    ? TableSelectionModes.Multiple
    : TableSelectionModes.None;

  const pageKey = `bulkStats-${status}`;
  const columnsDefinitions = getColumnsDefinitionByStatType(pageKey);
  const initialPreferences = getColumnPreferences(pageKey, 0, OrderByOptions, columnsDefinitions);

  const dispatch = useDispatch();

  const extendParams = useCallback(
    () => ({
      statuses: status
    }),
    [status]
  );

  const {
    columnPreferences,
    count,
    data,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState,
    queryParams
  } = useDatatable({
    columnsDefinitions,
    endpoint: Endpoints.BulkEmailMetricsListing.replace(/:id/, emailId),
    entityName: 'Search Project Preview',
    formatResponse: handleResponseChange,
    initialPreferences,
    orderByOptions: OrderByOptions,
    paramsExtender: extendParams
  });

  const { columns, orderBy, direction } = columnPreferences;
  const { isLoading, showWholeSkeleton, page, perPage, keyword } = listState;

  const {
    entityId,
    quickViewEntity,
    setUiState,
    quickViewState,
    toggleQuickViewPreview,
    handleQuickViewPreview
  } = useQuickViewPreview({
    columns,
    data
  });

  const quickViewClick = item => {
    handleQuickViewPreview(item);

    onQuickViewClick && onQuickViewClick(true);
  };

  const listViewPreset = {
    name: 'view',
    onClick: rowClickBuilder({
      columns,
      data,
      callback: quickViewClick,
      enableTracking: true,
      logRocketEvent: getProfileQuickViewByEntity(quickViewEntity)
    })
  };

  useEffect(() => {
    !enableNavigationControls && setUiState({ isQuickViewOpen: false });
  }, [setUiState, enableNavigationControls]);

  const tableActions = buildRowActionsRendererDefinition({
    actions: [listViewPreset],
    namespace: 'Bulk'
  });

  const finalColumns = getColumnsToRender([...columns, tableActions]);

  const dataForSelection = useMemo(
    () =>
      data.map(item => ({
        id: item.item_id,
        type: SearchProjectTypes[item.search_project_type],
        type_id: item.search_project_type
      })),
    [data]
  );

  const { searchProject } = useSelector(state => state.bulkEmail.domain.bulkEmailInPreview.data);

  const {
    handleRowSelection,
    selectedIndexesInPage,
    totalSelected,
    filteredItems,
    multiSelectComponents
  } = useMultipleSelection({ data: dataForSelection, count, perPage });

  const { SearchProjectAction, SearchProjectForms, handleRemoveFromSearchProject } =
    useSearchProject({
      filteredItems,
      queryParams,
      enableMoveActions: true,
      createdFrom: SEARCH_PROJECT_CREATED_FROM_VARIANTS.bulkMetrics,
      bulkId: emailId
    });

  const handleOnRemoveSuccess = () =>
    dispatch(
      showAlert({
        severity: 'success',
        title: 'Item Removed Succesfuly'
      })
    );

  const handleCloseQuickView = () => {
    toggleQuickViewPreview();
    onQuickViewClick && onQuickViewClick(false);
  };

  return (
    <>
      <DataTable
        isExpandable
        elevation={0}
        className={classes.fixedList}
        classes={{
          responsiveBase: `responsiveBase ${data.length === 0 ? 'emptyDataTable' : ''}`
        }}
        columns={finalColumns}
        count={count}
        data={data}
        filter={false}
        loading={showWholeSkeleton}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnSortChange={handleColumnSortChange}
        onPageClick={handlePageChange}
        onPerPageClick={handlePerPageChange}
        onSearchTextChange={handleKeywordChange}
        onToggleFilters={handleFiltersToggle}
        onColumnOrderChange={handleColumnOrderChange}
        page={page}
        print={false}
        selectableRows={hasSearchProjectActionsEnabled}
        refreshing={isLoading}
        responsive="standard"
        rowsPerPage={perPage}
        searchText={keyword}
        onRowSelectionChange={handleRowSelection}
        rowsSelected={selectedIndexesInPage}
        CustomToolbar={
          <ActionsToolbar
            onRemoveClick={handleRemoveFromSearchProject}
            onRemoveSuccess={handleOnRemoveSuccess}
            searchProjectIdInPreview={searchProject.id}
            searchProjectNameInPreview={searchProject.name}
            totalSelected={totalSelected}
          >
            <SearchProjectAction />
          </ActionsToolbar>
        }
        components={multiSelectComponents}
        selectToolbarPlacement="none"
        sortOrder={{ name: orderBy, direction }}
      />
      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          onClose={handleCloseQuickView}
          entityType={quickViewEntity}
          itemsToNavigate={data}
          quickViewEntityRoleProps={{ roleKey: 'item_id' }}
          id={entityId}
        />
      </When>
      <SearchProjectForms bulkEmailId={emailId} searchProject={searchProject} />
    </>
  );
};

StatsListing.defaultProps = {
  quickViewHandlerProps: {
    onQuickViewClick: undefined,
    enableNavigationControls: false
  }
};

export default StatsListing;
