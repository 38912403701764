// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { showAlert } from 'actions/app';
import { EntityRoutes } from 'routes/constants';
import { Permissions, userHasPermission } from 'services/Authorization';
import {
  createSearchProject,
  SEARCH_PARAMS_KEYS,
  SEARCH_PROJECT_CREATED_FROM_VARIANTS,
  updateSearchProject
} from 'services/searchProjects';
import { getAllSearchProjectsFolders } from 'services/searchProjects/Folders';
import strings from 'strings';
import type { SearchProjectFormDrawerPropTypes } from 'types/app';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import TreeViewSelector from 'UI/components/molecules/TreeViewSelector/TreeViewSelector';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import {
  DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS,
  SEARCH_PROJECT_ROOT_FOLDERS,
  SearchProjectPrivacyScopes
} from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

import { FORM_FIELDS_MAP } from './fields';
import SearchProjectExtractOptions from './SearchProjectExtractOptions';

const { searchProject: formCopies } = strings.searchProjects.forms;

const PRIVACY_RADIO_OPTIONS = [
  SearchProjectPrivacyScopes.private,
  SearchProjectPrivacyScopes.public
];

const getFinalTitle = ({ isSearchProjectBeingEdited, shouldExtractCompanyContacts }) => {
  if (shouldExtractCompanyContacts) return formCopies.titles.extractCompaniesContacts;

  return isSearchProjectBeingEdited ? formCopies.titles.edit : formCopies.titles.create;
};

const getSearchProjectBaseInitialValues = ({
  searchProject,
  isSearchProjectBeingEdited,
  shouldExtractCompanyContacts
}) => {
  const isSearchProjectSet = searchProject && typeof searchProject === 'object';
  const defaultPrivacyScope = SearchProjectPrivacyScopes.private.id;
  if (isSearchProjectSet) {
    if (isSearchProjectBeingEdited)
      return {
        name: searchProject.name,
        privacyScope: SearchProjectPrivacyScopes.getPrivacyState(searchProject.isPrivate).id
      };
    if (shouldExtractCompanyContacts)
      return {
        name: `${searchProject.name} - ${formCopies.sections.extractCompaniesContacts.nameSufix}`,
        privacyScope: defaultPrivacyScope
      };
  }

  return { name: null, privacyScope: defaultPrivacyScope };
};

const SearchProjectFormDrawer = ({
  bulkId = null,
  createdFrom = null,
  isSearchProjectBeingEdited = false,
  itemsToAdd = null,
  onClose,
  onSubmitSearchProject = null,
  queryParams = null,
  searchProject = null,
  shouldExtractCompanyContacts = false,
  shouldMoveCopiedItems = false
}: SearchProjectFormDrawerPropTypes) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const canMarkAsMasterList = userHasPermission(Permissions.SearchProjects.CanMarkAsMasterList);
  const shouldShowMasterListFields =
    canMarkAsMasterList && hasFeatureFlag(FeatureFlags.SearchProjectsMasterListFields);
  const isFoldersFeatureActive = hasFeatureFlag(FeatureFlags.SearchProjectsFolders);
  const finalTitle = getFinalTitle({ isSearchProjectBeingEdited, shouldExtractCompanyContacts });
  const formDefaultValues = useMemo(() => {
    const defaultValues = {};
    const baseInitialValues = getSearchProjectBaseInitialValues({
      searchProject,
      isSearchProjectBeingEdited,
      shouldExtractCompanyContacts
    });
    defaultValues[FORM_FIELDS_MAP.name.key] = baseInitialValues.name;
    defaultValues[FORM_FIELDS_MAP.privacyScope.key] = baseInitialValues.privacyScope;

    if (shouldShowMasterListFields) {
      if (isSearchProjectBeingEdited) {
        defaultValues[FORM_FIELDS_MAP.isMasterList.key] = searchProject.isMasterList;
        defaultValues[FORM_FIELDS_MAP.geoPolygon.key] = searchProject.geoPolygon
          ? searchProject.geoPolygon.polygon
          : null;
      } else {
        defaultValues[FORM_FIELDS_MAP.isMasterList.key] = false;
        defaultValues[FORM_FIELDS_MAP.geoPolygon.key] = null;
      }
    }

    if (isFoldersFeatureActive) {
      defaultValues[FORM_FIELDS_MAP.parentFolder.key] = isSearchProjectBeingEdited
        ? searchProject?.folder
        : SEARCH_PROJECT_ROOT_FOLDERS.mine;
    }

    return defaultValues;
  }, [
    shouldShowMasterListFields,
    isFoldersFeatureActive,
    isSearchProjectBeingEdited,
    searchProject,
    shouldExtractCompanyContacts
  ]);

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false
  });

  const form = useForm({
    defaultValues: formDefaultValues
  });
  const { errors, handleSubmit, register, setValue, watch } = form;
  const scopeWatcher = watch(FORM_FIELDS_MAP.privacyScope.key);

  useEffect(() => {
    register({ name: FORM_FIELDS_MAP.privacyScope.key });
  }, [register]);

  useEffect(() => {
    if (shouldShowMasterListFields) {
      register({ name: FORM_FIELDS_MAP.isMasterList.key });
    }
  }, [shouldShowMasterListFields, register]);

  useEffect(() => {
    if (isFoldersFeatureActive)
      register(
        { name: FORM_FIELDS_MAP.parentFolder.key },
        { required: formCopies.sections.parentFolder.requiredMessage }
      );
  }, [isFoldersFeatureActive, register]);

  const handleRadioChange = event => setValue(FORM_FIELDS_MAP.privacyScope.key, event.target.value);

  const searchParamsKey =
    createdFrom === SEARCH_PROJECT_CREATED_FROM_VARIANTS.bulkMetrics
      ? SEARCH_PARAMS_KEYS.MetricsParams
      : SEARCH_PARAMS_KEYS.SearchParams;

  const onSubmit = handleSubmit(async formData => {
    setUiState({ isSaving: true, isSuccess: false });

    const optionalParams = {};
    if (searchProject?.id) optionalParams.searchProjectId = searchProject?.id;
    if (shouldShowMasterListFields) {
      optionalParams.canMarkAsMasterList = canMarkAsMasterList;
      if (isSearchProjectBeingEdited) optionalParams.geoPolygonId = searchProject.geoPolygon?.id;
    }

    const { alert, data, success } = await (isSearchProjectBeingEdited
      ? updateSearchProject({
          ...formData,
          ...optionalParams
        })
      : createSearchProject({
          ...formData,
          ...optionalParams,
          bulkId,
          itemsToAdd,
          queryParams,
          searchParamsKey,
          shouldExtractCompanyContacts,
          shouldMoveCopiedItems
        }));

    dispatch(showAlert(alert));

    if (success) {
      const newData = isSearchProjectBeingEdited ? data : null;
      if (isFoldersFeatureActive && newData) {
        const parentFolder = formData[FORM_FIELDS_MAP.parentFolder.key];
        newData.folder = { id: parentFolder.id, name: parentFolder.name };
      }
      onSubmitSearchProject && onSubmitSearchProject(success, newData);
      !isSearchProjectBeingEdited &&
        setTimeout(() => {
          history.push(EntityRoutes.SearchProjectPreview.replace(':id', data.id), {
            createdFrom: createdFrom || 'default'
          });
        }, 1);
    }

    setUiState({ isSaving: false, isSuccess: success });
  });

  return (
    <DrawerContentLayout
      title={finalTitle}
      drawerProps={{ open: true }}
      onClose={onClose}
      onSubmit={onSubmit}
      uiState={uiState}
    >
      <FormContext {...form}>
        <NumberedForm>
          <NumberedForm.Item title={formCopies.sections.titles.details}>
            <TextBox
              label={formCopies.sections.name.label}
              name={FORM_FIELDS_MAP.name.key}
              inputRef={register({
                required: formCopies.sections.name.requiredMessage,
                minLength: {
                  value: 5,
                  message: formCopies.sections.name.minLengthMessage
                }
              })}
              error={!!errors[FORM_FIELDS_MAP.name.key]}
              errorText={errors[FORM_FIELDS_MAP.name.key]?.message}
            />
            <FPRadioGroup
              options={PRIVACY_RADIO_OPTIONS}
              onChange={handleRadioChange}
              value={scopeWatcher}
              adviceText={
                formCopies.sections.visibility[SearchProjectPrivacyScopes[scopeWatcher].id]
              }
            />
          </NumberedForm.Item>
          <When condition={shouldExtractCompanyContacts}>
            <NumberedForm.Item title={formCopies.sections.titles.extractCompaniesContacts}>
              <SearchProjectExtractOptions />
            </NumberedForm.Item>
          </When>
          <When condition={isFoldersFeatureActive}>
            <NumberedForm.Item title={formCopies.sections.titles.parentFolder}>
              <TreeViewSelector
                formFieldName={FORM_FIELDS_MAP.parentFolder.key}
                placeholder={formCopies.sections.parentFolder.placeholder}
                TreeViewLoaderProps={{
                  enableIconClick: true,
                  onlyShowFolders: true,
                  service: {
                    method: getAllSearchProjectsFolders,
                    queryParams: { excludeIds: DEFAULT_SEARCH_PROJECT_FOLDERS_EXCLUDE_IDS }
                  }
                }}
              />
            </NumberedForm.Item>
          </When>
          <When condition={shouldShowMasterListFields && !shouldExtractCompanyContacts}>
            <NumberedForm.Item title={formCopies.sections.titles.markAsMasterList}>
              <FormControlLabel
                label={formCopies.sections.markAsMasterList.label}
                control={
                  <Checkbox
                    name={FORM_FIELDS_MAP.isMasterList.key}
                    checked={!!watch(FORM_FIELDS_MAP.isMasterList.key)}
                    onChange={({ target }) => setValue(target.name, target.checked)}
                    color="primary"
                  />
                }
              />
            </NumberedForm.Item>
            <NumberedForm.Item title={formCopies.sections.titles.geoPolygon}>
              <TextBox
                label={formCopies.sections.geoPolygon.label}
                name={FORM_FIELDS_MAP.geoPolygon.key}
                inputRef={register()}
                error={!!errors[FORM_FIELDS_MAP.geoPolygon.key]}
                errorText={errors[FORM_FIELDS_MAP.geoPolygon.key]?.message}
              />
            </NumberedForm.Item>
          </When>
        </NumberedForm>
      </FormContext>
    </DrawerContentLayout>
  );
};

export default SearchProjectFormDrawer;
