// @flow
import strings from 'strings';
import type { OperationResult } from 'types/app';
import { Endpoints } from 'UI/constants/endpoints';

import { makeRequest } from '../utils';

const { alerts: alertsCopies } = strings.searchProjects.folderForm;

const getGeneralErrorAlert = errorAction => ({
  title: strings.formatString(alertsCopies.error.general.title, {
    action: errorAction
  }),
  body: alertsCopies.error.general.body
});

const getGeneralSuccessTitle = successAction =>
  strings.formatString(alertsCopies.success.generalTitle, { action: successAction });

const basePayloadMapper = ({ name, parentFolderId }) => ({
  name,
  parent_id: parentFolderId
});

/**
 * @typedef {Object} GetAllFoldersQueryParams
 * @property {string} includes
 * @property {number} parentId
 * @property {Array<Number>} excludeIds
 */

/**
 * @param {GetAllFoldersQueryParams} [queryParams = {}]
 * @returns {Promise<OperationResult>}
 */
export const getAllSearchProjectsFolders = (queryParams = {}): Promise<OperationResult> =>
  makeRequest({
    method: 'get',
    endpointOptions: {
      path: Endpoints.SearchProjectFolders,
      queryParams
    }
  });

/**
 * @param {Number} id
 * @returns {Promise<OperationResult>}
 */
export const getSearchProjectFolderById = (id): Promise<OperationResult> =>
  makeRequest({
    method: 'get',
    endpointOptions: {
      path: Endpoints.SearchProjectFolderById,
      replaceId: id,
      queryParams: {
        includes: 'parent'
      }
    }
  });

export const createSearchProjectFolder = payload =>
  makeRequest({
    method: 'post',
    url: Endpoints.SearchProjectFolders,
    data: basePayloadMapper(payload),
    alertConfig: {
      success: {
        title: getGeneralSuccessTitle(alertsCopies.success.actions.create),
        body: payload.name
      },
      error: getGeneralErrorAlert(alertsCopies.error.actions.create)
    }
  });

export const updateSearchProjectFolder = (folderId, payload) =>
  makeRequest({
    method: 'put',
    endpointOptions: {
      path: Endpoints.SearchProjectFolderById,
      replaceId: folderId
    },
    data: basePayloadMapper(payload),
    alertConfig: {
      success: {
        title: getGeneralSuccessTitle(alertsCopies.success.actions.edit)
      },
      error: getGeneralErrorAlert(alertsCopies.error.actions.edit)
    }
  });

export const archiveSearchProjectFolder = folderId =>
  makeRequest({
    method: 'delete',
    endpointOptions: {
      path: Endpoints.SearchProjectFolderById,
      replaceId: folderId
    },
    alertConfig: {
      success: {
        title: getGeneralSuccessTitle(alertsCopies.success.actions.archive)
      },
      error: getGeneralErrorAlert(alertsCopies.error.actions.archive)
    }
  });

export const restoreSearchProjectFolder = ({ folderId, parentId }) =>
  makeRequest({
    method: 'post',
    endpointOptions: {
      path: Endpoints.SearchProjectRestoreFolder,
      replaceId: folderId
    },
    data: { parentId },
    alertConfig: {
      success: {
        title: getGeneralSuccessTitle(alertsCopies.success.actions.restore)
      },
      error: getGeneralErrorAlert(alertsCopies.error.actions.restore)
    }
  });

const moveSearchProjectsToExistingFolder = payload =>
  makeRequest({
    url: Endpoints.SearchProjectMoveToFolder,
    method: 'post',
    data: {
      folderId: payload.parentFolderId,
      searchProjectIds: payload.searchProjectsIds
    },
    alertConfig: {
      success: {
        title: alertsCopies.success.moveSearchProjectsTitle
      },
      error: {
        title: alertsCopies.error.moveSearchProjectsTitle,
        body: alertsCopies.error.general.body
      }
    }
  });

const moveSearchProjectsToNewFolder = payload =>
  makeRequest({
    url: Endpoints.SearchProjectFolders,
    method: 'post',
    data: {
      ...basePayloadMapper(payload),
      searchProjectIds: payload.searchProjectsIds
    },
    alertConfig: {
      success: {
        title: alertsCopies.success.moveSearchProjectsTitle
      },
      error: {
        title: alertsCopies.error.moveSearchProjectsTitle,
        body: alertsCopies.error.general.body
      }
    }
  });

export const executeFolderFormDrawerService = ({
  currentParentFolderId,
  folderName,
  isFolderBeingEdited,
  parentFolderId,
  searchProjectsToMove,
  shouldMoveSearchProjects
}) => {
  const searchProjectsIds = shouldMoveSearchProjects
    ? searchProjectsToMove.data.map(({ id }) => id)
    : null;

  if (shouldMoveSearchProjects?.toExistingFolder)
    return moveSearchProjectsToExistingFolder({
      parentFolderId,
      searchProjectsIds
    });

  const basePayload = { parentFolderId, name: folderName };

  if (shouldMoveSearchProjects?.toNewFolder)
    return moveSearchProjectsToNewFolder({ ...basePayload, searchProjectsIds });

  return isFolderBeingEdited
    ? updateSearchProjectFolder(currentParentFolderId, basePayload)
    : createSearchProjectFolder(basePayload);
};
