import isNil from 'lodash/isNil';
import strings from 'strings';
import { formatRatio } from 'UI/utils';

const convertSecondsToHours = value => {
  if (!value) return 0;
  return value / 3600;
};

const convertToHours = value => {
  if (!value) return 0;
  return value / 60;
};

export const formatStats = ({ data, drawerHandlers }) => {
  const keyOrder = [
    'inMails',
    'inMailsGoal',
    'bulks',
    'bulksGoal',
    'peopleReached',
    'peopleReachedGoal',
    'posts',
    'postsGoal',
    'calls',
    'callsGoal',
    'phoneTime',
    'phoneTimeGoal',
    'notConnectedCalls'
  ];

  const orderedData = keyOrder.reduce((acc, key) => {
    if (!isNil(data[key])) {
      acc[key] = data[key];
    }
    return acc;
  }, {});

  const { goals, stats } = Object.keys(orderedData).reduce(
    (acc, key) => {
      if (key.includes('Goal')) {
        acc.goals[key] = orderedData[key];
      } else {
        acc.stats[key] = orderedData[key];
      }
      return acc;
    },
    { goals: {}, stats: {} }
  );

  return Object.keys(stats).map(key => {
    const isKeyPhoneTime = key === 'phoneTime';

    const value = isKeyPhoneTime ? convertSecondsToHours(Number(stats[key])) : Number(stats[key]);
    const goal = isKeyPhoneTime ? convertToHours(goals[`${key}Goal`]) : goals[`${key}Goal`];
    const url = data[`${key}Url`];

    return {
      color: value < goal ? 'purple' : 'black',
      title: strings.commandCenter.metricsTitles[key],
      value: goal
        ? formatRatio(value, goal, 'scalar', {
            defaultEmptyRatio: '0/0',
            notation: 'standard',
            forceCompactNotation: false,
            decimalPlaces: 1
          })
        : value,
      ...(drawerHandlers &&
        url && {
          onClick: () => drawerHandlers[key]({ url })
        })
    };
  });
};
