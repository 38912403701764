import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import RefreshIcon from '@material-ui/icons/Refresh';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import { DateFormats } from 'UI/constants/defaults';
import { localTimeFormatter } from 'UI/utils';

import { MESSAGE_STATUS } from '../chat.constants';

import { AttachmentMessage } from './AttachmentMessage';
import { MessageBubble, MessageContainer, MessageRow, MessageText } from './Message.styled';

const statusMessageRender = {
  [MESSAGE_STATUS.sending]: <CircularProgress size={16} />,
  [MESSAGE_STATUS.success]: <DoneIcon aria-label="Done Icon" color="primary" />,
  [MESSAGE_STATUS.error]: handleClickRetry => (
    <FPIconButton aria-label="Error Icon" onClick={handleClickRetry}>
      <RefreshIcon color="error" />
    </FPIconButton>
  )
};

export const Message = ({ attachment, date, error, isOutbound, message, onClickRetry, status }) => {
  const [showIcon, setShowIcon] = useState(true);

  const localTime = localTimeFormatter(date, DateFormats.SimpleTime);
  const isBadRequest = error?.statusCode === HTTPStatusCodes.BadRequest;
  const shouldRenderStatusIcon = showIcon && !isBadRequest && statusMessageRender[status];

  useEffect(() => {
    const timeOut =
      status === MESSAGE_STATUS.success &&
      setTimeout(() => {
        setShowIcon(false);
      }, 3000);

    return () => {
      timeOut && clearTimeout(timeOut);
    };
  }, [status]);

  return (
    <MessageRow isOutbound={isOutbound}>
      <MessageContainer isOutbound={isOutbound}>
        <When condition={shouldRenderStatusIcon}>
          {typeof statusMessageRender[status] === 'function'
            ? statusMessageRender[status](onClickRetry)
            : statusMessageRender[status]}
        </When>

        <MessageBubble isOutbound={isOutbound}>
          <When condition={attachment?.url}>
            <AttachmentMessage attachment={attachment} isOutbound={isOutbound} />
          </When>

          <MessageText isOutbound={isOutbound}>{message}</MessageText>

          <MessageText variant="body2" isOutbound={isOutbound} isHourText>
            {localTime === 'Invalid date' ? '' : localTime}
          </MessageText>
        </MessageBubble>
      </MessageContainer>

      <When condition={status === MESSAGE_STATUS.error}>
        <Typography color="error" variant="body2">
          {error?.message ?? strings.messageCenter.error.sendingMessage}
        </Typography>
      </When>
    </MessageRow>
  );
};
