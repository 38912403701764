// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { confirm, showAlert, showFile } from 'actions/app';
import { globalStyles } from 'GlobalStyles';
import { useFetch } from 'hooks/fetch';
import { useFetchWithStatus } from 'hooks/fetchWithStatus';
import groupBy from 'lodash/groupBy';
import { EntityRoutes } from 'routes/constants';
import { getCurrentUser } from 'services/Authentication';
import { getHighestUserRoleInHierarchy } from 'services/Authorization';
import {
  approveEstimate,
  createInvoice,
  createPayment,
  createSuggestion,
  deletePlacement,
  getPlacementById,
  markAsFallOff,
  recreateEstimate,
  requestFallOff,
  requestRevertFallOff,
  requestZip,
  revertFallOff,
  saveEstimate,
  updatePlacement
} from 'services/Placements';
import strings from 'strings';
import type { DrawerUiState, Split } from 'types/app';
import FPChip from 'UI/components/atoms/FPChip';
import { When } from 'UI/components/atoms/When';
import InfoLabel from 'UI/components/molecules/InfoLabel';
import NumberedForm from 'UI/components/molecules/NumberedForm';
import UserComments from 'UI/components/molecules/UserComments';
import {
  buildFileExplorerSections,
  FileKeyPrefix,
  flattenSplits,
  getFilesThatChanged,
  joinSplitsWithChannelPartners,
  normalizeUserPersonalInformation,
  PlacementRoleHierarchy
} from 'UI/components/organisms/placements/utils';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { PaymentMode } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { CANDIDATES_PROFILE_HASHES } from 'UI/constants/hashIds';
import { Roles } from 'UI/constants/roles';
import { PlacementStatus } from 'UI/constants/status';
import { getDataSheetByJobOrderId } from 'UI/pages/EditDataSheet/EditDataSheet.services';
import { EmptyPlacements } from 'UI/res';
import { hasFeatureFlag, nestTernary, toLocalTime } from 'UI/utils';
import { getActions } from 'UI/utils/actions-engine';

import { FormFieldsMap } from '../fields';
import PlacementAdditionalDetails from '../PlacementAdditionalDetails';
import PlacementBasicSection from '../PlacementBasicSection';
import PlacementCandidateSection from '../PlacementCandidateSection';
import PlacementCompanySection from '../PlacementCompanySection';
import { useValidCandidateDataSheet } from '../PlacementCreateManager/hooks';
import PlacementDataSheetView from '../PlacementDataSheetView';
import PlacementDetail from '../PlacementDetail';
import PlacementFallOff from '../PlacementFallOff';
import PlacementFilesSection from '../PlacementFilesSection';
import PlacementInvoicesForm from '../PlacementInvoices';
import PlacementLogs from '../PlacementLogs';
import { PlacementPayments } from '../PlacementPayments';
import PlacementReferenceChecks from '../PlacementReferenceChecks';
import PlacementReferenceReleaseView from '../PlacementReferenceReleaseView';
import PlacementSplitsSection from '../PlacementSplitsSection';
import PlacementSuggestionForm from '../PlacementSuggestionForm';
import PlacementUploadEstimate from '../PlacementUploadEstimate';
import { useStyles } from '../styles';

import { Guards, PlacementActions, Rules } from './rules';

const PlacementSuggestions = ({ suggestions, showBottomDivider = false }) => (
  <>
    <Typography>{strings.placements.fields.suggestedChanged}</Typography>
    {suggestions.map(({ id, description, user, created_at }) => (
      <UserComments
        key={id}
        avatarInitials={user.initials}
        author={user?.personalInformation?.full_name}
        date={toLocalTime(created_at).format(DateFormats.SimpleDateTime)}
        note={description}
      />
    ))}
    {showBottomDivider && <Divider style={globalStyles.mediumDivider} />}
  </>
);

const PlacementAgreementChanges = ({ recruiter, reasonForchange }) => (
  <>
    <Typography variant="subtitle1">
      {`${strings.placements.fields.reasonForRequestChange}: `}
    </Typography>
    <UserComments
      key={recruiter?.id}
      avatarInitials={recruiter?.initials}
      author={recruiter?.personalInformation?.full_name}
      note={reasonForchange}
    />
  </>
);

type PlacementEditFormProps = {
  placementId?: number,
  onClose: () => void,
  onCompleted: () => any,
  onBeforeSubmit: () => any,
  onInvoicesChanged: () => any,
  onPaymentsChanged: () => any
};

const confirmMessagesByAction = {
  [PlacementActions.Save]: {
    question: strings.placements.confirmations.updatePlacement,
    button: strings.placements.ctas.updatePlacement,
    successTitle: strings.placements.messages.placementUpdated,
    successBody: strings.placements.messages.placementUpdatedSubtitle
  },
  [PlacementActions.Approve]: {
    question: strings.placements.confirmations.approvePlacement,
    button: strings.placements.ctas.approvePlacement,
    successTitle: strings.placements.messages.placementApproved,
    successBody: strings.placements.messages.placementApprovedSubtitle
  },
  [PlacementActions.Adjusting]: {
    question: strings.placements.confirmations.adjustPlacement,
    button: strings.placements.ctas.makeAdjustment,
    successTitle: strings.placements.messages.placementAdjusted,
    successBody: strings.placements.messages.placementAdjustedSubtitle
  },
  [PlacementActions.AdjustingEstimate]: {
    question: strings.placements.confirmations.adjustEstimate,
    button: strings.placements.ctas.adjustEstimate,
    successTitle: strings.placements.messages.estimateUpdated,
    successBody: strings.placements.messages.estimateUpdatedSubtitle
  }
};

const PlacementEditForm = ({
  placementId,
  onBeforeSubmit,
  onCompleted,
  onInvoicesChanged,
  onPaymentsChanged,
  onClose
}: PlacementEditFormProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = getCurrentUser();
  const highestRole = getHighestUserRoleInHierarchy(currentUser, PlacementRoleHierarchy);
  const isUserFinance = highestRole?.id === Roles.Finance;
  const isLeadership = highestRole?.id === Roles.Leadership;
  const hasRolesThatCanOverride = [
    Roles.Operations,
    Roles.RegionalDirector,
    Roles.Finance,
    Roles.Leadership
  ].includes(highestRole?.id);

  const [groupedFiles, setGroupedFiles] = useState([]);
  /** To keep track of tabs that should refresh after an event.
   * Remember that a component unmount when key changes */
  const [formsKeys, setFormsKeys] = useState({
    payments: 0,
    invoices: 0,
    falloffs: 0
  });
  const [uiState, setUiState] = useState<DrawerUiState>({
    isLoading: false,
    isSaving: false,
    isSuccess: false,
    navStack: []
  });
  const [splits, setSplits] = useState([]);
  const [initialSplits, setInitialSplits] = useState([]);
  const [placement, setPlacement] = useState(null);
  const { files, suggestedUpdates: suggestions, sendout } = placement || {};
  const feeAgreements = [];
  const placementStatusId = placement?.placement_status_id;

  const { joborder, candidate } = sendout || {};
  const company = joborder?.company;

  const [fileCategories] = useFetch(
    sendout ? `${Endpoints.FilesForPlacement.replace(':id', sendout.id)}` : '',
    []
  );

  const candidateFiles = candidate?.files;
  const jobOrderFiles = joborder?.files;
  const [companyFiles] = useFetch(
    company ? `${Endpoints.Companies}/${company.id}/${Endpoints.Files}` : '',
    []
  );
  const fileExplorerSections = buildFileExplorerSections(
    candidateFiles,
    jobOrderFiles,
    companyFiles
  );

  const { state: referenceReleaseState } = useFetchWithStatus(
    candidate ? `${Endpoints.Candidates}/${candidate?.id}/${Endpoints.ReferenceReleases}` : ''
  );

  const referenceReleaseExists =
    referenceReleaseState?.results && referenceReleaseState?.results.length > 0;
  const newestReferenceReleaseEmail = referenceReleaseExists && referenceReleaseState?.results[0];

  const shouldLoadAssignmentDataSheet =
    hasFeatureFlag(FeatureFlags.PlacementWithAssignmentDataSheet) && !!joborder;
  const assignmentDataSheetRequest = useCallback(async () => {
    return getDataSheetByJobOrderId(joborder?.id);
  }, [joborder]);
  const { state: assignmentDataSheetState } = useFetchWithStatus(
    null,
    null,
    'default',
    null,
    shouldLoadAssignmentDataSheet ? assignmentDataSheetRequest : null
  );

  const { candidateDataSheet } = useValidCandidateDataSheet(candidate?.id);

  const assignmentDataSheetProgress =
    assignmentDataSheetState?.results && assignmentDataSheetState.results.progress;
  const isAssignmentDataSheetValid =
    assignmentDataSheetProgress &&
    assignmentDataSheetProgress.current === assignmentDataSheetProgress.total;
  const assignmentDataSheet = isAssignmentDataSheetValid && assignmentDataSheetState?.results;

  const availableActions = getActions(placementStatusId, highestRole?.id, Rules, {
    placement,
    user: currentUser,
    uiState,
    splits: initialSplits
  });

  const form = useForm();

  const { handleSubmit, reset, register, clearError } = form;

  useEffect(() => {
    const loadPlacement = async () => {
      setUiState(prevState => ({ ...prevState, isLoading: true }));
      const result = await getPlacementById(
        placementId,
        [],
        [
          'sendout.candidate.personalInformation',
          'sendout.candidate.files',
          'sendout.candidate.sourceType',
          'sendout.joborder.sourceType',
          'sendout.joborder.files',
          'sendout.joborder.company.contact',
          'sendout.joborder.specialty.industry',
          'sendout.joborder.company.city.state',
          'sendout.joborder.company.specialty.industry',
          'sendout.hiringAuthorithies.hiringAuthority',
          'recruiter.personalInformation',
          'recruiter.teamRelation.regional.personalInformation',
          'recruiter.teamRelation.coach.personalInformation',
          'recruiter.teamRelation.coach.assistants',
          'status',
          'paymentTerms',
          'billingCompany',
          'billingCompany.city.state',
          'hiringAuthority'
        ]
      );
      const placementData = result.data;
      if (placementData) {
        const splts = [];

        joinSplitsWithChannelPartners(placementData.splits).forEach(
          ({ user, type, id, split_percentage, channelPartners, coach_id }) =>
            splts.push({
              recruiter: normalizeUserPersonalInformation(user),
              type,
              id,
              percent: split_percentage,
              channelPartners: channelPartners
                ? channelPartners.map(channelPartner => ({
                    id: channelPartner.id,
                    recruiter: normalizeUserPersonalInformation(channelPartner.user),
                    type,
                    isChannelPartner: true,
                    percent: channelPartner.split_percentage,
                    coach_id: channelPartner.coach_id
                  }))
                : null,
              coach_id
            })
        );

        setInitialSplits(splts);
        setPlacement(placementData);
        const fileValues = {};
        const filesByCategory = groupBy(placementData.files, 'file_type_id');
        setGroupedFiles(filesByCategory);
        Object.keys(filesByCategory).forEach(key => {
          fileValues[`${FileKeyPrefix}${key}`] = filesByCategory[key];
        });

        const isBilledToDifferentCompany =
          placementData.billingCompany?.id !== placementData.sendout.joborder.company.id &&
          !!placementData.billingCompany;
        reset({
          ...placementData,
          ...fileValues,
          payment_terms_id: placementData?.paymentTerms,
          billToCompany: isBilledToDifferentCompany ? placementData.billingCompany : null,
          shouldBillToOtherCompany: isBilledToDifferentCompany,
          hiringAuthority: placementData.hiringAuthority,
          [FormFieldsMap.ShouldRequestFeeChange.key]: placementData.requested_fee_change,
          [FormFieldsMap.ShouldRequestGuaranteePeriodChange.key]:
            placementData.requested_guarantee_days_change,
          [FormFieldsMap.ReasonForRequestChange.key]: placementData.reason_for_change
        });
      }

      setUiState(prevState => ({ ...prevState, isLoading: false }));
    };
    placementId && loadPlacement();
  }, [placementId, reset]);

  const handleSplitsChange = useCallback((newSplits: Split[]) => setSplits(newSplits), []);

  const clearErrors = useCallback(() => {
    setTimeout(() => {
      clearError();
    }, 250);
  }, [clearError]);

  const onSubmit = async formData => {
    if (primaryAction.type !== 'submit') return;
    await primaryAction.action(formData);
  };

  const handleBack = () => primaryAction.backAction && primaryAction.backAction();
  const savePlacement = async formData => {
    const confirmMessages = confirmMessagesByAction[availableActions.primary];
    if (!confirmMessages) return;

    dispatch(
      confirm({
        severity: 'warning',
        title: strings.shared.ui.confirm,
        message: confirmMessages.question,
        confirmButtonText: confirmMessages.button,
        onConfirm: async ok => {
          if (!ok) return;
          const finalSplits = flattenSplits(splits, formData);

          /** Backend only needs files that were replaced, added or deleted. If nothing changed, then nothing is sent */
          const filesThatChanged = getFilesThatChanged(fileCategories, files, formData);
          const paymentTermsId = formData.payment_terms_id?.id;
          const {
            candidateSourceType,
            jobOrderSourceType,
            billToCompany,
            hiringAuthority,
            [FormFieldsMap.ShouldRequestFeeChange.key]: requestedFeeChange,
            [FormFieldsMap.ShouldRequestGuaranteePeriodChange.key]: requestedGuaranteeDaysChange,
            [FormFieldsMap.ReasonForRequestChange.key]: reasonForChange
          } = formData;

          const finalData = {
            ...formData,
            splits: finalSplits,
            files: filesThatChanged,
            fee_amount: Number.parseFloat(formData.fee_amount),
            fee_percentage:
              formData.fee_agreement_payment_scheme_id !== PaymentMode.Flat
                ? formData.fee_percentage
                : null,
            source_type_id: candidateSourceType?.id,
            job_order_source_type_id: jobOrderSourceType?.id,
            payment_terms_id: paymentTermsId,
            billingCompanyId: billToCompany?.id,
            hiringAuthorityId: hiringAuthority?.id,
            assignmentDataSheetId: assignmentDataSheet?.id,
            candidateDataSheetId: candidateDataSheet?.id,
            requestedFeeChange,
            requestedGuaranteeDaysChange,
            reasonForChange:
              requestedFeeChange || requestedGuaranteeDaysChange ? reasonForChange : null
          };
          onBeforeSubmit && onBeforeSubmit(finalData);

          await performAsyncMethod(updatePlacement, finalData, {
            successTitle: confirmMessages.successTitle,
            successBody: confirmMessages.successBody
          });
        }
      })
    );

    setUiState(prevState => ({
      ...prevState,
      isSuccess: false,
      isSaving: false
    }));
  };

  const performAsyncMethod = async (method, formData = null, options) => {
    const defaultOptions = {
      shouldClose: true,
      notifyCompletion: true,
      successCallback: undefined
    };
    const finalOptions = { ...defaultOptions, ...options };
    setUiState(prevState => ({ ...prevState, isSaving: true }));
    const result = await method(placementId, formData, finalOptions);
    setUiState(prevState => ({ ...prevState, isSaving: false }));
    result.alert && result.alert.body && dispatch(showAlert(result.alert));
    if (result.success) {
      finalOptions.notifyCompletion && onCompleted && onCompleted();
      finalOptions.shouldClose && onClose();
      finalOptions.successCallback && options.successCallback();
    }

    return result;
  };

  const navigateTo = (key: string) => () =>
    setUiState(prevState => ({
      ...prevState,
      navStack: [...prevState.navStack, key],
      showBackNavigation: true
    }));

  const goBack = () =>
    setUiState(prevState => {
      const navStack = prevState.navStack.splice(0, prevState.navStack.length - 1);
      return {
        ...prevState,
        navStack,
        showBackNavigation: navStack.length > 0
      };
    });

  const backAction = {
    title: strings.shared.ui.back,
    action: goBack,
    backAction: goBack
  };

  const actions = {
    [PlacementActions.Default]: { title: strings.shared.ui.ok, action: onClose },
    [PlacementActions.Save]: {
      title: strings.shared.ui.update,
      type: 'submit',
      action: savePlacement
    },
    [PlacementActions.Approve]: {
      title: strings.shared.ui.approve,
      type: 'submit',
      action: savePlacement
    },
    [PlacementActions.Suggest]: {
      title: strings.placements.ctas.requestAChange,
      action: navigateTo(PlacementActions.Suggesting)
    },
    [PlacementActions.Suggesting]: {
      title: strings.placements.ctas.requestChange,
      type: 'submit',
      action: async formData => {
        await performAsyncMethod(createSuggestion, formData);
      },
      backAction: goBack
    },
    [PlacementActions.ManageInvoices]: {
      title: strings.placements.ctas.manageInvoices,
      action: navigateTo(PlacementActions.ManagingInvoices)
    },
    [PlacementActions.ManagingInvoices]: {
      title: strings.placements.ctas.saveInvoice,
      type: 'submit',
      action: async formData => {
        await performAsyncMethod(createInvoice, formData, {
          shouldClose: false,
          notifyCompletion: false,
          successCallback: () => {
            setFormsKeys(prevState => ({ ...prevState, invoices: prevState.invoices + 1 }));
            clearErrors();
            onInvoicesChanged && onInvoicesChanged();
          }
        });
      },
      backAction: goBack
    },
    [PlacementActions.ViewInvoices]: {
      title: strings.placements.ctas.viewInvoices,
      action: navigateTo(PlacementActions.ViewingInvoices)
    },
    [PlacementActions.ManagePayments]: {
      title: strings.placements.ctas.managePayments,
      action: navigateTo(PlacementActions.ManagingPayments)
    },
    [PlacementActions.ManagingPayments]: {
      title: strings.placements.ctas.savePayment,
      type: 'submit',
      action: async formData => {
        await performAsyncMethod(createPayment, formData, {
          shouldClose: false,
          notifyCompletion: false,
          successCallback: () => {
            setFormsKeys(prevState => ({ ...prevState, payments: prevState.payments + 1 }));
            clearErrors();
            onPaymentsChanged && onPaymentsChanged();
          }
        });
      },
      backAction: goBack
    },
    [PlacementActions.RequestingFallOff]: {
      title: strings.shared.ui.submit,
      type: 'submit',
      action: async formData => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.placements.confirmations.requestFallOff,
            confirmButtonText: strings.placements.ctas.requestFallOff,
            onConfirm: async ok => {
              if (!ok) return;
              const finalData = {
                ...formData,
                candidate_still_available: formData.candidate_still_available === '1',
                job_still_open: formData.job_still_open === '1',
                placement_fall_off_reason_id: Number.parseInt(
                  formData.placement_fall_off_reason_id,
                  10
                )
              };
              await performAsyncMethod(requestFallOff, finalData);
            }
          })
        );
      },
      backAction: goBack
    },
    [PlacementActions.ViewPayments]: {
      title: strings.placements.ctas.viewPayments,
      action: navigateTo(PlacementActions.ViewingPayments)
    },
    [PlacementActions.ViewLogs]: {
      title: strings.placements.ctas.viewLogs,
      action: navigateTo(PlacementActions.ViewingLogs)
    },
    [PlacementActions.MarkFallOff]: {
      title: strings.placements.ctas.completeFallOff,
      action: async () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.placements.confirmations.completeFallOff,
            confirmButtonText: strings.placements.ctas.completeFallOff,
            onConfirm: async ok => {
              if (!ok) return;

              await performAsyncMethod(markAsFallOff);
            }
          })
        );
      }
    },
    [PlacementActions.RequestRevertFallOff]: {
      title: strings.placements.ctas.switchToPlacement,
      action: () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.placements.confirmations.requestRevertFallOff,
            cancelButtonText: strings.shared.ui.cancel,
            confirmButtonText: strings.shared.ui.continue,
            onConfirm: async ok => {
              if (!ok) return;

              await performAsyncMethod(requestRevertFallOff, null);
            }
          })
        );
      }
    },
    [PlacementActions.RevertFallOff]: {
      title: strings.placements.ctas.switchToPlacement,
      action: () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.placements.confirmations.revertFallOff,
            content: (
              <ul className={classes.confirmChecklist}>
                <li>Job Order status will be updated to Placed</li>
                <li>Candidate status will be updated to Placed</li>
                <li>Sendout status will be updated to Placed</li>

                <li>
                  Company and Candidate of recruiter&apos;s production will be updated in Fortpac.
                </li>
                <li>
                  If there was a payment registered, Company and Candidate of recruiter&apos;s cash
                  in will be updated in Fortpac
                </li>
              </ul>
            ),
            cancelButtonText: strings.shared.ui.cancel,
            confirmButtonText: strings.shared.ui.continue,
            onConfirm: async ok => {
              if (!ok) return;

              await performAsyncMethod(revertFallOff, null);
            }
          })
        );
      }
    },
    [PlacementActions.MakeAdjustment]: {
      title: strings.placements.ctas.makeAdjustment,
      action: navigateTo(PlacementActions.Adjusting)
    },
    [PlacementActions.Adjusting]: {
      title: strings.shared.ui.save,
      type: 'submit',
      action: savePlacement,
      backAction: goBack
    },
    [PlacementActions.AdjustEstimate]: {
      title: strings.placements.ctas.changeEstimate,
      action: navigateTo(PlacementActions.AdjustingEstimate)
    },
    [PlacementActions.AdjustingEstimate]: {
      title: strings.placements.ctas.updateEstimate,
      type: 'submit',
      action: savePlacement,
      backAction: goBack
    },
    [PlacementActions.RequestFallOff]: {
      title: strings.placements.ctas.requestFallOff,
      action: navigateTo(PlacementActions.RequestingFallOff),
      backAction: goBack
    },
    [PlacementActions.RequestZip]: {
      title: strings.placements.ctas.requestZipFile,
      action: async () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.placements.confirmations.requestZipFile,
            confirmButtonText: strings.placements.ctas.sendDownloadLink,
            onConfirm: async ok => {
              if (!ok) return;

              await performAsyncMethod(requestZip);
            }
          })
        );
      }
    },
    [PlacementActions.UploadEstimate]: {
      title: strings.placements.ctas.uploadEstimate,
      action: navigateTo(PlacementActions.UploadingEstimate)
    },
    [PlacementActions.UploadingEstimate]: {
      title: strings.shared.ui.Save,
      type: 'submit',
      action: async formData => {
        const { estimateFile } = formData;
        const newEstimateUrl = estimateFile?.url;
        const shouldUpdateEstimate = placement.estimate_url !== newEstimateUrl;
        if (shouldUpdateEstimate) {
          await performAsyncMethod(saveEstimate, { estimateUrl: newEstimateUrl });
        } else {
          onClose();
        }
      },
      backAction: goBack
    },
    [PlacementActions.ApproveEstimate]: {
      title: strings.placements.ctas.approveEstimate,
      action: async () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.shared.ui.confirm,
            message: strings.placements.confirmations.approveEstimate,
            confirmButtonText: strings.placements.ctas.approveEstimate,
            onConfirm: async ok => {
              if (!ok) return;

              await performAsyncMethod(approveEstimate);
            }
          })
        );
      }
    },
    [PlacementActions.ViewEstimate]: {
      title: strings.placements.ctas.viewEstimate,
      action: () => {
        placement.estimate_url &&
          dispatch(
            showFile({
              url: placement.estimate_url,
              useProxy: true
            })
          );
      }
    },
    [PlacementActions.RecreateEstimate]: {
      title: strings.placements.ctas.recreateEstimate,
      action: async () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.placements.confirmations.recreateEstimate,
            message: strings.placements.confirmations.recreateEstimateHelper,
            confirmButtonText: strings.placements.ctas.recreateEstimateGoahead,
            onConfirm: async ok => {
              if (!ok) return;

              await performAsyncMethod(recreateEstimate, null, {
                successBody: strings.placements.messages.estimateRecreatedSubtitle,
                successTitle: strings.placements.messages.estimateRecreated
              });
            }
          })
        );
      }
    },
    [PlacementActions.RecreateSalesOrder]: {
      title: strings.placements.ctas.recreateSalesOrder,
      action: async () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.placements.confirmations.recreateSalesOrder,
            message: strings.placements.confirmations.recreateSalesOrderHelper,
            confirmButtonText: strings.placements.ctas.recreateSalesOrderGoahead,
            onConfirm: async ok => {
              if (!ok) return;

              await performAsyncMethod(
                recreateEstimate,
                { renewPlacementKey: true },
                {
                  successBody: strings.placements.messages.salesOrderRecreatedSubtitle,
                  successTitle: strings.placements.messages.salesOrderRecreated
                }
              );
            }
          })
        );
      }
    },
    [PlacementActions.ViewingInvoices]: backAction,
    [PlacementActions.ViewingPayments]: backAction,
    [PlacementActions.ViewingLogs]: backAction,
    [PlacementActions.DeletePlacement]: {
      title: strings.placements.ctas.deletePlacement,
      action: async () => {
        dispatch(
          confirm({
            severity: 'warning',
            title: strings.placements.confirmations.deletePlacement,
            message: strings.placements.confirmations.deletePlacementHelper,
            ...strings.shared.ui.keepDeleteButtonCopies,
            onConfirm: async ok => {
              if (!ok) return;

              await performAsyncMethod(deletePlacement, {
                successBody: strings.placements.messages.placementDeleted,
                successTitle: strings.placements.messages.placementDeletedSubtitle
              });
            }
          })
        );
      }
    }
  };

  const primaryAction = actions[availableActions.primary];

  const enableCopyPaster =
    isUserFinance &&
    availableActions.primary !== PlacementActions.Adjusting &&
    (placementStatusId === PlacementStatus.PendingInvoice ||
      placementStatusId === PlacementStatus.Invoiced);

  const actionsForEdition = [
    PlacementActions.Adjusting,
    PlacementActions.Save,
    PlacementActions.Approve,
    PlacementActions.AdjustingEstimate
  ];
  const statusesForEdition = [
    PlacementStatus.PendingValidation,
    PlacementStatus.PendingUpdate,
    PlacementStatus.PendingRegionalValidation,
    PlacementStatus.PendingEstimateApproval,
    PlacementStatus.PendingFinanceValidation
  ];
  const isFinanceEditing =
    isUserFinance &&
    (availableActions.primary === PlacementActions.Adjusting ||
      availableActions.primary === PlacementActions.AdjustingEstimate);
  const isFinanceAdjusting =
    availableActions.primary === PlacementActions.Adjusting && isUserFinance;

  const actionsForSave = [
    PlacementActions.Save,
    PlacementActions.Approve,
    PlacementActions.AdjustingEstimate
  ];
  const canOwnerOrTeamEdit =
    statusesForEdition.includes(placementStatusId) &&
    actionsForSave.includes(availableActions.primary) &&
    Guards.teamOwnership({ placement, user: currentUser, splits });
  const canEdit = canOwnerOrTeamEdit || isFinanceEditing || isLeadership;
  const { isLoading } = uiState;

  const renderForm = () => {
    const shouldShowReadOnlyView = !actionsForEdition.includes(availableActions.primary);

    const shouldShowRequestedChanges =
      suggestions?.length > 0 &&
      (placementStatusId === PlacementStatus.PendingValidation ||
        placementStatusId === PlacementStatus.PendingUpdate) &&
      !isUserFinance;

    const shouldShowFeeRequestedChanges =
      !shouldShowReadOnlyView &&
      (placement?.requested_fee_change || placement?.requested_guarantee_days_change) &&
      hasRolesThatCanOverride;

    const shouldShowFalloffReason =
      placement?.fall_off_reason &&
      (placementStatusId === PlacementStatus.FallOff ||
        placementStatusId === PlacementStatus.PendingRevertFallOff ||
        placementStatusId === PlacementStatus.PendingFallOff);

    const assignmentDataSheetURL =
      assignmentDataSheet?.jobOrder?.id &&
      EntityRoutes.JobOrderEdit.replace(':id', assignmentDataSheet.jobOrder.id);

    const candidateDataSheetURL =
      candidateDataSheet?.candidate?.id &&
      EntityRoutes.CandidateProfile.replace(':id', candidateDataSheet.candidate.id);

    switch (availableActions.primary) {
      case PlacementActions.Suggesting:
        return <PlacementSuggestionForm />;
      case PlacementActions.UploadingEstimate:
        return <PlacementUploadEstimate id={placementId} />;
      case PlacementActions.ManagingInvoices:
      case PlacementActions.ViewingInvoices:
        return (
          <PlacementInvoicesForm
            id={placementId}
            key={formsKeys.invoices}
            onInvoicesChanged={onInvoicesChanged}
            readOnly={!isUserFinance}
          />
        );
      case PlacementActions.ManagingPayments:
      case PlacementActions.ViewingPayments:
        return (
          <PlacementPayments
            id={placementId}
            key={formsKeys.payments}
            readOnly={!isUserFinance}
            onPaymentsChanged={onPaymentsChanged}
          />
        );
      case PlacementActions.ViewingLogs:
        return <PlacementLogs id={placementId} />;
      case PlacementActions.RequestingFallOff:
        return <PlacementFallOff id={placementId} />;
      default:
        return (
          <>
            {shouldShowRequestedChanges && (
              <PlacementSuggestions suggestions={suggestions} showBottomDivider />
            )}
            {shouldShowFeeRequestedChanges && (
              <PlacementAgreementChanges
                recruiter={placement.recruiter}
                reasonForchange={placement.reason_for_change}
              />
            )}
            {shouldShowFalloffReason && (
              <>
                <InfoLabel
                  title={strings.placements.fields.falloffReason}
                  description={placement?.fall_off_reason || ''}
                  className={classes.infoLabel}
                  cropped={false}
                />
                <InfoLabel
                  title={strings.placements.fields.isJobOrderOpen}
                  description={
                    placement?.job_still_open ? strings.shared.ui.yes : strings.shared.ui.no
                  }
                  className={classes.infoLabel}
                />
                <InfoLabel
                  title={strings.placements.fields.isCandidateOpen}
                  description={
                    placement?.candidate_still_available
                      ? strings.shared.ui.yes
                      : strings.shared.ui.no
                  }
                  className={classes.infoLabel}
                />
                {placement?.fall_off_notes && (
                  <InfoLabel
                    title={strings.placements.fields.falloffNotes}
                    description={placement.fall_off_notes}
                    className={classes.infoLabel}
                  />
                )}
                <Divider style={globalStyles.mediumDivider} />
              </>
            )}
            {shouldShowReadOnlyView ? (
              <PlacementDetail
                placement={placement}
                splits={splits.length ? splits : initialSplits}
                fileCategories={fileCategories}
                files={groupedFiles}
                referenceReleases={referenceReleaseState?.results}
                assignmentDataSheet={assignmentDataSheet}
                candidateDataSheet={candidateDataSheet}
              />
            ) : (
              <NumberedForm>
                <PlacementBasicSection
                  enableCopy={enableCopyPaster}
                  sendout={sendout}
                  feeAgreements={feeAgreements}
                  isReadOnly={!canEdit}
                  isAmountEditable={isFinanceEditing}
                  placement={placement}
                  areAgreementChangesRequested={
                    placement.requested_fee_change || placement.requested_guarantee_days_change
                  }
                />
                {initialSplits?.length && (
                  <PlacementSplitsSection
                    copyPaste={enableCopyPaster}
                    initialSplits={initialSplits}
                    onSplitsChange={handleSplitsChange}
                    readOnly={!canEdit}
                    canChangeChannelPartner={isFinanceEditing}
                  />
                )}
                <PlacementReferenceChecks
                  candidateId={candidate.id}
                  candidateName={candidate?.personalInformation?.full_name}
                  preSelectedReferences={placement?.[FormFieldsMap.References.key]}
                  placementStatusId={placement?.placement_status_id}
                />
                <When condition={assignmentDataSheetURL}>
                  <PlacementDataSheetView
                    registeredDate={assignmentDataSheet?.updated_at}
                    title={strings.placements.sections.assignmentDataSheet.title}
                    url={assignmentDataSheetURL}
                  />
                </When>
                <When condition={candidateDataSheetURL}>
                  <PlacementDataSheetView
                    registeredDate={candidateDataSheet?.updated_at}
                    title={strings.candidates.profile.dataSheet.title}
                    url={`${candidateDataSheetURL}#${CANDIDATES_PROFILE_HASHES.EstablishingUrgency}`}
                  />
                </When>
                {isUserFinance && (
                  <>
                    <PlacementCompanySection sendout={sendout} copyPaste={enableCopyPaster} />
                    <PlacementCandidateSection candidate={candidate} copyPaste={enableCopyPaster} />
                  </>
                )}

                {fileCategories.length > 0 && files?.length > 0 && (
                  <PlacementFilesSection
                    fileCategories={fileCategories}
                    files={groupedFiles}
                    softDeleteForExistingItems
                    fileExplorerSections={fileExplorerSections}
                    readOnly={!canEdit}
                  />
                )}
                {newestReferenceReleaseEmail && (
                  <PlacementReferenceReleaseView
                    email={newestReferenceReleaseEmail}
                    candidateId={candidate?.id}
                  />
                )}
                <PlacementAdditionalDetails readOnly={!canEdit} company={company} />
                {isFinanceAdjusting && (
                  <input ref={register()} type="hidden" name="isFinanceAdjusting" value="1" />
                )}
              </NumberedForm>
            )}
          </>
        );
    }
  };

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={onClose}
      onBack={handleBack}
      variant="borderless"
      uiState={uiState}
      title={strings.placements.messages.title}
      isTopToolbarNeeded
      isBottomToolbarNeeded={!isLoading}
      onSecondaryButtonClick={onClose}
      primaryProps={{
        key: availableActions.primary,
        initialText: primaryAction.title,
        onProgressText: strings.shared.ui.working,
        type: primaryAction.type === 'submit' ? 'submit' : 'button',
        isSaving: uiState.isSaving,
        isSuccess: uiState.isSuccess,
        disabled: uiState.isSaving,
        onClick: primaryAction.type !== 'submit' ? primaryAction.action : undefined
      }}
      moreActions={availableActions.moreActions.map(each => ({
        title: actions[each].title,
        action: actions[each].action,
        visible: true
      }))}
      subheader={
        placement ? (
          <FPChip size="small" label={placement?.status?.title} color={placement?.status?.style} />
        ) : null
      }
    >
      {isLoading ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <CircularProgress color="inherit" size={40} />
        </Box>
      ) : (
        nestTernary(
          placement,
          <FormContext {...form}>{renderForm()}</FormContext>,
          <EmptyPlaceholder
            title=""
            subtitle={strings.placements.messages.notFound}
            customEmptyState={<EmptyPlacements />}
          />
        )
      )}
    </DrawerContentLayout>
  );
};

PlacementEditForm.defaultProps = {
  placementId: null
};

export default PlacementEditForm;
