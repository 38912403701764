import { SECONDARY_API_CLIENT } from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';

export const saveAssignmentSheet = async (dataSheet, config) => {
  return SECONDARY_API_CLIENT.put(`/${Endpoints.DataSheetSaveCompleted}`, dataSheet, config);
};

export const saveAssignmentSheetDraft = async (dataSheet, config) => {
  return SECONDARY_API_CLIENT.put(`/${Endpoints.DataSheetSaveDraft}`, dataSheet, config);
};

export const getAssignmentSheetById = async assignmentSheetId => {
  return SECONDARY_API_CLIENT.get(`/${Endpoints.DataSheetById.replace(':id', assignmentSheetId)}`);
};
