import React from 'react';
import { SimplifiedTable } from 'features/command-center/components/shared';
import { TABLES_KEYS } from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import { RowActions } from 'UI/constants/defaults';
import { EntityType } from 'UI/constants/entityTypes';

import {
  COLUMNS,
  ORDER_BY_OPTIONS,
  VIRTUAL_PROPS
} from './CallsRelatedCompaniesDrawerContent.constants';

export const CallsRelatedCompaniesDrawerContent = ({ endpoint = '' }) => {
  return (
    <SimplifiedTable
      apiInstance={SECONDARY_API_CLIENT}
      columns={COLUMNS}
      endpoint={endpoint}
      entityType={EntityType.Company}
      orderByOptions={ORDER_BY_OPTIONS}
      pageKey={TABLES_KEYS.drawerCallsRelatedCompanies}
      rowActions={[RowActions.View]}
      shouldUseSavedPagination={false}
      showWrapper={false}
      virtualProps={VIRTUAL_PROPS}
    />
  );
};
