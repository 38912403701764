// @flow
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { getCoachInitialState } from 'features/command-center/utils';
import isEqual from 'lodash/isEqual';
import { useUserContext } from 'providers/UserProvider';
import type { Filters } from 'types/app';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

import { getRegionalInitialState } from '../../layout/DominateSheetLayout/DominateSheetLayout.utils';

interface InlineFiltersProviderProps {
  children: ReactNode;
}

interface InlineContextProps {
  filters: Filters[];
  getFilters: () => Filters[];
  handleFiltersChange: (filters: Filters[]) => void;
}

const InlineFiltersContext = createContext<InlineContextProps | null>(null);

export const useInlineFiltersContext = () => {
  const context = useContext(InlineFiltersContext);
  if (!context) {
    throw new Error('useFiltersContext must be used within a FiltersProvider');
  }
  return context;
};

export const InlineFiltersProvider = ({ children }: InlineFiltersProviderProps) => {
  const [currentUser] = useUserContext();
  const areMultipleDivisionsEnable = hasFeatureFlag(FeatureFlags.HomeMultipleRegions);
  const defaultCoachValue = getCoachInitialState(currentUser);

  const [filters, setFilters] = useState<Filters>({
    ...(areMultipleDivisionsEnable && getRegionalInitialState(currentUser)
      ? {
          regional: {
            paramName: 'regionalIds',
            value: getRegionalInitialState(currentUser)
          }
        }
      : {}),
    ...(defaultCoachValue?.id
      ? {
          coach: {
            paramName: 'coachIds',
            value: defaultCoachValue
          }
        }
      : {})
  });

  const handleFiltersChange = useCallback(
    (newFilters: Filters) => {
      if (isEqual(filters, newFilters)) return;
      setFilters(newFilters);
    },
    [filters]
  );

  const contextValue = useMemo(() => {
    return {
      filters,
      handleFiltersChange
    };
  }, [filters, handleFiltersChange]);

  return (
    <InlineFiltersContext.Provider value={contextValue}>{children}</InlineFiltersContext.Provider>
  );
};
