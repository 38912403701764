// @flow

import { ContactRole, EntityType, SearchProjectItemTypeIds } from 'UI/constants/entityTypes';
import { getPropertyByPath } from 'UI/utils/inventory';

export const entitiesRoles = {
  [ContactRole.Name]: EntityType.Contact,
  [ContactRole.Candidate]: EntityType.Candidate,
  [ContactRole.HiringAuthority]: EntityType.HiringAuthority
};

export const searchProjectRoles = {
  [SearchProjectItemTypeIds.Candidate]: EntityType.Candidate,
  [SearchProjectItemTypeIds.HiringAuthority]: EntityType.HiringAuthority,
  [SearchProjectItemTypeIds.Name]: EntityType.Contact,
  [SearchProjectItemTypeIds.NameCandidate]: EntityType.Contact,
  [SearchProjectItemTypeIds.NameHA]: EntityType.Contact,
  [SearchProjectItemTypeIds.Company]: EntityType.Company
};

const searchProjectProperty = 'item_search_project_type';

export const handleAssignEntityRole = ({ quickViewEntityRoleProps, item, onSetCurrentEntity }) => {
  let role = '';

  const { roleKey, pathToEntityRoleId } = quickViewEntityRoleProps;

  const hasMultipleRolesEntities = pathToEntityRoleId || roleKey;
  const entityRole = hasMultipleRolesEntities && getPropertyByPath(item, hasMultipleRolesEntities);

  Object.hasOwnProperty.call(item, roleKey) && (role = entitiesRoles[entityRole]);

  Object.hasOwnProperty.call(item, searchProjectProperty) &&
    (role = searchProjectRoles[item?.item_search_project_type]);

  role && onSetCurrentEntity(role);
};
