// @flow
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { confirm as confirmAction, showAlert } from 'actions/app';
import { hideReferenceReleaseComposer as hideReferenceReleaseComposerAction } from 'actions/joborder';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useDrawer } from 'hooks/drawerHandler';
import { useProfile } from 'hooks/profileActions';
import useFetchCatalogs from 'hooks/useFetchCatalogs';
import { useVerifyUrgencyDialogManager } from 'hooks/useVerifyUrgencyDialogManager';
import { UserProviderContext } from 'providers/UserProvider';
import { EntityRoutes } from 'routes/constants';
import API from 'services/API';
import { canUserOrStaffEditEntity, userHasRoles } from 'services/Authorization';
import { JobOrderIncludes } from 'services/includes';
import { getSendoutById } from 'services/Sendouts';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import NotAvailable from 'UI/components/molecules/NotAvailable';
import { VerifyUrgencyDialog } from 'UI/components/molecules/VerifyUrgencyDialog';
import ReferenceRelease from 'UI/components/organisms/candidates/ReferenceRelease';
import {
  handleEntityEditionCompleted,
  handleWriteUpActions
} from 'UI/components/organisms/inventoryProfiles/utils';
import JobOrderEdit from 'UI/components/organisms/JobOrderEdit';
import JobOrderSheet from 'UI/components/organisms/JobOrderSheet';
import { PlacementCreateManager } from 'UI/components/organisms/placements/PlacementCreateManager';
import { getPlacementActions } from 'UI/components/organisms/placements/utils';
import ReassignRecruiterForm from 'UI/components/organisms/ReassignRecruiter';
import SendoutsForm from 'UI/components/organisms/sendouts/SendoutForm';
import InventoryProfileLayout from 'UI/components/templates/InventoryProfileLayout';
import { DateFormats } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { CompanyType, EntityType, JobOrderEntity, TabKeys } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { RequestStatus } from 'UI/constants/status';
import { EmptyFeeAgreement } from 'UI/res/images';
import { getErrorMessage, localTimeFormatter } from 'UI/utils';
import { completeReferenceRelease, sendoutDataToForm } from 'UI/utils/inventory';
import { updateUiState } from 'UI/utils/uiHelpers';

import { useFetchDataSheet } from '../EditDataSheet/hooks';

import { useJobOrderProfileFeatureFlags } from './JobOrderProfile.hooks';

type JobOrderProfileProps = {
  enableUrlNavigation: boolean,
  id: number,
  layoutClassName: Object,
  match: any,
  onEditionCompleted: Function,
  onVerificationCompleted: () => void
};

const {
  inventoryProfiles: {
    sections: {
      jobOrders: { notAvailableStrings, duplicateDialog: duplicateDialogCopies }
    }
  },
  inventory: {
    jobOrders: {
      profile: { editButton }
    }
  }
} = strings;

const createTabsProps = ({
  endpoint,
  onActivityComplete,
  onNewPlacementClick,
  onSendoutClick,
  onTriggerTabRefresh,
  onWriteUpClick,
  profileModule,
  profileName,
  jobOrder,
  shouldRefresh,
  tabKeys,
  uiState,
  overviewProps
}) => [
  {
    id: TabKeys.Overview,
    tabProps: {
      profile: jobOrder,
      onWriteUpClick,
      ...overviewProps
    }
  },
  {
    id: TabKeys.Sendouts,
    tabProps: {
      profile: jobOrder,
      profileModule: EntityType.Joborder,
      onNewItemClick: onSendoutClick,
      onSendoutClick,
      shouldRefresh
    }
  },
  {
    id: TabKeys.Placements,
    tabProps: {
      key: tabKeys.placements,
      onNewPlacementClick,
      onTriggerTabRefresh,
      profileId: jobOrder?.id,
      profileModule: EntityType.Joborder
    }
  },
  {
    id: TabKeys.Candidates,
    tabProps: {
      uiState,
      joborderId: jobOrder?.id,
      endpoint,
      profileName
    }
  },
  {
    id: TabKeys.HiringAuthorities,
    tabProps: {
      profile: jobOrder,
      search: false
    }
  },
  {
    id: TabKeys.ActivityLogs,
    tabProps: {
      itemName: jobOrder?.title,
      onActivityComplete,
      profileId: jobOrder?.id,
      profileModule,
      endpoint
    }
  },
  {
    id: TabKeys.Notes,
    tabProps: {
      itemName: jobOrder?.title,
      onActivityComplete,
      profileId: jobOrder?.id,
      profileModule: EntityType.Joborder,
      endpoint
    }
  }
];

const FPJobOrderProfile = ({
  enableUrlNavigation,
  id,
  layoutClassName,
  match,
  onEditionCompleted,
  onVerificationCompleted
}: JobOrderProfileProps) => {
  const location = useLocation();

  const joborderId = id ?? match.params.id;

  const endpoint = `${Endpoints.JobOrders}/${joborderId}`;
  const history = useHistory();
  const dispatch = useDispatch();
  const { referenceRelease } = useSelector(state => state.jobOrder);
  const [currentUser] = useContext(UserProviderContext);
  const editionDrawer = useDrawer(location, history);
  const {
    isOpen: isOpenVerifyUrgencyDialog,
    openDialog: openVerifyUrgencyDialog,
    closeDialog: closeVerifyUrgencyDialog
  } = useVerifyUrgencyDialogManager({
    entity: JobOrderEntity.title
  });
  const { canUseChangeLogsTabs, canUseUrgencyVerification } = useJobOrderProfileFeatureFlags();

  /** To keep track of tabs that should refresh after an event.
   * Remember that a component unmount when key changes */
  const [tabKeys, setTabKeys] = useState({
    sendouts: 0,
    placements: 1
  });

  const {
    handleEditClick,
    handleEditClosed,
    handleEditCompleted,
    handleProfileStateUpdate,
    handleReassignClick,
    handleReassignClosed,
    handleReassignCompleted,
    isLoading,
    refreshData: refreshProfile,
    results,
    setUiState,
    state,
    status,
    uiState
  } = useProfile({
    entity: EntityType.Joborder,
    profileId: joborderId,
    includes: JobOrderIncludes,
    shouldLoadTitle: enableUrlNavigation
  });

  const itemName = results?.title;
  const lastActivity = results?.last_activity_date;

  const handleRefreshActivityStatus = data =>
    handleProfileStateUpdate({ last_activity_date: data?.updated_at });
  const formattedDate = localTimeFormatter(lastActivity, DateFormats.SimpleDateTime);

  const triggerTabRefresh = (nameTab: string) =>
    setTabKeys(prevState => ({ ...prevState, [nameTab]: new Date().getTime() }));

  const handleRefreshTable = () => updateUiState({ shouldRefreshTableData: true }, setUiState);
  const { status: dataSheetFormStatus, dataSheet } = useFetchDataSheet(results?.id);
  const useFetchCatalogsInstance = useFetchCatalogs({ companyId: results?.company?.id });

  const isOverviewLoading =
    dataSheetFormStatus === RequestStatus.Loading ||
    useFetchCatalogsInstance.uiState === RequestStatus.Loading;

  const getInventoryType = () => {
    if (!isOverviewLoading && dataSheet) {
      const jobOrderTypeId = dataSheet?.data.section9.jobOrderType;

      return useFetchCatalogsInstance.catalogs?.jobOrderTypes?.data?.find(
        type => type.id === jobOrderTypeId
      );
    }

    return results?.whiteSheet?.jobOrderType;
  };

  const getJobOrderStatus = () => {
    if (dataSheet && !isOverviewLoading) {
      const jobOrderStatusId = dataSheet?.data.section9.jobOrderStatus;

      return useFetchCatalogsInstance.catalogs?.jobOrderStatuses?.data?.find(
        joStatus => joStatus.id === jobOrderStatusId
      );
    }

    return results?.status;
  };

  const jobOrder = results && {
    ...results,
    status: getJobOrderStatus()
  };

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   P L A C E M E N T   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const referenceReleaseHandler = () => dispatch(hideReferenceReleaseComposerAction());
  const { handleReferenceReleaseComplete } = completeReferenceRelease({
    referenceRelease,
    referenceReleaseHandler
  });

  const handleNewPlacementClick = useCallback(
    ({ sendoutId }) => {
      updateUiState(
        {
          isPlacementOpen: true,
          selectedPlacementId: null,
          selectedSendoutId: sendoutId ? parseInt(sendoutId, 10) : null
        },
        setUiState
      );
    },
    [setUiState]
  );

  const { handlePlacementClosed, handlePlacementCompleted } = getPlacementActions({
    history,
    location,
    onRefreshPlacementTable: handleRefreshTable,
    onTriggerTabRefresh: triggerTabRefresh,
    setUiState
  });

  const handleNewSendoutClick = () => {
    handlePlacementClosed();
    handleSendoutClick();
  };

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   D U P L I C A T E   J O B  O R D E R
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleDuplicateClick = async () => {
    dispatch(
      confirmAction({
        severity: 'warning',
        ...duplicateDialogCopies,
        cancelButtonText: strings.shared.ui.cancel,
        onConfirm: async ok => {
          try {
            if (!ok) return;

            setUiState(prevState => ({ ...prevState, isLoading: true }));
            const response = await API.post(`${endpoint}/duplicate`);
            if (response.data && response.status === HTTPStatusCodes.Created) {
              dispatch(
                showAlert({
                  severity: 'success',
                  title: strings.inventoryProfiles.sections.jobOrders.successDuplication
                })
              );

              const { id: newJobOrderId } = response.data.data;
              history.push(EntityRoutes.JobOrderProfile.replace(':id', newJobOrderId));
            }
          } catch (error) {
            dispatch(
              showAlert({
                severity: 'error',
                title: 'Job Order',
                autoHideDuration: 5000,
                body: getErrorMessage(error)
              })
            );
          } finally {
            setUiState(prevState => ({ ...prevState, isLoading: false }));
          }
        }
      })
    );
  };
  const userCanEdit = canUserOrStaffEditEntity(currentUser, jobOrder);

  const extraMenuItems = [
    {
      title: 'Duplicate',
      action: handleDuplicateClick,
      visible: userCanEdit
    }
  ];

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   S E N D O U T S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleSendoutClick = async (sendout: any) => {
    if (sendout && sendout.id) {
      const sendoutResponse = await getSendoutById(sendout.id);
      if (!sendoutResponse.data) return;
      const formattedSendout = sendoutDataToForm(sendoutResponse.data, {
        id: sendout?.id,
        title: sendout?.title
      });
      updateUiState(
        {
          isSendoutOpen: true,
          selectedSendout: formattedSendout
        },
        setUiState
      );
      return;
    }
    updateUiState(
      {
        isSendoutOpen: true,
        selectedSendoutId: null,
        selectedSendout: null
      },
      setUiState
    );
  };

  const isSendoutAvailable =
    jobOrder && jobOrder?.company?.type?.id !== CompanyType.NotSigned && uiState.isSendoutOpen;

  const goToCompanyProfile = () =>
    history.push(EntityRoutes.CompanyProfile.replace(':id', jobOrder?.company?.id));

  const handleSendoutCompleted = async () => {
    triggerTabRefresh('sendouts');
    handleRefreshTable();
    handleSendoutClosed();
    await refreshProfile();
  };

  const handleSendoutNavigation = (event, options) => {
    handleSendoutClosed();
    const { sendoutId, placementId } = options || {};
    placementId && handleNewPlacementClick({ id: placementId });
    sendoutId && handleNewPlacementClick({ sendoutId });
  };

  const handleSendoutClosed = () =>
    updateUiState({ isSendoutOpen: false, shouldRefreshTableData: false }, setUiState);

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   W H I T E   S H E E T
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const handleDataSheetClick = () => {
    history.push(EntityRoutes.JobOrderEdit.replace(':id', jobOrder?.id));
  };

  const handleWhitesheetClosed = () =>
    uiState.isWriteUpOpen && updateUiState({ isWriteUpOpen: false }, setUiState);

  const handleWriteUpdate = () =>
    handleWriteUpActions({
      onWriteUpClose: handleWhitesheetClosed,
      onRefreshData: refreshProfile
    });

  /** ::::::::::::::::::::::::::::::::::::::::::::::::::::
   *
   *   T A B S   S E T T I N G S
   *
   * :::::::::::::::::::::::::::::::::::::::::::::::::::::
   */

  const tabsProps = createTabsProps({
    jobOrder,
    onWriteUpClick: handleDataSheetClick,
    onSendoutClick: handleSendoutClick,
    shouldRefresh: uiState.shouldRefreshTableData,
    onTriggerTabRefresh: () => triggerTabRefresh(TabKeys.Placements),
    tabKeys,
    onNewPlacementClick: handleNewPlacementClick,
    uiState,
    endpoint,
    onActivityComplete: handleRefreshActivityStatus,
    profileModule: EntityType.Joborder,
    profileName: itemName,
    overviewProps: {
      loading: isOverviewLoading || isLoading,
      catalogs: useFetchCatalogsInstance.catalogs,
      dataSheet
    }
  });

  const canUserEdit = canUserOrStaffEditEntity(currentUser, jobOrder) || !jobOrder;

  const writeUpActions = (() => {
    return [
      {
        title: editButton,
        action: handleDataSheetClick,
        visible: canUserEdit
      },
      {
        title: 'Verify Urgency',
        action: openVerifyUrgencyDialog,
        visible:
          canUseUrgencyVerification &&
          userHasRoles([Roles.RegionalDirector, Roles.AssistantRegionalDirector, Roles.Leadership])
      }
    ];
  })();

  const headerProps = {
    sectionName: 'Job Order',
    title: itemName
  };

  const handleRefreshProfile = async () => {
    await refreshProfile();
  };

  const finalStatus =
    isOverviewLoading || status === RequestStatus.Loading ? RequestStatus.Loading : status;

  return (
    <>
      <InventoryProfileLayout
        activityLabel={formattedDate}
        addItemLabel="Add a Collaborator"
        contentPageLayoutProps={{ className: layoutClassName }}
        enableUrlNavigation={enableUrlNavigation}
        entityType={JobOrderEntity}
        headerCardProps={headerProps}
        inventoryType={getInventoryType()}
        isChangeLogsTabEnabled={canUseChangeLogsTabs}
        recruiters={jobOrder?.additionalRecruiters}
        results={jobOrder}
        profileMenuProps={{
          onReassignClick: handleReassignClick,
          onEditClick: handleEditClick,
          customMenuItems: extraMenuItems,
          writeUpActions
        }}
        ProfileTabsProps={{
          endpoint,
          profileTabProps: tabsProps
        }}
        ProfileUiStateManagerProps={{
          status: finalStatus,
          responseStatusCode: state.responseStatusCode,
          entityRoute: EntityRoutes.JobOrders,
          onRefreshProfileClick: handleRefreshProfile,
          entity: JobOrderEntity
        }}
      />
      <When condition={!isLoading && jobOrder?.id}>
        <>
          <Drawer open={uiState.isEditOpen} onClose={handleEditClosed}>
            <div role="presentation">
              <JobOrderEdit
                joborder={jobOrder}
                onEditCompleted={handleEntityEditionCompleted({
                  handleEditCompleted,
                  enableUrlNavigation,
                  onEditionCompleted
                })}
                onEditClosed={handleEditClosed}
              />
            </div>
          </Drawer>

          <Drawer open={uiState.isWriteUpOpen} onClose={handleWhitesheetClosed}>
            <div role="presentation">
              {uiState.isWriteUpOpen && (
                <JobOrderSheet
                  joborderId={joborderId}
                  companyId={jobOrder?.company?.id || 0}
                  whitesheet={jobOrder?.whiteSheet}
                  profile={jobOrder}
                  isReadOnly={uiState.isWriteUpReadOnly}
                  onWhitesheetCompleted={handleWriteUpdate}
                  onWhitesheetClosed={handleWhitesheetClosed}
                />
              )}
            </div>
          </Drawer>

          <Drawer open={uiState.isSendoutOpen} onClose={handleSendoutClosed}>
            {isSendoutAvailable && uiState.isSendoutOpen && (
              <SendoutsForm
                sendoutData={uiState.selectedSendout}
                profile={jobOrder}
                onCreate={handleSendoutCompleted}
                onEdit={handleSendoutCompleted}
                onNavigate={handleSendoutNavigation}
                onDelete={handleSendoutCompleted}
                onClose={handleSendoutClosed}
                onDuplicateSendoutSelected={handleSendoutClick}
              />
            )}
            {!isSendoutAvailable && uiState.isSendoutOpen && (
              <NotAvailable
                {...notAvailableStrings}
                buttonAction={goToCompanyProfile}
                ImageRender={EmptyFeeAgreement}
                onClose={handleSendoutClosed}
              />
            )}
          </Drawer>
          {uiState.isPlacementOpen && !uiState.selectedPlacementId && (
            <Drawer open={uiState.isPlacementOpen} onClose={handlePlacementClosed}>
              <PlacementCreateManager
                sendoutId={uiState.selectedSendoutId}
                companyId={jobOrder?.company?.id}
                jobOrderId={joborderId}
                onClose={handlePlacementClosed}
                onCompleted={handlePlacementCompleted}
                onNewSendoutClick={handleNewSendoutClick}
              />
            </Drawer>
          )}
          {referenceRelease?.candidateId && (
            <Drawer open onClose={referenceReleaseHandler}>
              <ReferenceRelease
                candidateId={referenceRelease.candidateId}
                onClose={referenceReleaseHandler}
                onCompleted={handleReferenceReleaseComplete}
              />
            </Drawer>
          )}
          <Drawer
            open={uiState.isReassignOpen}
            onClose={editionDrawer.toggleDrawer('isReassignOpen', false)}
          >
            <div role="presentation">
              <ReassignRecruiterForm
                baseEndpoint={Endpoints.JobOrders}
                item={jobOrder}
                entityType={JobOrderEntity}
                onReassignCompleted={handleReassignCompleted}
                onReassignClosed={handleReassignClosed}
              />
            </div>
          </Drawer>
          {isOpenVerifyUrgencyDialog && (
            <VerifyUrgencyDialog
              entityId={joborderId}
              entityType={EntityType.Joborder}
              open={isOpenVerifyUrgencyDialog}
              onClose={closeVerifyUrgencyDialog}
              onSaveSucceeded={() => {
                closeVerifyUrgencyDialog();
                onVerificationCompleted && onVerificationCompleted();
              }}
            />
          )}
        </>
      </When>
    </>
  );
};

export default FPJobOrderProfile;
