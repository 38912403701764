// @flow
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import useFileUpload from 'hooks/fileUpload';
import type { AttachmentCategory, FileExplorerSection } from 'types/app';
import FPIcon from 'UI/components/atoms/FPIcon';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import TextBox from 'UI/components/atoms/TextBox';
import { CloseIcon, colors, SvgUploadButton } from 'UI/res';
import { nestTernary } from 'UI/utils';

import { useStyles } from './styles';

type FileFieldProps = {
  /** List of accepted file types */
  acceptFiles: string[],
  /** The related category of the file */
  category: AttachmentCategory,
  /** Additional class names  */
  className: string,
  /** Default entity type assgined to  new files */
  defaultEntityType: string,
  /** Wheter to disable the field */
  disabled?: boolean,
  /** Endpoint to remove the uploaded file. If no specified, by default uses endpoint */
  deleteEndpoint?: string,
  /** The endpoint to which the file is uploaded */
  endpoint: string,
  /** Error text from validation form */
  errorText?: string,
  /** Sections to show in File Explorer */
  fileExplorerSections: FileExplorerSection[],
  /** Initial value for existing files */
  initialValue?: any,
  /** The name of the field */
  name: string,
  /** Event fired when the selected files changes */
  onChange: (name: string, value: any) => any,
  /** Event fired when the upload starts */
  onUploadStarted?: (name: string, attachment: any) => void,
  /** Wheter to only remove a local file reference or completely remove the file from the server   */
  softDeleteForExistingItems?: boolean
};

const FileField = ({
  acceptFiles,
  category,
  className,
  defaultEntityType,
  disabled,
  deleteEndpoint,
  endpoint,
  errorText,
  initialValue,
  name: fieldName,
  onChange,
  onUploadStarted,
  softDeleteForExistingItems,
  fileExplorerSections
}: FileFieldProps) => {
  const classes = useStyles();

  const {
    canUseFileExplorer,
    file,
    fileName,
    fileRef,
    handleClick,
    handleFileChange,
    handleInputClick,
    isUploading,
    uploadError
  } = useFileUpload({
    initialValue,
    category,
    endpoint,
    deleteEndpoint,
    fieldName,
    onChange,
    onUploadStarted,
    softDeleteForExistingItems,
    defaultEntityType,
    fileExplorerSections
  });

  const { title, required } = category;

  const tooltipText = file
    ? 'Clear'
    : nestTernary(canUseFileExplorer, 'Select a file', 'Upload a file');

  const iconColor = !disabled ? colors.oxford : colors.lightgray;
  return (
    <>
      <TextBox
        name={fieldName}
        label={`${title} ${required ? '*' : ''}`}
        placeholder="Choose a file"
        value={fileName || ''}
        error={!!(errorText || uploadError)}
        errorText={errorText || uploadError}
        className={clsx(classes.input, className)}
        onClick={handleInputClick}
        InputProps={{
          readOnly: true,
          endAdornment: !disabled && (
            <FPIconButton
              onClick={handleClick}
              disabled={isUploading || disabled}
              tooltipProps={{ title: tooltipText }}
            >
              {isUploading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                nestTernary(
                  file,
                  <CloseIcon fill={iconColor} />,
                  <FPIcon color={iconColor} size={24} component={SvgUploadButton} />
                )
              )}
            </FPIconButton>
          )
        }}
        outPutValue={disabled}
      />
      <input
        ref={fileRef}
        type="file"
        accept={acceptFiles.join(',')}
        hidden
        onChange={handleFileChange}
      />
    </>
  );
};

FileField.defaultProps = {
  acceptFiles: [
    'image/png',
    'image/jpg',
    'image/jpeg',
    '.pdf',
    '.docx',
    '.doc',
    '.pptx',
    '.ppt',
    '.xlsx',
    '.xls',
    '.csv',
    '.zip'
  ],
  disabled: false,
  deleteEndpoint: null,
  errorText: null,
  initialValue: null,
  onChange: () => {},
  onUploadStarted: undefined,
  softDeleteForExistingItems: false,
  fileExplorerSections: [],
  className: null
};

export default FileField;
