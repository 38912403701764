import React from 'react';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { DateFormats } from 'UI/constants/defaults';
import { localTimeFormatter } from 'UI/utils';

import { formatInternationalPhone } from '../messageChat/chat.utils';

import { useStyles } from './styles';

export const Conversation = ({ conversation, onClickConversation, isSelected }) => {
  const {
    fullName = '',
    externalNumber,
    date,
    message: messageText,
    unreadMessages = 0
  } = conversation;

  const hasUnreadMessages = unreadMessages > 0;
  const styles = useStyles();

  const dateFormatted = localTimeFormatter(date, DateFormats.SimpleDateTime);
  const phoneNumber = formatInternationalPhone(externalNumber);

  const handleClickConversation = () => {
    onClickConversation && onClickConversation(conversation);
  };

  return (
    <ListItem
      aria-label="conversation"
      button
      className={styles.conversationContainer}
      onClick={handleClickConversation}
      selected={isSelected}
    >
      <ListItemText
        primary={
          <>
            <div className={styles.header}>
              <Typography variant="body2">
                <span className={styles.boldText}>{fullName}</span>
                {phoneNumber}
              </Typography>
              <Typography variant="body2">{dateFormatted}</Typography>
            </div>

            <div className={styles.content}>
              <Typography className={styles.messageText}>{messageText}</Typography>
              <Badge
                badgeContent={unreadMessages}
                className={styles.unreadBadge}
                color="primary"
                invisible={!hasUnreadMessages}
                max={9}
              />
            </div>
          </>
        }
      />
    </ListItem>
  );
};
