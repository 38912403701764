import React from 'react';
import { CustomTableToolbarSelect } from 'hooks/multipleSelection';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import FPButtonMenu from 'UI/components/molecules/FPButtonMenu';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { SvgDeleteFilled, SvgDriveFileMoveRounded } from 'UI/res';
import { hasFeatureFlag } from 'UI/utils';

const setPrimaryColor = ({ primary }) => primary.main;
const { toolbar: toolbarCopies } = strings.searchProjects.mainPage.dataTable;

const CustomSearchProjectsIntoFoldersToolbar = ({ onOpenFolderDrawer, totalSelected }) => {
  const moveSearchProjectsMenuItems = [
    {
      title: toolbarCopies.moveItemsMenuButton.menuItems.moveToExisting,
      action: onOpenFolderDrawer({ moveToExistingFolder: true, open: true }),
      visible: true
    },
    {
      title: toolbarCopies.moveItemsMenuButton.menuItems.moveToNew,
      action: onOpenFolderDrawer({ moveToNewFolder: true, open: true }),
      visible: true
    }
  ];

  return (
    <CustomTableToolbarSelect total={totalSelected}>
      <FPButtonMenu
        menuItems={moveSearchProjectsMenuItems}
        title={toolbarCopies.moveItemsMenuButton.title}
        isIconButton
        icon={SvgDriveFileMoveRounded}
        IconButtonProps={{
          iconProps: {
            onSetColor: setPrimaryColor
          }
        }}
      />
      <When condition={hasFeatureFlag(FeatureFlags.SearchProjectsArchiveMultiple)}>
        <FPIconButton
          icon={SvgDeleteFilled}
          iconProps={{
            onSetColor: setPrimaryColor
          }}
          onClick={() => {
            /* TODO: add archive multiple search projects feature */
          }}
          tooltipProps={{ placement: 'bottom', title: toolbarCopies.archiveMultipleButton.title }}
        />
      </When>
    </CustomTableToolbarSelect>
  );
};

export default CustomSearchProjectsIntoFoldersToolbar;
