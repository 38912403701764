import React from 'react';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';

import useAssignmentSheet from './hooks/useAssignmentSheet';
import { StyledGpacGeneratorForm, useDataSheetDrawerStyles } from './AssignmentSheetDrawer.styled';

const AssignmentSheetDrawer = props => {
  const { assignmentSheetId, drawerTitle, whiteSheet, companyId, onClose, onSaveAssignmentSheet } =
    props;
  const classes = useDataSheetDrawerStyles();
  const [dataSheetState, dataSheetActions] = useAssignmentSheet({
    assignmentSheetId,
    whiteSheet,
    companyId,
    onSaveAssignmentSheet,
    onClose
  });

  return (
    <DrawerContentLayout
      containerComponent="div"
      onClose={onClose}
      uiState={{
        isFormLoading: dataSheetState.isFetching || dataSheetState.isFetchingAssignmentSheetData
      }}
      primaryProps={{
        onClick: dataSheetActions.onSaveClick,
        isSaving: dataSheetState.isSaving,
        disabled: dataSheetState.isSaving,
        initialText: dataSheetState.primaryButtonName
      }}
      title={drawerTitle}
      drawerProps={{ open: true, classes: { paper: classes.drawerLarge } }}
      contentProps={{ className: classes.drawerContent }}
      variant="blue"
    >
      {!!dataSheetState.data && (
        <StyledGpacGeneratorForm
          schema={dataSheetState.data.structure}
          uiSchema={dataSheetState.data.ui}
          httpOptions={dataSheetState.httpOptions}
          showErrorList={false}
          formData={dataSheetState.formData}
          onSubmit={dataSheetActions.save}
          onChange={dataSheetActions.handleOnChange}
          submitRef={dataSheetState.saveButtonRef}
          baseUrl={dataSheetState.baseUrl}
          noHtml5Validate
          focusOnFirstError
        />
      )}
    </DrawerContentLayout>
  );
};

export default AssignmentSheetDrawer;
