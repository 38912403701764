// @flow
import React, { forwardRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { showAlert } from 'actions/app';
import { THEME } from 'GlobalStyles';
import useDatatable, { getColumnPreferences, getColumnsToRender } from 'hooks/datatable';
import {
  getSearchProjectAddItemsPreview,
  SEARCH_PARAMS_KEYS,
  SEARCH_PROJECT_CREATED_FROM_VARIANTS
} from 'services/searchProjects';
/** Atoms, Components and Styles */
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import DataTable from 'UI/components/organisms/DataTable';
import ListPageLayout from 'UI/components/templates/ListPageLayout';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, ListingFilters } from 'UI/constants/entityTypes';
import { useFixedDatatableStyles } from 'UI/globalStyles/DataTableStyles';
import { OutlinedWarningIcon } from 'UI/res';

import { ColumnsDefinitions } from './columns';
import { useStyles } from './styles';

const CustomWarningAlert = forwardRef(({ children }, ref) => (
  <div ref={ref}>
    <b>{children[0]}</b> out of <b>{children[1]}</b> selected items won&apos;t be added because
    they&apos;re already in this Search Project
  </div>
));

type ProjectListProps = {
  createdFrom: string,
  itemsToAdd: Object,
  onRowClick: (id: Number) => void,
  queryParams: Object
};

const pageKey = 'searchProjects-add';

const orderByOptions = {
  column: 'created_at',
  direction: 'desc'
};

const mainOptions = [
  { id: 1, title: 'My Search Projects' },
  { id: 3, title: 'My Collaborations' }
];

const itemsInitialState = {
  new: 0,
  repeated: 0
};

const ProjectList = ({
  createdFrom,
  itemsToAdd,
  onRowClick,
  queryParams,
  searchProjectId
}: ProjectListProps) => {
  const dispatch = useDispatch();
  const initialPreferences = getColumnPreferences(pageKey, 0, orderByOptions, ColumnsDefinitions);

  const extendParams = useCallback(({ userFilter: usrFilter }, { isPrivate }) => {
    const optionalFilters = {};
    if (isPrivate) optionalFilters.isPrivate = isPrivate.value.isPrivate;
    return {
      searchProjectFilter: usrFilter?.id || 1,
      ...optionalFilters
    };
  }, []);

  const {
    handleOneFilterChange,
    handleKeywordChange,
    handleColumnSortChange,
    handlePerPageChange,
    handlePageChange,
    handleColumnDisplayChange,
    listState,
    columnPreferences,
    data,
    count,
    columnOrder
  } = useDatatable({
    key: pageKey,
    endpoint: Endpoints.SearchProjects,
    entityName: 'Search Projects',
    entityType: EntityType.SearchProjects,
    initialPreferences,
    columnsDefinitions: ColumnsDefinitions,
    orderByOptions,
    paramsExtender: extendParams,
    excludeUserFilters: [
      ListingFilters.FreeGame,
      ListingFilters.MyDig,
      ListingFilters.MyTeam,
      ListingFilters.All
    ]
  });

  const { data: bulkEmailInPreviewData } = useSelector(
    state => state.bulkEmail.domain.bulkEmailInPreview
  );
  const bulkId = bulkEmailInPreviewData?.id;

  const { columns, orderBy, direction } = columnPreferences;
  const finalColumns = getColumnsToRender(columns);

  const { isLoading, showWholeSkeleton, page, perPage, keyword, userFilter } = listState;

  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [items, setItems] = useState(itemsInitialState);

  const classes = useStyles();
  const fixedTableClasses = useFixedDatatableStyles();

  const handleRowSelectionChange = current => {
    setIsLoadingPreview(true);
    const currentIndex = current[0].index;
    const selectedSearchProjectId = data[currentIndex].id;
    const paramsType =
      createdFrom === SEARCH_PROJECT_CREATED_FROM_VARIANTS.bulkMetrics
        ? SEARCH_PARAMS_KEYS.MetricsParams
        : SEARCH_PARAMS_KEYS.SearchParams;
    const previewParams = {
      currentSearchProjectId: searchProjectId,
      selectedSearchProjectId,
      itemsToAdd,
      queryParams,
      createdFrom,
      paramsType,
      bulkId
    };
    getSearchProjectAddItemsPreview(previewParams)
      .then(res => {
        const {
          data: { repeatedIdsCount, newIdsCount },
          success
        } = res;
        if (success) {
          setSelectedRow([currentIndex]);
          setItems({
            new: newIdsCount || 0,
            repeated: repeatedIdsCount || 0
          });
          const finalData = data.map((val, i) =>
            i !== currentIndex
              ? { ...val, added: null, repeated_items: null }
              : { ...val, added: newIdsCount, repeated_items: repeatedIdsCount }
          );
          setPreviewData(finalData);
          onRowClick && onRowClick(selectedSearchProjectId);
        } else {
          dispatch(showAlert(res.alert));
        }
      })
      .catch(error => dispatch(showAlert(error.alert)))
      .finally(() => setIsLoadingPreview(false));
  };

  const handleUserFilterChange = (name, value) => {
    handleOneFilterChange(name, value);
    setPreviewData([]);
    setSelectedRow([]);
    setItems(itemsInitialState);
    onRowClick && onRowClick(null);
  };

  return (
    <ListPageLayout loading={showWholeSkeleton} mode="contained">
      {previewData[selectedRow[0]]?.added !== undefined &&
        !!previewData[selectedRow[0]]?.repeated_items &&
        items.repeated > 0 && (
          <div className={classes.alertContainer}>
            <Alert
              className={classes.alert}
              severity="warning"
              icon={<OutlinedWarningIcon fill={THEME.palette.warning.main} />}
            >
              <CustomWarningAlert>
                {[items.repeated, items.repeated + items.new]}
              </CustomWarningAlert>
            </Alert>
          </div>
        )}
      <div className={classes.userFilter}>
        <AutocompleteSelect
          name="userFilter"
          placeholder="Search Projects to Show"
          selectedValue={userFilter || mainOptions[0]}
          onSelect={handleUserFilterChange}
          defaultOptions={mainOptions}
        />
      </div>
      <DataTable
        classes={{ responsiveBase: 'responsiveBase' }}
        className={fixedTableClasses.fixedList}
        loading={showWholeSkeleton}
        refreshing={isLoading || isLoadingPreview}
        data={previewData.length > 0 ? previewData : data}
        columns={finalColumns}
        count={count}
        page={page}
        rowsPerPage={perPage}
        searchText={keyword}
        filter={false}
        selectToolbarPlacement="none"
        sortOrder={{ name: orderBy, direction }}
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        selectableRows="single"
        selectableRowsOnClick
        selectableRowsHideCheckboxes
        rowsSelected={selectedRow}
        onColumnDisplayClick={handleColumnDisplayChange}
        onColumnSortChange={handleColumnSortChange}
        onPageClick={handlePageChange}
        onPerPageClick={handlePerPageChange}
        onRowSelectionChange={handleRowSelectionChange}
        onSearchTextChange={handleKeywordChange}
      />
    </ListPageLayout>
  );
};

export default ProjectList;
