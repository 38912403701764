// @flow
import { createStaticRanges } from 'react-date-plus-time-range';
import { defaultStaticRanges } from 'react-date-plus-time-range/dist/defaultRanges';
import isNil from 'lodash/isNil';
import moment from 'moment';
import { userHasRole } from 'services/Authorization';
import strings from 'strings';
import type { DatePeriod } from 'types/app';
import { AvatarUserColors } from 'UI/components/atoms/FPAvatar/styles';
import { DateFormats } from 'UI/constants/defaults';
import { Roles } from 'UI/constants/roles';

import 'moment-timezone';
/**
 * Fuse two or more style objects into one.
 * @param {Array} stylesArray array of objects with inline style key values.
 * @return {Object} an object containing all the style key values.
 */

export const removePercentageFromStringNumber = (number: string) => {
  return `${number}`.replace('%', '');
};

export const fuseStyles = (styleObjectsArray: Array<Object>) => {
  let newStyle = {};
  styleObjectsArray.forEach(style => {
    newStyle = {
      ...newStyle,
      ...style
    };
  });
  return newStyle;
};

/**
 * It calculates the decimal value between 0 and 255 departing from a string of chars (initials).
 * @param {string} initialsString is the string of initials.
 * @return {string} a string with HEX color format ready for styles.
 */

export const calculateRgbColorFromInitials = (initialsString: string) => {
  const charArrayOfInitials = initialsString.split('');
  const ColorArray = [255, 255, 255];
  let hash = 0;

  charArrayOfInitials.forEach((initial, i) => {
    ColorArray[i] = initialsString.charCodeAt(i).toString(8);
    // eslint-disable-next-line no-bitwise
    hash = initialsString.charCodeAt(i) + ((hash << 5) - hash);
  });
  // eslint-disable-next-line no-bitwise
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();
  const color = `#${'00000'.substring(0, 6 - c.length)}${c}`;
  return color;
};

/**
 * It helps avoiding nested ternary expressions.
 * @param {boolean} condition that must be evaluated.
 * @param {any} then if true.
 * @param {any} otherwise if is false.
 * @return {any}
 */

export const nestTernary = (condition: boolean, then: any, otherwise: any) =>
  condition ? then : otherwise;

/**
 * Round to a specified number of decimals
 * @param {number} number number to be rounded.
 * @param {number} n number of decimals.
 */
export const roundDecimals = (number: number, n: number) => {
  const multiplier = 10 ** n;
  return Math.floor(number * multiplier) / multiplier;
};

/**
 * Calculate the relative difference between two numbers
 * @param {number} a first number.
 * @param {number} b second number.
 */
export const relDiff = (a: number, b: number) => {
  return 100 * Math.abs((a - b) / ((a + b) / 2));
};

/**
 * Returns an url starting with https if no protocol was provided in the URL. For instance, www.linkedin.com becomes https://www.linkedin.com
 * @param {number} url first number.
 */
export const normalizeUrl = (url: string): string => {
  if (!url) {
    return '';
  }

  const hasProtocol = /^[a-z0-9]+:/.test(url);
  const prefix = hasProtocol || url.startsWith('www.') ? '' : 'www.';

  return hasProtocol ? url : `http://${prefix}${url}`;
};

/**
 * Function that returns array containing .env feature flags
 * @returns {Array}
 */
export const getFeatureFlags = (): string[] => {
  const featureFlagsSetting = `${
    (window.GPAC_ENV && window.GPAC_ENV.FEATURE_FLAGS) || process.env.REACT_APP_FEATURE_FLAGS
  }`;

  return featureFlagsSetting ? featureFlagsSetting.split('|') : [];
};

/**
 * Function that evaluates if the env contains the provided feature flags
 *
 * @param {string | Array<string> } featureFlags
 * @returns {boolean | Array<boolean>}
 */
export const hasFeatureFlag = featureFlags => {
  const appFeatureFlags = getFeatureFlags();
  if (Array.isArray(featureFlags)) {
    return featureFlags.map(flag => appFeatureFlags.includes(flag));
  }

  return appFeatureFlags.includes(featureFlags);
};

export const VALIDATION_REGEXS = {
  // eslint-disable-next-line no-useless-escape
  URL: /^\s*(?:[A-Za-z0-9]+:\/\/)?(?:(?:(?:[A-Za-z0-9])|(?:[A-Za-z0-9](?:[A-Za-z0-9\-]+)?[A-Za-z0-9]))+(\.))+([A-Za-z]{2,})([\/?])?([\/?][A-Za-z0-9\-%._~:\/?#\[\]@!\$&\'\(\)\*\+,;=]+)?\s*$/,
  // eslint-disable-next-line no-useless-escape
  EMAIL: /^\s*[a-zA-Z0-9._+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+\s*$/,

  SMART_TAGS: /({{[^{]*?)\w(?=\})}}/gim
};

export const PHONE_VALIDATION = {
  minLength: {
    value: 10,
    message: 'Min length is 10'
  },
  maxLength: {
    value: 10,
    message: 'Max length is 10'
  }
};

export const EXT_PHONE_VALIDATION = {
  maxLength: {
    value: 16,
    message: 'Max length is 16'
  }
};

const MIN_PERCENTAGE = 25;
const MAX_PERCENTAGE = 100;
const MIN_PERCENT_VALIDATION = {
  value: MIN_PERCENTAGE,
  message: `Fee percentage must be between ${MIN_PERCENTAGE}% and ${MAX_PERCENTAGE}% `
};

const MAX_PERCENT_VALIDATION = {
  value: MAX_PERCENTAGE,
  message: `Fee percentage must be between ${MIN_PERCENTAGE}% and ${MAX_PERCENTAGE}% `
};

export const REGULAR_PERCENT_VALIDATION = {
  min: MIN_PERCENT_VALIDATION,
  max: MAX_PERCENT_VALIDATION
};

export const POSITIVE_VALUES_VALIDATION = {
  min: {
    value: 0,
    message: 'Only positive values'
  }
};

export const PERCENT_VALIDATION = {
  ...POSITIVE_VALUES_VALIDATION,
  max: MAX_PERCENT_VALIDATION
};

export const SPLIT_PERCENT_VALIDATION = {
  min: {
    value: 1,
    message: 'Splits must be greater than zero'
  },
  max: MAX_PERCENT_VALIDATION
};

export const WARRANTY_VALIDATION = {
  min: {
    value: 30,
    message: 'Min warranty is 30'
  },
  max: {
    value: 365,
    message: 'Max warranty is 365'
  }
};

export const REQUIRED_VALIDATION = { required: 'This field is required' };

const nicknameMaxLength = 45;
export const NICKNAME_VALIDATION = {
  maxLength: {
    value: nicknameMaxLength,
    message: `Max length is ${nicknameMaxLength}`
  }
};

const nameMaxLength = 128;
export const NAME_VALIDATION = {
  maxLength: {
    value: nameMaxLength,
    message: `Max length is ${nameMaxLength}`
  }
};

const titleMaxLength = 512;
export const TITLE_VALIDATION = {
  maxLength: {
    value: titleMaxLength,
    message: `Max length is ${titleMaxLength}`
  }
};

const achievementMaxLength = 1024;
export const ACHIEVEMENT_VALIDATION = {
  maxLength: {
    value: achievementMaxLength,
    message: `Max length is ${achievementMaxLength}`
  }
};

const urlMaxLength = 512;
export const URL_VALIDATION = {
  pattern: {
    value: VALIDATION_REGEXS.URL,
    message: 'The link must be valid'
  },
  maxLength: {
    value: urlMaxLength,
    message: `Max length is ${urlMaxLength}`
  }
};

export const NOTIFICATIONS_PERMISSION_TYPES = {
  DEFAULT: 'default',
  DENIED: 'denied',
  GRANTED: 'granted'
};

export const NOTIFICATIONS_SERVICE_TYPES = {
  BACKGROUND_MESSAGE: 'BACKGROUND_MESSAGE_NOTIFICATION',
  CLICK_BACKGROUND_MESSAGE: 'CLICK_BACKGROUND_MESSAGE_NOTIFICATION',
  PUSH_RECEIVED: 'push-received',
  TOKEN_STORE_NAME: 'notificationToken',
  PERMISSION_STORE_NAME: 'notificationPermission',
  MESSAGE_CENTER: 'message_center'
};

/**
 * Make uppercase the first letter of a string
 * @param {string} str string to capitalize first letter
 */

export const capitalizeFirstLetter = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : null;

/**
 * It splits a string by dot and underscore, then capitalizes the first letter of each word
 * @returns A function that takes a string and returns a string.
 */
export const splitTextByDotAndUnderscore = text => {
  return capitalizeFirstLetter(
    text
      .split('.')
      .map(part => part.split('_').map(capitalizeFirstLetter).join(' '))
      .join(' ')
  );
};

/**
 * Make a filtering function for the Autocomplete component that allow to look up every word into many fields
 * @param {string[]} fieldsToLookupInto array of fields/properties of an object to look up into.
 */
export const makeMultiFieldFiltering =
  (fieldsToLookupInto: string[]) =>
  (options: any[], event: any): any[] => {
    const term = event.inputValue.toLowerCase();
    const words = term.split(' ').filter(word => word);

    return options.filter(
      option =>
        option[fieldsToLookupInto[0]].toLowerCase().startsWith(term) ||
        words.every(word =>
          fieldsToLookupInto.some(field => option[field].toLowerCase().includes(word))
        )
    );
  };

/**
 * Extracts the first message of a response with errors (could be an object or array payload)
 * @param {Error} error Error catched in an Exception
 */
export const getErrorMessage = (
  error: any,
  defaultMessage = strings.shared.errors.defaultMessage
) => {
  if (!error || !error.request || !error.response || error.request.responseType !== 'json') {
    return defaultMessage;
  }
  const errorData = error.response.data;

  return !errorData
    ? error.response.statusText
    : nestTernary(
        Array.isArray(errorData),
        errorData[0]?.message,
        errorData?.message?.message ||
          errorData?.message ||
          errorData?.error?.message ||
          defaultMessage
      );
};

export const currencyFormatter = (
  inputNumber: number,
  fractionDigits?: number,
  options?: {
    notation: 'compact',
    forceCompactNotation: boolean
  }
) => {
  if (options?.notation === 'compact') {
    const formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: fractionDigits || 0,
      roundingMode: 'trunc'
    });

    return inputNumber < 1e3 && options.forceCompactNotation
      ? `$${(inputNumber / 1e3).toFixed(fractionDigits)}K`
      : formatter.format(inputNumber);
  }

  if (
    inputNumber === null ||
    inputNumber === undefined ||
    inputNumber === '' ||
    Number.isNaN(inputNumber)
  )
    return '';
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: fractionDigits || 0
  }).format(inputNumber);
};

export const numberFormatter = (inputNumber: number, format: string) => {
  switch (format) {
    case 'percent':
      return `${Math.round(inputNumber).toFixed(0)}%`;
    default:
      return new Intl.NumberFormat('en').format(inputNumber);
  }
};

export const compensationFormatter = (min: number, mid: number, max: number): string =>
  `${currencyFormatter(max)} - ${currencyFormatter(mid)} - ${currencyFormatter(min)}`;

export const phoneNumberFormatter = (phoneNumber: ?string): string => {
  if (!phoneNumber) return '';
  return phoneNumber.replace(/^(\d{3})(\d{3})(\d{1})/, '($1)-$2-$3');
};

export const formatDuration = seconds => {
  const oneHourInSeconds = 3600;
  const oneMinuteInSeconds = 60;
  const hours = Math.floor(seconds / oneHourInSeconds);
  const minutes = Math.floor((seconds % oneHourInSeconds) / oneMinuteInSeconds);
  const secs = seconds % oneMinuteInSeconds;

  if (hours > 0) {
    return `${hours} hrs${minutes > 0 ? `, ${minutes} min` : ''}`;
  }
  if (minutes > 0) {
    return `${minutes} min${secs > 0 ? `, ${secs} sec` : ''}`;
  }
  return `${secs} sec`;
};

export const getDefaultDashboardPeriod = (): DatePeriod => getRangePeriod([1, 'year']);
export const getYearToDate = (): DatePeriod => getPeriodToDate('year');
export const getLastYear = (): DatePeriod => getPeriodFromPreviousYear(1, 'year');
export const getLastTwelveMonths = (): DatePeriod => getRangePeriod([1, 'year']);
export const getLastTwentyFourMonths = (): DatePeriod => getRangePeriod([2, 'year']);
export const getCurrentWeekRange = (): DatePeriod => ({
  startDate: moment().startOf('week').toDate(),
  endDate: moment().endOf('week').toDate()
});

/**
 * @function dateFormatter
 * @param {string} date
 * @param {DateFormatsCallback} callback
 * @returns {string} Formatted date
 */
export const dateFormatter = (date, callback) =>
  date
    ? moment(date).format(callback ? callback(DateFormats) : DateFormats.SimpleDateHyphen)
    : null;

/**
 * Gets a YTD (Year To Date) MTD (Month To Date) period
 * Returns the period starting from the beginning of the current unit of time up until now
 * @param string unitOfTime The unit of time for the period. i.e: year, month, day, quarter
 * @returns DatePeriod
 */
export const getPeriodToDate = (unitOfTime: string = 'day'): DatePeriod => ({
  startDate: moment().startOf(unitOfTime).toDate(),
  endDate: moment().endOf('day').toDate()
});

/**
 * It returns an object with a startDate and endDate property, where the startDate is the beginning of
 * the current day, and the endDate is the end of the current day
 */
export const getCurrentPeriod = (unitOfTime: string = 'day'): DatePeriod => ({
  startDate: moment().startOf(unitOfTime).toDate(),
  endDate: moment().endOf(unitOfTime).toDate()
});

/* Creating a function that returns a DatePeriod object. */
export const getPeriodFromPreviousYear = (
  subtract: number = 1,
  unitOfTime: string = 'year'
): DatePeriod => ({
  startDate: moment().startOf(unitOfTime).subtract(subtract, unitOfTime).toDate(),
  endDate: moment().subtract(subtract, unitOfTime).endOf(unitOfTime).toDate()
});

/**
 * Gets the DatePeriod
 * @param {[number, string]} start params to moment.subtract()
 * @param {*} end params to moment.endOf()
 * @returns DatePeriod
 */
export const getRangePeriod = (start, end): DatePeriod => ({
  startDate: moment()
    .subtract(...start)
    .startOf('day')
    .toDate(),
  endDate: moment()
    .endOf(end || 'day')
    .toDate()
});

export const industrySpecialtyOptionLabel = (item: any) =>
  `${item.industry_title || item.industry?.title || ''} › ${item.title}`;

export const titleOptionLabel = (item: any) => item.title;

export const countryCodeOptionLabel = (item: Object) => `(${item?.country_iso_2}) ${item?.code}`;

export const valueOptionLabel = (item: any) => `${item}`;

export const idOptionSelected = (option: any, value: any) => option.id === value?.id;

export const valueOptionSelected = (option: any, value: any) => option === value;
export const relocationsGetOptionLabel = option =>
  option.is_state ? `${option.title}` : `${option.title}, ${option.slug || option.state_slug}`;

export const relocationsGetOptionSelected = (option, value) => option.id === value.id;
export const relocationsGroupBy = option => option.state;

export const countryStateOptionLabel = (item: any) =>
  `${item?.country_slug || item.country?.slug || ''}: ${item.title}`;

/**
 * Builds a formatted string that represents a period, considering if the period crosses a month or year change, i.e.:
 * 01/Aug/2020 - 03/Aug/2020 => Aug 01 - 03, 2020
 * 31/Jul/2020 - 02/Aug/2020 => Jul 31 - Aug 02, 2020
 * 31/Dec/2020 - 02/Ene/2021 => Dec 31, 2020 - Ene 02, 2020
 * @param {Metric} metric Metric object with start and end date
 */
export const formatMetricPeriod = (metric: any) => {
  const startDate = moment.utc(metric.start_date).local();
  const endDate = moment.utc(metric.end_date).local();
  const isPeriodInSameMonth = startDate.month() === endDate.month();
  const isPeriodInSameYear = startDate.year() === endDate.year();
  return isPeriodInSameMonth
    ? `${startDate.format(DateFormats.MonthDay)} - ${endDate.format('DD, YYYY')}`
    : nestTernary(
        isPeriodInSameYear,
        `${startDate.format(DateFormats.MonthDay)} - ${endDate.format(DateFormats.MonthDayYear)}`,
        `${startDate.format(DateFormats.MonthDayYear)} - ${endDate.format(
          DateFormats.MonthDayYear
        )}`
      );
};

export const toLocalTime = (dateTime: string) => {
  if (!dateTime) return null;
  return moment.utc(dateTime).local();
};

/**
 * Function expression that looks multiple matching items depending on map param.
 *
 * @param {string} replace - String to replace
 * @param {string | RegExp} regex - Regular expression to match i.e. /string1|string2|string3/g.
 * Must add global (g) at the end.
 * @param {Object} map - Map object that has by key the match param and by value the desired string
 * to replace with i.e. const obj = { 'string1' : 'My first value' }
 *
 * @returns {string} - Replaced string with new values
 */
export const replaceByMap = (replace: string, regex: string | RegExp, map: Object): string =>
  replace.replace(regex, match => map[match]);

/*
 * method to know if any value of the function is null
 * @param {object} value
 */
export const validateObjectWithoutNulls = (value: any) => {
  const nullsInside = Object.entries(value)
    .map(record => {
      const undefinedsValues = Object.entries(record[1])
        .map(item => item[1])
        .filter(item => item === null);

      return undefinedsValues.length === 0;
    })
    .filter(record => !record);

  if (nullsInside.length === 0) {
    return true;
  }
  return false;
};

/**
 * method to get timezones in long names
 * @param {string[]} timezonesToShow
 */
export const getTimezones = (timezonesToShow: string[]) => {
  const usZones = moment.tz
    .names()
    .map(item => {
      const splitedText = item.split('/');
      if (splitedText[0] === 'US') {
        return item;
      }

      return null;
    })
    .filter(record => record !== null)
    .map(item => {
      const zoneName = item.split('/')[1];
      const initials = `${zoneName[0]}ST`.toUpperCase();
      const offset = moment.tz(moment(), item).utcOffset() / 60;
      const timeSchema = {
        name: item,
        title: `${zoneName} Standard Time`,
        offset_title: `GMT${offset}:00`,
        offset,
        initials
      };

      if (timezonesToShow && timezonesToShow?.length === 0) {
        return timeSchema;
      }

      const formatTimezones = timezonesToShow.map(value => value.toUpperCase());
      if (formatTimezones.indexOf(zoneName.toUpperCase()) !== -1) {
        return timeSchema;
      }

      return null;
    })
    .filter(record => record !== null);
  return usZones;
};

/**
 * its a method to get the timezone specific
 * @param {string} timezoneName its the timezone long name that returns the method getTimezones().
 * @param {string[]} timezoneData
 * @returns
 */
export const getTimezoneByName = (timezoneName: string, timezoneData: any[]) => {
  if (!timezoneName) return null;
  const timezones = timezoneData || getTimezones([]);

  const selectedTimezone = timezones.filter(res => res.name === timezoneName);
  const initials = `${timezoneName[0]}ST`.toUpperCase();

  if (selectedTimezone.length === 0) {
    return {
      name: timezoneName,
      title: `${timezoneName} Standard Time`,
      initials
    };
  }
  return selectedTimezone[0];
};

/**
 * Extract file extension from file name
 * @param {string} fileName File Name
 * @returns {string} File extension
 */
export const getFileExtension = (fileName: string) =>
  !fileName ? '' : fileName.split('.').pop().toLowerCase();

/**
 * Creates a file name of up to maxLength characters preserving the file extensions
 * @param {string} fileName File Name
 * @returns {string} File name with ellipsis and file extension
 */
export const trimFileNameWithExtension = (fileName: string, maxLength?: number = 45) => {
  if (!fileName) return null;
  const extension = getFileExtension(fileName);
  return fileName.length > maxLength ? `${fileName.substr(0, maxLength)}...${extension}` : fileName;
};

/**
 * Function util to fill component or element key
 * to avoid key repetition
 */
export const getId = () => Math.floor(Math.random() * new Date().getTime());

/**
 * Concat the hour from secondary date to primary date.
 * @param {Moment} dateToMerge the date base to add the hours
 * @param {Moment} dateHour the date to get the hours
 * @returns
 */
export const mergeHourToDate = (dateToMerge, dateHour) =>
  dateToMerge
    .startOf('day')
    .add(dateHour.get('minutes'), 'minutes')
    .add(dateHour.get('hours'), 'hours');

/**
 * Extracts file name from url
 * @param {string} url the url to extract the file name from
 * @returns {string} file name from url
 */
export const extractFilenameFromUrl = (url: string) => {
  if (!url) return '';
  const path = url.substring(url.lastIndexOf('/') + 1);
  const stripParamsAndHashRegex = /[^.]+(\.[^?#]+)?/;
  return decodeURIComponent((path.match(stripParamsAndHashRegex) || [])[0]);
};

/**
 * Method that create new statics ranges on date time picker
 * more info for schema: https://www.npmjs.com/package/react-date-plus-time-range#:~:text=*shape%20of-,range,-%3A
 * @param {object[]} ranges schema of date range
 * @param {object} config configuration parameter
 * @param {boolean} config.defaultRanges will have the default parameters. default: true
 * @returns
 */

export const createCustomStaticRanges = (ranges: object[], { defaultRanges } = {}): Array => {
  const defaultDates = defaultRanges === undefined || defaultRanges ? defaultStaticRanges : [];
  const allRanges = [...defaultDates, ...ranges];
  return createStaticRanges(allRanges);
};

export const loadFromLocalStorage = (key, defaultValue) => {
  try {
    const saved = localStorage.getItem(key);
    return !saved ? defaultValue : JSON.parse(saved);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return defaultValue;
  }
};

export const loadFromSessionStorage = (key, defaultValue) => {
  try {
    const item = sessionStorage.getItem(key);
    return !item ? defaultValue : JSON.parse(item);
  } catch {
    return defaultValue;
  }
};

export const convertEmailToPlainText = html =>
  html &&
  html
    .replace(/<style([\s\S]*?)<\/style>/gi, '')
    .replace(/<script([\s\S]*?)<\/script>/gi, '')
    .replace(/<\/div>/gi, '\n')
    .replace(/<\/li>/gi, '\n')
    .replace(/<li>/gi, '  *  ')
    .replace(/<\/ul>/gi, '\n')
    .replace(/<\/p>/gi, '\n')
    .replace(/<br\s*[/]?>/gi, '\n')
    .replace(/<[^>]+>/gi, '')
    .replace(/$\n/gim, '');

/**
 * @param {Array.<{key: string, colName: string}>} columns
 * @param {Array} data
 */
export const generateCSVFile = (columns, data) => {
  const headers = columns.map(({ colName }) => colName).join(',');
  const csvData = [headers];
  data.forEach(row => {
    const innerData = [];
    columns.forEach(({ key }) => {
      innerData.push(row[key]);
    });
    csvData.push(innerData.join(','));
  });
  return csvData.join('\r\n');
};

/**
 * @param {string} data - Data in string format to save within the file
 * @param {string} format
 * @param {string} fileName
 */
export const downloadFile = (data, format, fileName) => {
  const blob = new Blob([data], { type: format });
  const dataURI = `data:${format};charset=utf-8,${data}`;
  const URL = window.URL || window.webkitURL;
  const downloadURI =
    typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.setAttribute('href', downloadURI);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Method to get bytes formateds
 * @param {Number} bytes the bytes to format
 * @param {Number} decimals the fix from Format
 * @returns String formated with KB/MB/GB etc..
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const bytesFactor = 1024;
  const decimalFix = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const unit = Math.floor(Math.log(bytes) / Math.log(bytesFactor));

  return `${parseFloat((bytes / bytesFactor ** unit).toFixed(decimalFix))} ${sizes[unit]}`;
};

/*
 * Set Item delay to each row
 * @param {object} data
 * @returns {object} delay in seconds to apply
 */

export const setItemDelay = (data: Object, item: Object) => {
  const i = `${data.indexOf(item)}00`;
  const delay = 500 + parseFloat(i);
  return delay;
};

export const getAvatarColor = (email: string) => {
  const emailCharacters = email.split('');

  const sum = emailCharacters.reduce(
    (accumulator, current) => accumulator + current.charCodeAt(0),
    0
  );

  return sum % AvatarUserColors.length;
};

/**
 * Method to track an event by App Insights
 * @param {Object} appInsights context
 * @param {string} trackingEvent type of event
 * @param {Object} trackingMeta data to track
 */
export const trackAppInsightsMetric = (appInsights, trackingEvent, trackingMeta) => {
  appInsights &&
    trackingEvent &&
    trackingMeta &&
    appInsights.trackEvent(
      {
        name: trackingEvent
      },
      {
        ...trackingMeta
      }
    );
};

/**
 * Method to format any value to a desired dateFormat
 * @param {Object} item to format
 * @param {string} dateFormat format mode
 */

export const localTimeFormatter = (item, dateFormat) => {
  const localTime = toLocalTime(item);
  const formattedDate = localTime && localTime.format(dateFormat);
  return formattedDate;
};

/**
 * @param {Object} obj - object to be converted into an array
 * @returns {Array}
 */
export const objectToArray = obj => Object.keys(obj).map(key => obj[key]);

/**
 * @param {string} date
 * @returns {{ quantity: number, unitOfTime: 'days'|'hours'|'minutes' }}
 */
export const getRemainingTimeToDate = date => {
  const currentDate = moment();
  const eventDate = moment(date);
  const daysRemaining = eventDate.diff(currentDate, 'days');
  if (daysRemaining > 0) return { quantity: daysRemaining, unitOfTime: 'days' };

  const hoursRemaining = eventDate.diff(currentDate, 'hours');
  if (hoursRemaining > 0) return { quantity: hoursRemaining, unitOfTime: 'hours' };

  const minutesRemaining = eventDate.diff(currentDate, 'minutes');
  return minutesRemaining >= 0 ? { quantity: minutesRemaining, unitOfTime: 'minutes' } : null;
};

/**
 * @param {moment.Moment} date
 * @param {moment.Moment} time
 * @returns {moment.Moment}
 */
export const createDateTime = (date, time) =>
  moment().set({
    year: date.get('year'),
    month: date.get('month'),
    date: date.get('date'),
    hour: time.get('hour'),
    minute: time.get('minute')
  });

export const getTimeInputValidity = (date, time) => {
  const currentDateTime = moment();
  const isCurrentDateSelected =
    !!date &&
    ['year', 'month', 'day'].reduce((prev, curr) => prev && date.isSame(currentDateTime, curr));
  if (isCurrentDateSelected && time) {
    const selectedDateTime = createDateTime(currentDateTime, time);
    const isSelectedTimeAfterCurrentTime = selectedDateTime.isAfter(currentDateTime);
    if (!isSelectedTimeAfterCurrentTime) return false;
  }
  return true;
};

/**
 * The function checks if a given datetime in a specific timezone is after the current datetime in the
 * same timezone.
 * @param timezone - The `timezone` parameter is a string representing the desired timezone. It should
 * be a valid timezone identifier, such as "America/New_York" or "Asia/Tokyo".
 * @param date - The `date` parameter represents a moment.js object that represents a specific date. It
 * can be used to compare the year, month, and day of the selected date with the current date in the
 * specified timezone.
 * @param time - The `time` parameter is a moment object representing the time that you want to compare
 * with the current time in the specified timezone.
 * @returns a boolean value.
 */
export const isDatetimeInTimezoneAfter = (timezone, date, time) => {
  const nowInTimezone = moment().tz(timezone);

  const isCurrentDateSelected =
    !!date &&
    ['year', 'month', 'day'].reduce((prev, curr) => prev && date.isSame(nowInTimezone, curr));

  if (isCurrentDateSelected && time) {
    const selectedDateTime = moment()
      .tz(timezone)
      .set({
        year: date.get('year'),
        month: date.get('month'),
        date: date.get('date'),
        hour: time.get('hour'),
        minute: time.get('minute')
      });

    const isSelectedTimeAfterCurrentTime = selectedDateTime.isAfter(nowInTimezone);
    if (!isSelectedTimeAfterCurrentTime) return false;
  }
  return true;
};

/**
 * This function filters elements based on the elements to remove from an initial array.
 * @param {Array} initialElements - The initial array of elements.
 * @param {Array} elementsToRemove - The array of elements to be removed.
 * @param {string} elementId - The key used to identify elements (default is 'id').
 * @returns {Array} - The filtered array with elements removed.
 */

export const excludeElementsFromArray = ({ initialElements, elementsToRemove, elementId = 'id' }) =>
  initialElements.filter(element => !elementsToRemove.includes(element?.[elementId]));

/**
 * This function filters items to get the final list of columns based on 'hideWhen' conditions.
 * @param {Array} items - The array of items to filter.
 * @returns {Array} - The final list of columns that meet the 'hideWhen' conditions.
 */

export const getFinalColumns = items =>
  items.filter(
    item =>
      !item.hideWhen || (typeof item.hideWhen === 'function' ? !item.hideWhen() : !item.hideWhen)
  );

export const getProfileFileDraggableProps = () => ({
  FileItemProps: {
    menuItemsToDisable: userHasRole(Roles.Operations)
      ? []
      : [strings.fileManager.fileItem.menuItemsTitles.delete]
  }
});

/**
 * The `formatPhoneNumber` function takes a phone number as input and returns a formatted version of
 * the number, ex: '(123)456-7890', with parentheses around the area code and a hyphen between the area code and the line
 * number.
 * @param string - The parameter `phone` is of type `string`, which means it expects a string value as
 * input. This string represents a phone number with 10 digits.
 * @returns The function `formatPhoneNumber` returns a formatted phone number string. If the phone
 * number has 10 digits, it returns the phone number in the format "(areaCode)lineNumber". If the phone
 * number has more than 10 digits, it assumes the first 3 digits are the country code and returns the
 * phone number in the format "(countryCode)areaCode-lineNumber".
 */
export const formatPhoneNumber = (phone: string) => {
  if (!phone) return null;

  // Remove all non-digit characters from the phone number
  const phoneNumber = phone.replace(/\D/g, '');

  // Check if the phone number has 10 digits
  if (phoneNumber.length === 10) {
    const areaCode = phoneNumber.substring(0, 3);
    const lineNumber = phoneNumber.substring(3);
    return `(${areaCode}) ${lineNumber.substring(0, 3)}-${lineNumber.substring(3)}`;
  }

  // Assume the phone number has a country code
  const countryCode = phoneNumber.substring(0, 3);
  const areaCode = phoneNumber.substring(3, 6);
  const lineNumber = phoneNumber.substring(6);
  return `(${countryCode})${areaCode}-${lineNumber}`;
};

/**
 * @param {number | Date} firstValue
 * @param {number  Date} secondValue
 * @param {string} unit - time, currency, scalar
 * @param {object} options - notation: 'compact', standard (default: 'compact') - forceCompactNotation: boolean - fractionDigits: number
 * @returns {string}
 */
export const formatRatio = (firstValue, secondValue, unit, options = {}) => {
  const {
    decimalPlaces = 0,
    defaultEmptyRatio = '',
    forceCompactNotation = false,
    notation = 'compact'
  } = options;

  if (!isValidInput(firstValue, secondValue)) return defaultEmptyRatio;

  const formattedFirstValue = formatValue(firstValue, unit, {
    notation,
    forceCompactNotation,
    decimalPlaces
  });
  const formattedSecondValue = formatValue(secondValue, unit, {
    notation,
    forceCompactNotation,
    decimalPlaces
  });

  return `${formattedFirstValue}/${formattedSecondValue}`;
};

const isValidInput = (first, second) => {
  return (
    !isNil(first) &&
    !isNil(second) &&
    !Number.isNaN(first) &&
    !Number.isNaN(second) &&
    Number.isFinite(first) &&
    Number.isFinite(second)
  );
};

const formatValue = (value, unit, options) => {
  const formatters = {
    currency: () => formatCurrency(value, options),
    time: () => formatTimeToHoursFromSeconds(value, options.decimalPlaces),
    scalar: () => formatScalar(value, options),
    default: () => value
  };

  return (formatters[unit] || formatters.default)();
};

const formatCurrency = (value, { decimalPlaces, notation, forceCompactNotation }) => {
  return currencyFormatter(value, decimalPlaces, { notation, forceCompactNotation });
};

const formatTimeToHoursFromSeconds = (value, decimalPlaces) => {
  const hours = value / 3600;
  return String(hours % 1 === 0 ? hours : hours.toFixed(decimalPlaces));
};

export const formatTimeToHoursFromMinutes = (value, decimalPlaces) => {
  const hours = value / 60;
  return String(hours % 1 === 0 ? hours : hours.toFixed(decimalPlaces));
};

export const formatScalar = (value, { notation, forceCompactNotation, decimalPlaces }) => {
  if (notation !== 'compact') return formatNumber(value, decimalPlaces);

  if (value < 1e3 && forceCompactNotation) {
    return `${(value / 1e3).toFixed(decimalPlaces)}k`;
  }

  const formatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPlaces,
    roundingMode: 'trunc'
  });

  return formatter.format(value);
};

export const formatNumber = (value: number, decimalPlaces: number = 0) => {
  return new Intl.NumberFormat('en').format(value.toFixed(decimalPlaces));
};

export const formatPercentage = (value: number, decimalPlaces: number = 0) => {
  if (Number.isNaN(value) || !Number.isFinite(value)) return value;
  return `${value.toFixed(decimalPlaces)}%`;
};

export const filterColumnsByFeatureFlag = (allCols, columnsToExclude) => {
  const columnsToExcludeFiltered = columnsToExclude
    .filter(colGroup => !hasFeatureFlag(colGroup.featureFlag))
    .flatMap(colGroup => colGroup.columns);

  return allCols.filter(col => !columnsToExcludeFiltered.includes(col.name));
};
