// @flow
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { InlineFilters, SimplifiedTable } from 'features/command-center/';
import {
  COMMAND_CENTER_BASE_URL,
  ENDPOINTS,
  INLINE_FILTERS_KEYS
} from 'features/command-center/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import { SvgInformationCircle } from 'UI/res';

import {
  COLUMNS,
  INLINE_FILTERS,
  METRICS_DESCRIPTION,
  ORDER_BY_OPTIONS
} from './OverallPerformanceReport.constants';
import { useStyles } from './OverallPerformanceReport.styles';
import { formatResponse } from './OverallPerformanceReport.utils';

const COPIES = strings.commandCenter.dailyPerformance.overallPerformanceReport;
const persistenceKey = INLINE_FILTERS_KEYS.dailyPerformance.overallPerformanceReport;

export const OverallPerformanceReport = () => {
  const [filters, setFilters] = useState(null);
  const classes = useStyles();

  const handleFiltersChange = selectedFilters => {
    setFilters(selectedFilters);
  };

  const footer = (
    <div className={classes.footer}>
      <div className={classes.footerTitle}>
        <FPIcon
          role="img"
          aria-label="Info"
          component={SvgInformationCircle}
          onSetColor={palette => palette.info.main}
          size={18}
        />
        <Typography className={classes.label}>{COPIES.footer.title}</Typography>
      </div>
      <ul className={classes.list}>
        {METRICS_DESCRIPTION.map(item => (
          <li key={item.title}>
            <Typography className={classes.label}>
              <span className={classes.title}>{item.title} -</span> {item.content}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <InlineFilters
          filters={INLINE_FILTERS}
          onFiltersChange={handleFiltersChange}
          persistenceKey={persistenceKey}
        />
      </Grid>
      <Grid item xs={12}>
        <SimplifiedTable
          pageKey={persistenceKey}
          apiInstance={SECONDARY_API_CLIENT}
          columns={COLUMNS}
          endpoint={`${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivityRecruiterPerformance}`}
          filters={filters}
          apiVersion={2}
          header={COPIES.title}
          footer={footer}
          orderByOptions={ORDER_BY_OPTIONS}
          formatResponse={formatResponse}
        />
      </Grid>
    </Grid>
  );
};
