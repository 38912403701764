import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  ConversationContent,
  ConversationList,
  SearchContactsDrawer,
  useChat,
  useTextConversations
} from 'features/message-center';
import { formatInternationalPhone } from 'features/message-center/components/messageChat/chat.utils';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { When } from 'UI/components/atoms/When';
import Searchbar from 'UI/components/molecules/Searchbar';
import DualPanelLayout from 'UI/components/templates/DualPanelLayout/DualPanelLayout';
import { UIStatus } from 'UI/constants/status';
import { getEntityType } from 'UI/utils/inventory';

export const MessageCenter = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);

  const {
    conversations,
    handleClickConversation,
    handleCloseSearchContactsDrawer,
    handleFetchMoreConversations,
    handleOpenSearchContactsDrawer,
    handleRefresh,
    handleSearch,
    handleUpdateConversation,
    selectedConversation,
    uiState
  } = useTextConversations();

  const chat = useChat({
    externalNumber: selectedConversation?.externalNumber,
    selectedConversationInList: selectedConversation,
    onUpdateConversation: handleUpdateConversation
  });
  const { handleRefreshConversation } = chat;

  const isEntityTypeValid = !!getEntityType(selectedConversation?.entityType);

  const handleAddNewConversation = ({ externalNumer }) => {
    handleRefresh(externalNumer);
  };

  const handleSearchChange = (_name, value) => setSearchKeyword(value);
  const handleSearchAsync = () => handleSearch(searchKeyword);

  const toggleQuickViewOpen = () => setIsQuickViewOpen(prev => !prev);

  return (
    <>
      <DualPanelLayout
        uiStatus={uiState.status}
        error={{
          title: uiState.error,
          subtitle: strings.shared.errors.serverError.subtitle,
          onClick: handleRefresh
        }}
        leftPanel={{
          header: {
            title: strings.messageCenter.title,
            Actions: (
              <>
                <FPIconButton
                  onClick={handleRefresh}
                  tooltipProps={{
                    title: strings.messageCenter.chat.refresh
                  }}
                >
                  <RefreshIcon />
                </FPIconButton>
                <FPIconButton
                  size={24}
                  onClick={handleOpenSearchContactsDrawer}
                  tooltipProps={{
                    title: strings.messageCenter.newMessage
                  }}
                >
                  <AddCircleIcon color="primary" />
                </FPIconButton>
              </>
            ),
            Header: (
              <Searchbar
                name="keyword"
                value={searchKeyword}
                placeholder={strings.shared.ui.search}
                width="auto"
                onChange={handleSearchChange}
                onSearchAsync={handleSearchAsync}
                showClearButton
              />
            )
          },
          Content: (
            <ConversationList
              conversations={conversations}
              isLoading={uiState.status === UIStatus.Loading}
              onClickConversation={handleClickConversation}
              onFetchMoreConversations={handleFetchMoreConversations}
              searchKeyword={searchKeyword}
              selectedConversationId={selectedConversation?.id}
            />
          )
        }}
        rightPanel={{
          header: {
            HeaderTitle: selectedConversation ? (
              <>
                <Typography variant="h6">{selectedConversation?.fullName}</Typography>
                <Typography variant="body2">
                  {formatInternationalPhone(selectedConversation?.externalNumber || '')}
                </Typography>
              </>
            ) : null,
            Actions: selectedConversation ? (
              <>
                <FPIconButton
                  onClick={handleRefreshConversation}
                  tooltipProps={{
                    title: strings.messageCenter.chat.refresh
                  }}
                >
                  <RefreshIcon />
                </FPIconButton>
                <When condition={isEntityTypeValid}>
                  <FPIconButton
                    onClick={toggleQuickViewOpen}
                    tooltipProps={{
                      title: strings.inventoryProfiles.quickView.viewProfile
                    }}
                  >
                    <VisibilityIcon />
                  </FPIconButton>
                </When>
              </>
            ) : null
          },
          Content: (
            <ConversationContent
              key={selectedConversation?.id}
              isQuickViewOpen={isQuickViewOpen}
              toggleQuickViewOpen={toggleQuickViewOpen}
              selectedConversationInList={selectedConversation}
              {...chat}
            />
          )
        }}
      />

      {uiState.isSearchContactsDrawerOpen && (
        <SearchContactsDrawer
          isDrawerOpen={uiState.isSearchContactsDrawerOpen}
          onClose={handleCloseSearchContactsDrawer}
          onNewConversation={handleAddNewConversation}
        />
      )}
    </>
  );
};
