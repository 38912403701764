import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';

const COPIES = strings.commandCenter.dailyPerformance.overallPerformanceReport.footer;

export const COLUMNS = [
  {
    name: 'idFortpac',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruiterName',
    label: 'Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterActivityRecruiter,
          idKey: 'empNo',
          textKey: 'name',
          enableInTabRedirect: true
        }
      },
      setCellHeaderProps: () => ({ className: 'dataTableStickyLeftColumnHeader' }),
      setCellProps: () => ({
        className: 'dataTableStickyLeftColumn'
      })
    }
  },
  {
    name: 'managerName',
    label: 'Team',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterActivityTeam,
          idKey: 'managerEmpNo',
          textKey: 'team',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'healthStatus',
    label: 'Current Health Status',
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatusChip'
    }
  },
  {
    name: 'hireDate',
    label: 'Start Date',
    options: {
      sort: true,
      display: true,
      renderer: { id: 'dateBuilder', config: { format: DateFormats.SimpleDate } }
    }
  },
  {
    name: 'lastPlacementDate',
    label: 'Last Placement',
    options: {
      sort: true,
      display: true,
      renderer: { id: 'dateBuilder', config: { format: DateFormats.SimpleDate } }
    }
  },
  {
    name: 'thisWeekSendouts',
    label: 'Current Week Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'lastWeekSendouts',
    label: 'Prev. Week 2 Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'thirdWeekSendouts',
    label: 'Prev. Week 3 Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'fourthWeekSendouts',
    label: 'Prev. Week 4 Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'soPlRatio',
    label: 'SO to PL',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'outstandingBillings',
    label: 'Current O. Billings',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'drawBalance',
    label: 'Current Draw Balance',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'avgInMailsByBillingPeriod',
    label: 'InMails',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'bulks',
    label: 'Bulks',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'peopleReached',
    label: 'People Reached',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'todayLivePosts',
    label: 'Posts',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'callsTime',
    label: 'Phone Time',
    options: {
      sort: true,
      display: true,
      renderer: 'phoneTime'
    }
  },
  {
    name: 'calls',
    label: 'Calls',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'notConnectedCalls',
    label: 'Not Conn. Calls',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'totalCalls',
    label: 'Total Calls',
    options: {
      sort: false,
      display: true,
      renderer: 'formattedNumber'
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'recruiterName',
  direction: 'asc'
};

export const INLINE_FILTERS = ['team', 'healthStatus'];

export const METRICS_DESCRIPTION = [
  COPIES.metrics.inMails,
  COPIES.metrics.SOtoPL,
  COPIES.metrics.calls,
  COPIES.metrics.posts
];
