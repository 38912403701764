import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  ActiveInventoryStatsSummary,
  ActivityTrends,
  CallsTrends,
  FinancialStatsSummary,
  GoalsProgressInTime,
  InMailsTrends,
  NotConnectedCallsTrends,
  NotConnectedHourlyCallTrends,
  NotConnectedPhoneTimeTrends,
  PeopleReachedTrends,
  PhoneTimeTrends,
  PilMap,
  PlacementsTrends,
  ProductionCashInByMonth,
  ProductivityStatsSummary,
  SendoutsTrends,
  WeeklyActivityStatsSummary,
  WeeklyInventoryGrowthStatsSummary,
  WeeklyProductivityStatsSummary
} from 'features/command-center';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

export const RecruiterOverview = ({ weeks, recruiterId }) => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const canDoDrillDown = checkIfFeatureFlagEnabled(FeatureFlags.CommandCenterDrillDown);
  const requestParams = {
    recruiter: recruiterId.toUpperCase(),
    ...(canDoDrillDown && { version: 1 })
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PilMap requestParams={requestParams} />
      </Grid>

      <Grid item xs={12}>
        <ActiveInventoryStatsSummary requestParams={requestParams} hasDrillDown={canDoDrillDown} />
      </Grid>

      <Grid item xs={12}>
        <FinancialStatsSummary requestParams={requestParams} />
      </Grid>

      <Grid item xs={12}>
        <ProductivityStatsSummary requestParams={requestParams} hasDrillDown={canDoDrillDown} />
      </Grid>

      <Grid item xs={12}>
        <ProductionCashInByMonth enableDateFilter requestParams={requestParams} />
      </Grid>

      <Grid item xs={12}>
        <GoalsProgressInTime requestParams={requestParams} hasDrillDown={canDoDrillDown} />
      </Grid>

      <Grid item xs={12}>
        <PlacementsTrends requestParams={requestParams} />
      </Grid>

      <Grid item xs={12}>
        <SendoutsTrends requestParams={requestParams} />
      </Grid>

      <Grid item xs={12}>
        <WeeklyActivityStatsSummary requestParams={requestParams} hasDrillDown={canDoDrillDown} />
      </Grid>

      <Grid item xs={12}>
        <WeeklyProductivityStatsSummary
          requestParams={requestParams}
          hasDrillDown={canDoDrillDown}
        />
      </Grid>

      <Grid item xs={12}>
        <WeeklyInventoryGrowthStatsSummary
          requestParams={requestParams}
          hasDrillDown={canDoDrillDown}
        />
      </Grid>

      <Grid item xs={12}>
        <PeopleReachedTrends weeks={weeks} requestParams={requestParams} />
      </Grid>

      <Grid item xs={12}>
        <InMailsTrends weeks={weeks} requestParams={requestParams} />
      </Grid>

      <Grid item xs={12}>
        <CallsTrends weeks={weeks} requestParams={requestParams} hasDrillDown={canDoDrillDown} />
      </Grid>

      <Grid item xs={12}>
        <PhoneTimeTrends
          weeks={weeks}
          requestParams={requestParams}
          hasDrillDown={canDoDrillDown}
        />
      </Grid>

      <Grid item xs={12}>
        <ActivityTrends requestParams={requestParams} hasDrillDown={canDoDrillDown} />
      </Grid>

      <Grid item xs={12}>
        <NotConnectedCallsTrends
          weeks={weeks}
          requestParams={requestParams}
          hasDrillDown={canDoDrillDown}
        />
      </Grid>

      <Grid item xs={12}>
        <NotConnectedPhoneTimeTrends
          weeks={weeks}
          requestParams={requestParams}
          hasDrillDown={canDoDrillDown}
        />
      </Grid>

      <Grid item xs={12}>
        <NotConnectedHourlyCallTrends requestParams={requestParams} hasDrillDown={canDoDrillDown} />
      </Grid>
    </Grid>
  );
};
