import { TABLES_KEYS } from 'features/command-center/constants';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import { DateFormats, FilterType } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

export const PAGE_KEY = TABLES_KEYS.overallPerformanceReport;

const COPIES = strings.commandCenter.dailyPerformance.overallPerformanceReport;

const FinancialFilterGroup = hasFeatureFlag(FeatureFlags.DailyPerformanceFinancialFilters)
  ? [
      {
        id: 'financial',
        name: 'Financial',
        filters: ['rangeByOutstandingBillings', 'rangeByDrawBalance']
      }
    ]
  : [];

export const FiltersGroups = [
  {
    id: 'recruiters',
    name: 'Recruiters',
    filters: ['employeeNumbers', 'teams', 'healthStatuses', 'startDate']
  },
  {
    id: 'sendouts',
    name: 'Sendouts',
    filters: [
      'rangeByCurrentWeekSendouts',
      'rangeByLastWeekSendouts',
      'rangeByThirdWeekSendouts',
      'rangeByFourthWeekSendouts',
      'rangeByFifthWeekSendouts',
      'rangeBySendoutPlacementRatio'
    ]
  },
  ...FinancialFilterGroup
];

export const COLUMNS = [
  {
    name: 'idFortpac',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'recruiterName',
    label: 'Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterActivityRecruiter,
          idKey: 'empNo',
          textKey: 'name',
          enableInTabRedirect: true
        }
      },
      setCellHeaderProps: () => ({ className: 'dataTableStickyLeftColumnHeader' }),
      setCellProps: () => ({
        className: 'dataTableStickyLeftColumn'
      })
    }
  },
  {
    name: 'managerName',
    label: 'Team',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CommandCenterActivityTeam,
          idKey: 'managerEmpNo',
          textKey: 'team',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'healthStatus',
    label: 'Current Health Status',
    options: {
      sort: true,
      display: true,
      renderer: 'healthStatusChip'
    }
  },
  {
    name: 'hireDate',
    label: 'Start Date',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'lastPlacementDate',
    label: 'Last Placement',
    options: {
      sort: true,
      display: true,
      renderer: { id: 'dateBuilder', config: { format: DateFormats.SimpleDate } }
    }
  },
  {
    name: 'thisWeekSendouts',
    label: 'Current Week Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'lastWeekSendouts',
    label: 'Last Week Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'thirdWeekSendouts',
    label: 'Prev. Week 2 Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'fourthWeekSendouts',
    label: 'Prev. Week 3 Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'fifthWeekSendouts',
    label: 'Prev. Week 4 Sendouts',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'soPlRatio',
    label: 'SO to PL',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'outstandingBillings',
    label: 'Current O. Billings',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'drawBalance',
    label: 'Current Draw Balance',
    options: {
      sort: true,
      display: true,
      renderer: 'currency_fixed'
    }
  },
  {
    name: 'avgInMailsByBillingPeriod',
    label: 'InMails',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'bulks',
    label: 'Bulks',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'peopleReached',
    label: 'People Reached',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'todayLivePosts',
    label: 'Posts',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'callsTime',
    label: 'Phone Time',
    options: {
      sort: true,
      display: true,
      renderer: 'phoneTime'
    }
  },
  {
    name: 'calls',
    label: 'Calls',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'notConnectedCalls',
    label: 'Not Conn. Calls',
    options: {
      sort: true,
      display: true,
      renderer: 'formattedNumber'
    }
  },
  {
    name: 'totalCalls',
    label: 'Total Calls',
    options: {
      sort: false,
      display: true,
      renderer: 'formattedNumber'
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'recruiterName',
  direction: 'asc'
};

export const INLINE_FILTERS = ['team', 'healthStatus'];

export const METRICS_DESCRIPTION = [
  COPIES.footer.metrics.inMails,
  COPIES.footer.metrics.SOtoPL,
  COPIES.footer.metrics.calls,
  COPIES.footer.metrics.posts
];

export const extraFilters = [
  {
    name: 'rangeByCurrentWeekSendouts',
    paramName: 'currentWeekSendouts',
    title: COPIES.filters.sendouts.currentWeek,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.currentWeek,
    placeholder: 'Place'
  },
  {
    name: 'rangeByLastWeekSendouts',
    paramName: 'lastWeekSendouts',
    title: COPIES.filters.sendouts.lastWeek,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.lastWeek
  },
  {
    name: 'rangeByThirdWeekSendouts',
    paramName: 'thirdWeekSendouts',
    title: COPIES.filters.sendouts.previousWeekTwo,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.previousWeekTwo
  },
  {
    name: 'rangeByFourthWeekSendouts',
    paramName: 'fourthWeekSendouts',
    title: COPIES.filters.sendouts.previousWeekThree,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.previousWeekThree
  },
  {
    name: 'rangeByFifthWeekSendouts',
    paramName: 'fifthWeekSendouts',
    title: COPIES.filters.sendouts.previousWeekFour,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.previousWeekFour
  },
  {
    name: 'rangeBySendoutPlacementRatio',
    paramName: 'sendoutToPlacementRatio',
    title: COPIES.filters.sendouts.placementRatio,
    type: FilterType.Range,
    displayLabel: COPIES.filters.sendouts.placementRatio
  },
  {
    name: 'rangeByOutstandingBillings',
    paramName: 'outstandingBillings',
    title: COPIES.filters.financial.outstandingBillings,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.outstandingBillings,
    inputType: 'currency',
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceFinancialFilters)
  },
  {
    name: 'rangeByDrawBalance',
    paramName: 'drawBalance',
    title: COPIES.filters.financial.drawBalance,
    type: FilterType.Range,
    displayLabel: COPIES.filters.financial.drawBalance,
    inputType: 'currency',
    showWhen: () => hasFeatureFlag(FeatureFlags.DailyPerformanceFinancialFilters)
  }
];
