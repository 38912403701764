// @flow
import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import { useGlobalStyles } from 'GlobalStyles';
import strings from 'strings';
import type { SelectionMenuPropTypes } from 'types/app';
import { When } from 'UI/components/atoms/When';
import { MULTIPLE_SELECTION_TYPES } from 'UI/constants/defaults';
import { formatNumber } from 'UI/utils';

const { multipleSelectionMenuItems: selectionMenuItemsCopies } = strings.shared.dataTable;

export const getFormattedMenuItemTitle = ({ copy, number }) =>
  strings.formatString(copy, { number: formatNumber(number) });

const SelectionMenu = ({
  disableSelectAllCheckbox = false,
  numberOfItems,
  onSelectionChange = null,
  someSelected = false
}: SelectionMenuPropTypes) => {
  const globalClasses = useGlobalStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = e => setAnchorEl(e.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleSelectMenuItem = selectionType => () => {
    handleCloseMenu();
    onSelectionChange && onSelectionChange(selectionType);
  };

  return (
    <>
      <IconButton aria-label="open-select-menu" onClick={handleOpenMenu}>
        {someSelected ? (
          <CheckBox data-testid="multiple-selection-checked" />
        ) : (
          <CheckBoxOutlineBlankOutlined data-testid="multiple-selection-unchecked" />
        )}
      </IconButton>
      <Menu
        id="check-menu"
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleSelectMenuItem(MULTIPLE_SELECTION_TYPES.page)}>
          {getFormattedMenuItemTitle({
            copy: selectionMenuItemsCopies.itemsOnPage,
            number: numberOfItems.page
          })}
        </MenuItem>
        <When condition={!disableSelectAllCheckbox}>
          <MenuItem onClick={handleSelectMenuItem(MULTIPLE_SELECTION_TYPES.all)}>
            {getFormattedMenuItemTitle({
              copy: selectionMenuItemsCopies.allItems,
              number: numberOfItems.all
            })}
          </MenuItem>
        </When>
        <Divider className={globalClasses.tightDivider} />
        <MenuItem onClick={handleSelectMenuItem(MULTIPLE_SELECTION_TYPES.unselectAll)}>
          {selectionMenuItemsCopies.clearSelection}
        </MenuItem>
      </Menu>
    </>
  );
};

type CustomDataTableCheckboxPropTypes = {
  SelectionMenuProps: SelectionMenuPropTypes
};

const CustomDataTableCheckbox = ({
  SelectionMenuProps,
  ...rest
}: CustomDataTableCheckboxPropTypes) =>
  /**
   * This validation is not only for the header checkbox
   * it is also for the row checkboxes. Menu checkbox will
   * be returned for the header if data-description is equal
   * to row-select-header. Normal checkbox will be returned
   * in any other case, so it will be rendered for each row
   */
  rest['data-description'] === 'row-select-header' ? (
    <SelectionMenu {...SelectionMenuProps} />
  ) : (
    <Checkbox {...rest} />
  );

export default CustomDataTableCheckbox;
