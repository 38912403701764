import { useEffect, useState } from 'react';
import API from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';
import { RequestStatus } from 'UI/constants/status';

const catalogEndpoints = {
  JobOrderStatuses: Endpoints.JobOrderStatuses,
  JobOrderTypes: Endpoints.JobOrderTypes,
  WorkTypes: Endpoints.WorkTypes,
  FeeAgreements: `${Endpoints.FeeAgreementsByCompany}?companyId=:companyId`
};

const useFetchCatalogs = ({ companyId }) => {
  const [uiState, setUiState] = useState(RequestStatus.Idle);
  const [catalogs, setCatalogs] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setUiState(RequestStatus.Loading);

      const formatResponse = ({ data, status }) => ({ data, status });
      try {
        const [jobOrderStatuses, jobOrderTypes, workTypes, feeAgreements] = await Promise.all([
          API.get(catalogEndpoints.JobOrderStatuses).then(formatResponse),
          API.get(catalogEndpoints.JobOrderTypes).then(formatResponse),
          API.get(catalogEndpoints.WorkTypes).then(formatResponse),
          API.get(catalogEndpoints.FeeAgreements.replace(':companyId', companyId)).then(
            formatResponse
          )
        ]);

        setCatalogs({
          jobOrderStatuses,
          jobOrderTypes,
          workTypes,
          feeAgreements
        });

        setUiState(RequestStatus.Success);
      } catch (error) {
        setUiState(RequestStatus.Error);
      }
    };

    if (!companyId) return;

    fetchData();
  }, [companyId]);

  return {
    catalogs,
    uiState
  };
};

export default useFetchCatalogs;
