// @flow
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { InlineFilters } from 'features/command-center/';
import { Toolbar } from 'features/command-center/components/shared';
import { StatsCard } from 'features/command-center/components/shared/StatsCard';
import useDatatable, {
  addMultipleColumns,
  buildRowActionsRendererDefinition,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { rowClickBuilder } from 'hooks/profileActions';
import { useQuickViewPreview } from 'hooks/useQuickViewPreview';
import { filtersToParams } from 'selectors/app';
import ConditionalActionWrapper from 'UI/components/atoms/ConditionalWrapper';
import { When } from 'UI/components/atoms/When';
import DataTable from 'UI/components/organisms/DataTable';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import { RowActions } from 'UI/constants/defaults';
import { useFixedDatatableStyles, useTableCard } from 'UI/globalStyles/DataTableStyles';

interface SimplifiedTableProps {
  apiVersion?: 1 | 2;
  columns: any;
  createExtraColumns: (columns: ArrayArray<object>) => Array<object>;
  createExtraColumnsConfig?: object;
  draggableColumnsEnabled?: Boolean;
  enableMultiplePhonesColumns?: boolean;
  endpoint: string;
  entityType?: string;
  extendParams?: object;
  formatResponse: (response: object) => any;
  header: string;
  headerAction?: React.ReactNode;
  inlineFilters?: Array<string>;
  onFetchedData?: () => {};
  orderByOptions: Object;
  pageKey?: string;
  rowActions?: Array<string>;
  shouldRefresh?: false;
  showWrapper?: boolean;
  subheader?: string;
  virtualProps?: any;
}

export const SimplifiedTable = ({
  apiInstance = null,
  apiVersion = 1,
  columns,
  createExtraColumns,
  createExtraColumnsConfig,
  draggableColumnsEnabled = false,
  endpoint,
  entityType,
  extendParams,
  extraRenderers,
  filters,
  footer,
  formatResponse,
  header,
  headerAction,
  inlineFilters,
  onFetchedData,
  orderByOptions,
  pageKey,
  quickViewEntityRoleProps,
  rowActions = [],
  shouldRefresh = true,
  shouldUseSavedPagination = true,
  showWrapper = true,
  subheader,
  virtualProps
}: SimplifiedTableProps) => {
  const fixedTableClasses = useFixedDatatableStyles();
  const tableClasses = useTableCard();
  const dispatch = useDispatch();

  const multiplePhonesColumnsConfig = createExtraColumns
    ? createExtraColumns(columns, dispatch, createExtraColumnsConfig)
    : [];

  const FinalColumnsDefinition = addMultipleColumns(columns, multiplePhonesColumnsConfig).filter(
    column => !!column
  );

  const initialPreferences = getColumnPreferences(
    pageKey,
    0,
    orderByOptions,
    FinalColumnsDefinition,
    0
  );

  const paramsExtender = useCallback(
    () => ({
      ...(filters && filtersToParams(filters)),
      ...(extendParams && extendParams())
    }),
    [filters, extendParams]
  );

  const {
    columnPreferences,
    count,
    data,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePerPageChange,
    listState
  } = useDatatable({
    apiInstance,
    enableFetchData: shouldRefresh,
    apiVersion,
    columnsDefinitions: FinalColumnsDefinition,
    endpoint,
    formatResponse,
    initialPreferences,
    key: pageKey,
    onFetchedData,
    orderByOptions,
    paramsExtender,
    shouldUseSavedFilters: false,
    shouldUseSavedPagination,
    virtualProps
  });

  const { columns: preferencesColumns, columnOrder, orderBy, direction } = columnPreferences;
  const { isLoading, page, perPage, keyword, showWholeSkeleton } = listState;

  const { entityId, quickViewState, listViewPreset, quickViewEntity, toggleQuickViewPreview } =
    useQuickViewPreview({ columns, data, entityType, quickViewEntityRoleProps });

  const actions = rowActions.map(action => {
    if (action?.customAction) {
      return {
        name: action?.name,
        onClick: rowClickBuilder({
          columns,
          data,
          pickId: true,
          callback: action?.onClick
        })
      };
    }
    if (action === RowActions.View) {
      return listViewPreset;
    }
    return action;
  });

  const tableActions = buildRowActionsRendererDefinition({
    actions,
    namespace: entityType || 'id',
    customName: 'contactData'
  });

  const finalColumns = getColumnsToRender([...preferencesColumns, tableActions], extraRenderers);

  const handleTabRefresh = async () => {
    await getData();
  };

  return (
    <>
      {inlineFilters && (
        <InlineFilters
          persistenceKey={pageKey}
          filters={inlineFilters}
          onFiltersChange={handleFiltersChange}
        />
      )}

      <ConditionalActionWrapper
        condition={showWrapper}
        wrapper={children => (
          <StatsCard
            header={header}
            headerAction={headerAction}
            subheader={subheader}
            footer={footer}
            paddingContent={0}
          >
            {children}
          </StatsCard>
        )}
      >
        <DataTable
          classes={{ responsiveBase: 'responsiveBase' }}
          className={clsx(tableClasses.tableRoot, fixedTableClasses.fixedList)}
          columnOrder={columnOrder?.length ? columnOrder : undefined}
          columns={finalColumns}
          components={{ TableToolbar: Toolbar }}
          count={count || 0}
          data={data}
          draggableColumns={{
            enabled: draggableColumnsEnabled
          }}
          elevation={0}
          filter={false}
          loading={showWholeSkeleton}
          onColumnDisplayClick={handleColumnDisplayChange}
          onColumnOrderChange={handleColumnOrderChange}
          onColumnSortChange={handleColumnSortChange}
          onPageClick={handlePageChange}
          onPerPageClick={handlePerPageChange}
          onSearchTextChange={handleKeywordChange}
          onToggleFilters={handleFiltersToggle}
          page={page}
          refreshing={isLoading}
          role="table"
          rowsPerPage={perPage}
          searchText={keyword}
          selectableRows="none"
          selectToolbarPlacement="none"
          sortOrder={{ name: orderBy, direction }}
        />
      </ConditionalActionWrapper>

      <When condition={entityId && quickViewState.isQuickViewOpen}>
        <FPQuickView
          id={entityId}
          entityType={quickViewEntity}
          drawerProps={{
            open: quickViewState.isQuickViewOpen
          }}
          itemsToNavigate={data}
          onClose={toggleQuickViewPreview}
          onEditionCompleted={handleTabRefresh}
          onVerificationCompleted={handleTabRefresh}
        />
      </When>
    </>
  );
};
