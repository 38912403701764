import React, { useCallback, useMemo } from 'react';
import { DRAWER_KEYS, StatsItemsCard } from 'features/command-center/components/shared';
import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { useDrawerManager } from 'features/command-center/contexts';
import { getCallsSummary, getWeekActivity } from 'features/command-center/services';
import moment from 'moment';
import strings from 'strings';
import { DateFormats } from 'UI/constants/defaults';
import { getCurrentWeekRange } from 'UI/utils';

import { formatStats } from './WeeklyActivityStatsSummary.utils';

const DEFAULT_DATE_RANGE = getCurrentWeekRange();

export const WeeklyActivityStatsSummary = ({ requestParams, hasDrillDown }) => {
  const { openDrawer } = useDrawerManager();
  const queryDateRange = useMemo(
    () => ({
      startDate: moment(DEFAULT_DATE_RANGE.startDate).format(DateFormats.QueryFormat),
      endDate: moment(DEFAULT_DATE_RANGE.endDate).format(DateFormats.QueryFormat)
    }),
    []
  );

  const cachedPromises = useCallback(async () => {
    const drawerHandlers = hasDrillDown
      ? {
          calls: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.Calls,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
          },
          notConnectedCalls: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.Calls,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
          },
          phoneTime: ({ url }) => {
            openDrawer({
              activeDrawer: DRAWER_KEYS.PhoneTime,
              contentProps: { endpoint: `${COMMAND_CENTER_BASE_URL}${url}` }
            });
          }
        }
      : null;

    const { recruiter } = requestParams;
    const groupBy = recruiter ? 'recruiter' : 'team';
    const [activityResponse, notConnectedCallsResponse] = await Promise.all([
      getWeekActivity({ ...requestParams, groupBy }),
      getCallsSummary({
        ...requestParams,
        ...queryDateRange,
        callGroup: 'noConnected',
        summaryType: 'total',
        countType: 'byCall'
      })
    ]);

    const data = {
      ...(activityResponse?.data ? activityResponse.data : {}),
      notConnectedCalls: notConnectedCallsResponse?.data?.total ?? 0,
      notConnectedCallsUrl: notConnectedCallsResponse?.data?.url
    };

    return formatStats({ data, drawerHandlers });
  }, [hasDrillDown, openDrawer, queryDateRange, requestParams]);

  return (
    <StatsItemsCard
      header={strings.commandCenter.activity.weeklyActivityStatsSummary.title}
      promise={cachedPromises}
      textAlign="center"
    />
  );
};
