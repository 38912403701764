import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          idKey: 'typeId',
          labelKey: 'type',
          baseClass: 'chip-company-type'
        }
      }
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'id',
          textKey: 'name',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'specialty',
    label: 'Specialty',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      sort: false,
      display: true
    }
  },
  {
    name: 'createdAt',
    label: 'Added Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'lastActivityDate',
    label: 'Last Activity',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'coachName',
    label: 'Team',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'recruiterName',
    label: 'Recruiter',
    options: {
      sort: true,
      display: true
    }
  }
];

export const VIRTUAL_PROPS = [
  {
    propName: 'name',
    attributes: ['id', 'name']
  },
  {
    propName: 'type',
    attributes: ['typeId', 'type']
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
