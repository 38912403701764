// @flow
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { showAlert as showAlertAction } from 'actions/app';
import { useGlobalStyles } from 'GlobalStyles';
import isNil from 'lodash/isNil';
import moment from 'moment';
import { UserProviderContext } from 'providers/UserProvider';
import API from 'services/API';
import {
  canUserOrStaffEditEntity,
  Permissions,
  userHasPermission,
  userHasRole
} from 'services/Authorization';
import { getAgreementsByCompanyId } from 'services/FeeAgreement';
import strings from 'strings';
import CustomDatePicker from 'UI/components/atoms/CustomDatePicker';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import Text from 'UI/components/atoms/Text';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect, {
  statusRenderOption,
  statusStartAdornment
} from 'UI/components/molecules/AutocompleteSelect';
import FPRadioGroup from 'UI/components/molecules/FPRadioGroup';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { BinaryOptions, No, Yes } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { PaymentMode } from 'UI/constants/entityTypes';
import { Roles } from 'UI/constants/roles';
import { FeeAgreementStatus } from 'UI/constants/status';
import { SvgContentPaperEdit } from 'UI/res';
import {
  getErrorMessage,
  idOptionSelected,
  nestTernary,
  REGULAR_PERCENT_VALIDATION,
  REQUIRED_VALIDATION
} from 'UI/utils';
import { buildBasePropsForField, preloadFromBackend, prepareFormToSubmit } from 'UI/utils/forms';
import { LabelRenderers } from 'UI/utils/renderers';

import { FormFieldsMap, InterviewDatePrefix } from './fields';

type JobOrderSheetProps = {
  whitesheet: any,
  profile: any,
  joborderId?: number | string,
  companyId: number,
  isReadOnly: boolean,
  onWhitesheetCompleted: (whitesheet: any) => void,
  onWhitesheetClosed: () => void,
  onBluesheetChanged: (whitesheet: any) => void,
  showAlert: any => void
};
const NUMBER_OF_DATES = 3;
const datesIndexer = Array.from({ length: NUMBER_OF_DATES }).map((_, index) => index);
const FEE_AGREEMENT_PERCENT = 33.33;
const WARRANTY = 30;

const canChangeFeeAgreementField = (
  canUserEdit: boolean,
  whitesheet: any,
  fieldPermission: string
) => {
  const isUserCoach = userHasRole(Roles.Coach);
  const isExistingWhitesheet = !!whitesheet?.id;
  const hasRightPrivileges = isUserCoach || userHasPermission(fieldPermission);

  return hasRightPrivileges && (!isExistingWhitesheet || (isExistingWhitesheet && canUserEdit));
};

const FieldConfiguration = [
  { name: FormFieldsMap.DiscussingAgreementComplete.key, validation: REQUIRED_VALIDATION },
  { name: FormFieldsMap.TimePositionOpen.key, validation: REQUIRED_VALIDATION },
  { name: FormFieldsMap.PositionFilled.key, validation: REQUIRED_VALIDATION },
  { name: FormFieldsMap.MaximumSalary.key, validation: REQUIRED_VALIDATION },
  { name: FormFieldsMap.MinimumSalary.key, validation: REQUIRED_VALIDATION },
  { name: FormFieldsMap.IdealSalary.key, validation: REQUIRED_VALIDATION },
  { name: FormFieldsMap.Type.key, validation: REQUIRED_VALIDATION },
  { name: FormFieldsMap.WorkType.key, validation: REQUIRED_VALIDATION },
  { name: FormFieldsMap.CompanyFeeAgreement.key, validation: null },
  { name: FormFieldsMap.FeeAgreementPaymentSchemeId.key, validation: null }
];

const getInitialValues = (whitesheet, context) => {
  const defaultValues = {
    [FormFieldsMap.DiscussingAgreementComplete.key]: whitesheet
      ? nestTernary(
          Number(whitesheet[FormFieldsMap.DiscussingAgreementComplete.key]) === 1,
          Yes.id,
          No.id
        )
      : '',
    [FormFieldsMap.PreparedToSignServiceAgreement.key]: !isNil(
      whitesheet?.[FormFieldsMap.PreparedToSignServiceAgreement]
    )
      ? `${Number(whitesheet[FormFieldsMap.PreparedToSignServiceAgreement])}`
      : '',
    [FormFieldsMap.PreparedToInterview.key]: !isNil(whitesheet?.[FormFieldsMap.PreparedToInterview])
      ? `${Number(whitesheet[FormFieldsMap.PreparedToInterview])}`
      : '',
    [FormFieldsMap.CompanyFeeAgreement.key]: null,
    [FormFieldsMap.FeeAgreeementPercent.key]: FEE_AGREEMENT_PERCENT,
    [FormFieldsMap.Guarantee.key]: WARRANTY,
    [FormFieldsMap.TimePositionOpen.key]: null,
    [FormFieldsMap.PositionFilled.key]: null,
    [FormFieldsMap.MaximumSalary.key]: '',
    [FormFieldsMap.MinimumSalary.key]: '',
    [FormFieldsMap.IdealSalary.key]: '',
    [FormFieldsMap.Benefits.key]: '',
    [FormFieldsMap.Background.key]: '',
    [InterviewDatePrefix]: [null, null, null],
    [FormFieldsMap.WorkType.key]: null,
    [FormFieldsMap.Type.key]: null,
    [FormFieldsMap.Status.key]: null,
    [FormFieldsMap.Notes.key]: ''
  };
  if (!whitesheet) return defaultValues;

  const isDraft = whitesheet && !whitesheet.id;
  return isDraft
    ? {
        ...defaultValues,
        ...whitesheet
      }
    : preloadFromBackend(whitesheet, FormFieldsMap, context);
};

const JobOrderSheet = ({
  whitesheet,
  profile,
  onWhitesheetCompleted,
  onWhitesheetClosed,
  onBluesheetChanged,
  joborderId = '',
  companyId,
  isReadOnly,
  showAlert,
  hasWhitesheet
}: JobOrderSheetProps) => {
  const globalClasses = useGlobalStyles();
  const [currentUser] = useContext(UserProviderContext);

  const isExistingWhitesheet = whitesheet?.id || hasWhitesheet;

  const [feeAgreements, setFeeAgreements] = useState([]);

  const defaultAgreementComplete = whitesheet
    ? nestTernary(
        Number(whitesheet[FormFieldsMap.DiscussingAgreementComplete.key]) === 1,
        Yes.id,
        No.id
      )
    : '';

  const defaultPreparedToSign = !isNil(whitesheet?.[FormFieldsMap.PreparedToSignServiceAgreement])
    ? `${Number(whitesheet[FormFieldsMap.PreparedToSignServiceAgreement])}`
    : '';

  const defaultPreparedToInterview = !isNil(whitesheet?.[FormFieldsMap.PreparedToInterview])
    ? `${Number(whitesheet[FormFieldsMap.PreparedToInterview])}`
    : '';
  const { register, errors, watch, handleSubmit, setValue, getValues, formState } = useForm({
    defaultValues: getInitialValues(whitesheet, profile)
  });
  const { touched, dirtyFields } = formState;
  const touchedLength = Object.keys(touched).length;
  const formValues = watch([
    FormFieldsMap.CompanyFeeAgreement.key,
    FormFieldsMap.FeeAgreeementPercent.key,
    FormFieldsMap.Guarantee.key,
    FormFieldsMap.TimePositionOpen.key,
    FormFieldsMap.PositionFilled.key,
    FormFieldsMap.MaximumSalary.key,
    FormFieldsMap.MinimumSalary.key,
    FormFieldsMap.IdealSalary.key,
    FormFieldsMap.Benefits.key,
    FormFieldsMap.Background.key,
    InterviewDatePrefix,
    FormFieldsMap.WorkType.key,
    FormFieldsMap.Type.key,
    FormFieldsMap.Status.key,
    FormFieldsMap.Notes.key
  ]);
  const agreementComplete = watch(
    FormFieldsMap.DiscussingAgreementComplete.key,
    defaultAgreementComplete
  );
  const preparedToSign = watch(
    FormFieldsMap.PreparedToSignServiceAgreement.key,
    defaultPreparedToSign
  );
  const preparedToInterview = watch(
    FormFieldsMap.PreparedToInterview.key,
    defaultPreparedToInterview
  );

  const canUserEdit = canUserOrStaffEditEntity(currentUser, profile) || !profile;
  const canModifyFeeGuarantee = canChangeFeeAgreementField(
    canUserEdit,
    whitesheet,
    Permissions.FeeAgreements.ModifyGuarantee
  );
  const canModifyFeePercentage = canChangeFeeAgreementField(
    canUserEdit,
    whitesheet,
    Permissions.FeeAgreements.ModifyPercentage
  );

  const [uiState, setUiState] = useState({
    isSaving: false,
    isSuccess: false,
    isReadOnly,
    isFormDisabled: isReadOnly || (!canUserEdit && whitesheet)
  });

  useEffect(() => {
    FieldConfiguration.forEach(({ name, validation }) => {
      register({ name }, { ...validation });
    });
    register({ name: FormFieldsMap.Status.key }, REQUIRED_VALIDATION);
    register(
      { name: FormFieldsMap.FeeAgreeementPercent.key },
      {
        validate(percent) {
          const payMode = getValues(FormFieldsMap.FeeAgreementPaymentSchemeId.key);
          const percentage = Number.parseFloat(percent);
          return (
            (payMode !== PaymentMode.Flat &&
              !!percentage &&
              percentage >= REGULAR_PERCENT_VALIDATION.min.value) ||
            payMode === PaymentMode.Flat ||
            REGULAR_PERCENT_VALIDATION.min.message
          );
        }
      }
    );
    register(
      { name: FormFieldsMap.Guarantee.key },
      {
        validate(guarantee) {
          const payMode = getValues(FormFieldsMap.FeeAgreementPaymentSchemeId.key);
          const guaranteeDays = Number.parseFloat(guarantee);
          return (
            payMode === PaymentMode.Conversion ||
            (payMode !== PaymentMode.Conversion && !Number.isNaN(guaranteeDays)) ||
            REQUIRED_VALIDATION.required
          );
        }
      }
    );
    register({ name: InterviewDatePrefix });
    register(
      { name: FormFieldsMap.PreparedToSignServiceAgreement.key },
      {
        validate(value) {
          const isAgreementSigned =
            getValues(FormFieldsMap.DiscussingAgreementComplete.key) === Yes.id;
          return isAgreementSigned || !!value || 'Please specify if they are ready to sign';
        }
      }
    );
    register(
      { name: FormFieldsMap.PreparedToInterview.key },
      {
        validate(value) {
          return !!value || 'Please specify if they are ready to hire ASAP';
        }
      }
    );
  }, [register, getValues]);

  useEffect(() => {
    onBluesheetChanged && onBluesheetChanged(getValues());
  }, [touchedLength, onBluesheetChanged, getValues]);

  useEffect(() => {
    onBluesheetChanged && onBluesheetChanged(getValues());
  }, [dirtyFields.size, onBluesheetChanged, getValues]);

  useEffect(() => {
    agreementComplete === Yes.id &&
      setValue(FormFieldsMap.PreparedToSignServiceAgreement.key, Yes.id, false);
  }, [agreementComplete, preparedToSign, setValue]);

  const handleRadioChange = e => {
    const { value, name } = e.target;
    setValue(name, value, true);
  };

  const handleDateChange = (name, date) => {
    setValue(name, date, true);
  };

  const handleInterviewDateChange = (name: string, value) => {
    const index = Number(name.replace(InterviewDatePrefix, ''));
    const interviewDates = formValues[InterviewDatePrefix];
    interviewDates[index] = value;
    setValue(InterviewDatePrefix, interviewDates);
  };

  const handleEditClick = () => {
    setUiState(prevState => ({
      ...prevState,
      isReadOnly: false,
      isFormDisabled: !canUserEdit && whitesheet
    }));
  };

  async function onSubmit(formData) {
    if (whitesheet?.id) {
      const transformedData = prepareFormToSubmit(formData, FormFieldsMap);
      await updateExistingWhitesheet(transformedData);
    } else {
      onWhitesheetCompleted && onWhitesheetCompleted(formData);
    }
  }

  const updateExistingWhitesheet = async sheet => {
    try {
      setUiState(prevState => ({ ...prevState, isSaving: true }));
      const response = await API.put(
        `${Endpoints.JobOrders}/${joborderId}/whiteSheets/${whitesheet.id}`,
        sheet
      );
      setUiState(prevState => ({ ...prevState, isSaving: false, isSuccess: true }));

      if (response.data) {
        showAlert({
          severity: 'success',
          title: 'Awesome',
          body: 'The write up was updated successfully'
        });

        onWhitesheetCompleted && onWhitesheetCompleted(response.data);
      }
    } catch (err) {
      setUiState(prevState => ({ ...prevState, isSaving: false, isSuccess: false }));
      showAlert({
        severity: 'error',
        title: 'Job Order',
        body: getErrorMessage(err)
      });
    }
  };

  const handleValueChange = useCallback(
    (name: string, value: any) => setValue(name, value, true),
    [setValue]
  );

  const setDefaultFeeValues = useCallback(() => {
    setValue(FormFieldsMap.FeeAgreeementPercent.key, FEE_AGREEMENT_PERCENT);
    setValue(FormFieldsMap.Guarantee.key, WARRANTY);
  }, [setValue]);

  const handleFeeChange = useCallback(
    (name?: string, value: any) => {
      setValue(
        FormFieldsMap.FeeAgreementPaymentSchemeId.key,
        value?.fee_agreement_payment_scheme_id || null
      );
      handleValueChange(name, value);
      if (value) {
        setValue(FormFieldsMap.FeeAgreeementPercent.key, value.fee_percentage || null, true);
        setValue(
          FormFieldsMap.Guarantee.key,
          !isNil(value.guarantee_days) ? value.guarantee_days : 0,
          true
        );
      } else {
        setDefaultFeeValues();
      }
    },
    [setValue, setDefaultFeeValues, handleValueChange]
  );

  useEffect(() => {
    const loadFeeAgreements = async () => {
      const result = await getAgreementsByCompanyId(companyId);
      const agreements = result.data;
      agreements && setFeeAgreements(agreements);
      if (agreements && agreements.length > 0) {
        const signedAgreements = agreements.some(
          ({ fee_agreement_status_id: statusId }) => statusId === FeeAgreementStatus.Signed
        );
        !isExistingWhitesheet &&
          handleFeeChange(FormFieldsMap.CompanyFeeAgreement.key, agreements[0]);
        setValue(
          FormFieldsMap.DiscussingAgreementComplete.key,
          signedAgreements ? Yes.id : No.id,
          true
        );
      } else {
        if (isExistingWhitesheet) return;
        setDefaultFeeValues();
      }
    };
    companyId && loadFeeAgreements();
  }, [isExistingWhitesheet, companyId, setValue, handleFeeChange, setDefaultFeeValues]);

  const selectedFeeAgreement = formValues[FormFieldsMap.CompanyFeeAgreement.key];
  const timeLooking = formValues[FormFieldsMap.TimePositionOpen.key];
  const lookingFor = timeLooking ? moment(timeLooking).fromNow(true) : '---';
  const selectedFeePaymentMode = getValues(FormFieldsMap.FeeAgreementPaymentSchemeId.key);
  const noMargin = false;

  return (
    <DrawerContentLayout
      onSubmit={handleSubmit(onSubmit)}
      onClose={onWhitesheetClosed}
      uiState={uiState}
      title={strings.inventory.jobOrders.create.assignmentSheet.new.title}
      additionalHeaderButtons={
        whitesheet &&
        whitesheet.id &&
        uiState.isReadOnly &&
        canUserEdit && (
          <FPIconButton
            icon={SvgContentPaperEdit}
            onClick={handleEditClick}
            tooltipProps={{ title: 'Edit' }}
          />
        )
      }
    >
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="1.- Is there a signed Fee Agreement? *" />
        <FPRadioGroup
          {...buildBasePropsForField(
            FormFieldsMap.DiscussingAgreementComplete.key,
            errors,
            noMargin
          )}
          value={agreementComplete}
          options={BinaryOptions}
          onChange={handleRadioChange}
          disabled={uiState.isFormDisabled || feeAgreements.length > 0}
          errorMessage={errors?.[FormFieldsMap.DiscussingAgreementComplete.key]?.message}
        />
        <div className={globalClasses.sheetInputContainer}>
          <FPRadioGroup
            {...buildBasePropsForField(
              FormFieldsMap.PreparedToSignServiceAgreement.key,
              errors,
              noMargin
            )}
            label="Are they prepared to sign our service agreement?"
            value={preparedToSign}
            options={BinaryOptions}
            onChange={handleRadioChange}
            disabled={uiState.isFormDisabled}
            errorMessage={errors?.[FormFieldsMap.PreparedToSignServiceAgreement.key]?.message}
          />
        </div>
        <div className={globalClasses.sheetInputContainer}>
          <FPRadioGroup
            {...buildBasePropsForField(FormFieldsMap.PreparedToInterview.key, errors, noMargin)}
            label="Are they prepared to interview and hire today (ASAP)?"
            value={preparedToInterview}
            options={BinaryOptions}
            onChange={handleRadioChange}
            disabled={uiState.isFormDisabled}
            errorMessage={errors?.[FormFieldsMap.PreparedToInterview.key]?.message}
          />
        </div>
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="2.- Fee Agreement: *" />
        {feeAgreements.length > 0 && (
          <div className={globalClasses.sheetInputContainer}>
            <AutocompleteSelect
              width="100%"
              {...buildBasePropsForField(FormFieldsMap.CompanyFeeAgreement.key, errors, noMargin)}
              selectedValue={formValues[FormFieldsMap.CompanyFeeAgreement.key]}
              placeholder="Fee Agreement"
              getOptionLabel={LabelRenderers.feeAgreement}
              getOptionSelected={idOptionSelected}
              defaultOptions={feeAgreements}
              onSelect={handleFeeChange}
              disabled={uiState.isFormDisabled}
            />
          </div>
        )}
        {selectedFeePaymentMode !== PaymentMode.Flat && (
          <div className={globalClasses.sheetInputContainer}>
            <TextBox
              {...buildBasePropsForField(FormFieldsMap.FeeAgreeementPercent.key, errors, noMargin)}
              label="Fee % *"
              value={formValues[FormFieldsMap.FeeAgreeementPercent.key]}
              width="100%"
              inputType="percentage"
              disabled={!canModifyFeePercentage || uiState.isFormDisabled || selectedFeeAgreement}
              onChange={handleValueChange}
            />
          </div>
        )}
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="3.- Guarantee days: *" />
        <div className={globalClasses.sheetInputContainer}>
          {selectedFeePaymentMode !== PaymentMode.Conversion ? (
            <TextBox
              {...buildBasePropsForField(FormFieldsMap.Guarantee.key, errors, noMargin)}
              label="Guarantee days *"
              value={formValues[FormFieldsMap.Guarantee.key]}
              disabled={!canModifyFeeGuarantee || uiState.isFormDisabled || selectedFeeAgreement}
              width="100%"
              inputType="number"
              onChange={handleValueChange}
            />
          ) : (
            <FormHelperText>This Fee Agreement does not allow guarantee </FormHelperText>
          )}
        </div>
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="4.- How long has this position been open? *" />
        <Grid
          container
          spacing={2}
          alignItems="center"
          className={globalClasses.sheetInputContainer}
        >
          <Grid item xs={8} className={globalClasses.tightGrid}>
            <CustomDatePicker
              name={FormFieldsMap.TimePositionOpen.key}
              label="Open Since *"
              placeholder="Open Since *"
              disableFuture
              value={timeLooking}
              onDateChange={handleDateChange}
              error={!!errors.time_position_open}
              helperText={errors.time_position_open && errors.time_position_open.message}
              disabled={uiState.isFormDisabled}
            />
          </Grid>
          <Grid item xs={4} className={globalClasses.tightGrid}>
            Open for <br />
            <b>{lookingFor}</b>
          </Grid>
        </Grid>
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="5.- When do you need the position to be filled? *" />
        <div className={globalClasses.sheetInputContainer}>
          <CustomDatePicker
            name={FormFieldsMap.PositionFilled.key}
            label="Start Date *"
            placeholder="Start Date *"
            disablePast={!isExistingWhitesheet}
            value={formValues[FormFieldsMap.PositionFilled.key]}
            onDateChange={handleDateChange}
            helperText={errors.position_filled?.message}
            error={!!errors.position_filled?.message}
            disabled={uiState.isFormDisabled}
            width="100%"
          />
        </div>
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="6.- Compensation salary range: *" />
        <div className={globalClasses.sheetInputContainer}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.MaximumSalary.key, errors, noMargin)}
            label="High salary *"
            value={formValues[FormFieldsMap.MaximumSalary.key] || ''}
            onChange={handleValueChange}
            inputType="currency"
            disabled={uiState.isFormDisabled}
            width="100%"
          />
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.MinimumSalary.key, errors, noMargin)}
            label="Low salary *"
            value={formValues[FormFieldsMap.MinimumSalary.key] || ''}
            onChange={handleValueChange}
            inputType="currency"
            disabled={uiState.isFormDisabled}
            width="100%"
          />
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.IdealSalary.key, errors, noMargin)}
            label="Ideal salary *"
            value={formValues[FormFieldsMap.IdealSalary.key] || ''}
            onChange={handleValueChange}
            inputType="currency"
            disabled={uiState.isFormDisabled}
            width="100%"
          />
          <Text
            text="Other Compensations and benefits"
            variant="body2"
            className={globalClasses.otherLabel}
          />
          <div className={globalClasses.sheetInputContainer}>
            <TextBox
              {...buildBasePropsForField(FormFieldsMap.Benefits.key, errors, noMargin)}
              label="Other benefits *"
              multiline
              inputRef={register({ required: 'Other benefits are required' })}
              disabled={uiState.isFormDisabled}
            />
          </div>
        </div>
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="7.- Minimum background requirements *" />
        <div className={globalClasses.sheetInputContainer}>
          <TextBox
            label="Background requirements*"
            {...buildBasePropsForField(FormFieldsMap.Background.key, errors, noMargin)}
            inputRef={register({ required: 'Minimum background requirements is required' })}
            disabled={uiState.isFormDisabled}
            multiline
          />
        </div>
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="8.- Work location: *" />
        <div className={globalClasses.sheetInputContainer}>
          <AutocompleteSelect
            width="100%"
            {...buildBasePropsForField(FormFieldsMap.WorkType.key, errors, noMargin)}
            selectedValue={formValues[FormFieldsMap.WorkType.key]}
            placeholder="Work preference *"
            url={Endpoints.WorkTypes}
            onSelect={handleValueChange}
            disabled={uiState.isFormDisabled}
          />
        </div>
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="9.- Preset interview dates:" />
        <div className={globalClasses.sheetInputContainer}>
          {datesIndexer.map(index => {
            const interviewDate = formValues[`${InterviewDatePrefix}`][index];
            return (
              <CustomDatePicker
                key={`${InterviewDatePrefix}${index}`}
                name={`${InterviewDatePrefix}${index}`}
                label="Date & Time"
                value={interviewDate}
                onDateChange={handleInterviewDateChange}
                withTime
                disablePast={!isExistingWhitesheet}
                disabled={uiState.isFormDisabled}
              />
            );
          })}
          <input
            ref={register()}
            type="hidden"
            name={FormFieldsMap.Id.key}
            value={whitesheet?.id || ''}
          />
        </div>
      </div>

      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="10.- Job Order Type: *" />
        <div className={globalClasses.sheetInputContainer}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.Type.key, errors, noMargin)}
            selectedValue={formValues[FormFieldsMap.Type.key]}
            placeholder="Type *"
            url={Endpoints.JobOrderTypes}
            onSelect={handleValueChange}
            disabled={uiState.isFormDisabled}
            renderOption={statusRenderOption}
            startAdornment={
              formValues[FormFieldsMap.Type.key] &&
              statusStartAdornment(
                formValues[FormFieldsMap.Type.key].color ||
                  formValues[FormFieldsMap.Type.key].style_class_name
              )
            }
          />
        </div>
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="11.- Job Order Status: *" />
        <div className={globalClasses.sheetInputContainer}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.Status.key, errors, noMargin)}
            selectedValue={formValues[FormFieldsMap.Status.key]}
            placeholder="Status *"
            url={`${Endpoints.JobOrderStatuses}?selectable=true`}
            onSelect={handleValueChange}
            disabled={uiState.isFormDisabled}
            renderOption={statusRenderOption}
            startAdornment={
              formValues[FormFieldsMap.Status.key] &&
              statusStartAdornment(
                formValues[FormFieldsMap.Status.key].color ||
                  formValues[FormFieldsMap.Status.key].style
              )
            }
          />
        </div>
      </div>
      <div className={globalClasses.sheetQuestion}>
        <Text variant="h2" text="Additional Details: " />
        <div className={globalClasses.sheetInputContainer}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Notes.key, errors, noMargin)}
            label="More details"
            inputRef={register}
            disabled={uiState.isFormDisabled}
            multiline
          />
        </div>
      </div>
    </DrawerContentLayout>
  );
};

JobOrderSheet.defaultProps = {
  whitesheet: null,
  joborderId: null,
  companyId: 0,
  profile: null
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: alert => dispatch(showAlertAction(alert))
  };
};

const JobOrderSheetConnected = connect(null, mapDispatchToProps)(JobOrderSheet);

export default JobOrderSheetConnected;
