export const updateConversationList = ({ conversations, id, updates }) => {
  if (!id) return conversations;

  const conversationIndex = conversations.findIndex(conversation => conversation.id === id);
  if (conversationIndex === -1) return conversations; // No matching conversation, return original

  const updatedConversation = {
    ...conversations[conversationIndex],
    ...updates
  };

  // Update in place without reordering
  return conversations.map((conversation, index) =>
    index === conversationIndex ? updatedConversation : conversation
  );
};
