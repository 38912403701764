import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          idKey: 'statusId',
          labelKey: 'status',
          baseClass: 'chip-joborder-status'
        }
      }
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          idKey: 'typeId',
          labelKey: 'type',
          baseClass: 'chip-joborder-type'
        }
      }
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.JobOrderProfile,
          idKey: 'id',
          textKey: 'title',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'functionalTitle',
    label: 'Functional Title',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'companyName',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'companyId',
          textKey: 'companyName',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'companyType',
    label: 'Company Type',
    options: {
      display: true,
      renderer: {
        id: 'fastChipBuilder',
        config: {
          idKey: 'companyTypeId',
          labelKey: 'companyType',
          baseClass: 'chip-company-type'
        }
      }
    }
  },
  {
    name: 'industry',
    label: 'Industry',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'location',
    label: 'Location',
    options: {
      sort: false,
      display: true
    }
  },
  {
    name: 'createdAt',
    label: 'Added Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'lastActivityDate',
    label: 'Last Activity',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'recruiterName',
    label: 'Recruiter',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'idealCompensation',
    label: 'Ideal comp.',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'highCompensation',
    label: 'High comp.',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  },
  {
    name: 'lowCompensation',
    label: 'Low comp.',
    options: {
      sort: true,
      display: true,
      renderer: 'currency'
    }
  }
];

export const VIRTUAL_PROPS = [
  {
    propName: 'status',
    attributes: ['statusId', 'status']
  },
  {
    propName: 'type',
    attributes: ['typeId', 'type']
  },
  {
    propName: 'companyName',
    attributes: ['companyId', 'companyName']
  },
  {
    propName: 'companyType',
    attributes: ['companyTypeId', 'companyType']
  },
  {
    propName: 'title',
    attributes: ['id', 'title']
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
