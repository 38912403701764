// @flow
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-plus-time-range';
import moment from 'moment/moment';
import type { DatePeriod } from 'types/app';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import TextBox from 'UI/components/atoms/TextBox';
import FooterActionsControlsLegacy from 'UI/components/molecules/FooterActionsControlsLegacy';
import { DateFormats } from 'UI/constants/defaults';

import { useStyles } from './DateRange.styled';

import 'UI/res/dateRangePicker.css';

const datePickerKey = 'selection';

type DateRangeProps = {
  DateRangerPickerProps?: any,
  defaultRange?: DatePeriod,
  disableTimeRange: boolean,
  isPeriodClearable?: boolean,
  onPeriodChange: (period: DatePeriod) => any
};

export const DateRange = ({
  DateRangerPickerProps,
  defaultRange,
  disableTimeRange,
  isPeriodClearable,
  onPeriodChange
}: DateRangeProps) => {
  const classes = useStyles();
  const dateFormatToShow = disableTimeRange ? DateFormats.SimpleDate : DateFormats.SimpleDateTime;

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriod>(defaultRange);
  const [previewRange, setPreviewRange] = useState([
    {
      ...defaultRange,
      key: datePickerKey
    }
  ]);

  const shouldShowPeriodClearButton = isPeriodClearable || !defaultRange;

  const toggleDatePicker = val => () => {
    setIsDatePickerOpen(val);
  };

  const handleDateRangeChange = item => {
    const { selection } = item || {};

    const isStartDateValid = moment(selection.startDate).isValid();
    const isEndDateValid = moment(selection.endDate).isValid();

    if (!isStartDateValid || !isEndDateValid) return;

    const endHours = selection.endDate.getHours();
    const endMinutes = selection.endDate.getMinutes();
    const timeStartsAtZero = endHours === 0 && endMinutes === 0;

    setPreviewRange([
      {
        ...selection,
        ...(timeStartsAtZero ? { endDate: moment(selection.endDate).endOf('day').toDate() } : {})
      }
    ]);
  };

  const handleDateRangeCancel = () => {
    setIsDatePickerOpen(false);
    selectedPeriod &&
      setPreviewRange([
        {
          key: datePickerKey,
          startDate: selectedPeriod.startDate,
          endDate: selectedPeriod.endDate
        }
      ]);
  };

  const handleDateRangeApply = () => {
    const { startDate, endDate } = previewRange[0];
    setIsDatePickerOpen(false);
    const newPeriod = {
      startDate,
      endDate
    };
    setSelectedPeriod(newPeriod);
    onPeriodChange && onPeriodChange(newPeriod);
  };

  const handleClearPeriod = () => {
    setIsDatePickerOpen(false);
    setSelectedPeriod(null);
    setPreviewRange([{ key: datePickerKey }]);
    onPeriodChange && onPeriodChange(null);
  };

  return (
    <div>
      <TextBox
        name="dateRange"
        label="Date Range"
        onFocus={toggleDatePicker(true)}
        minWidth={370}
        width={370}
        value={
          selectedPeriod
            ? `${moment(selectedPeriod.startDate).format(dateFormatToShow)} - ${moment(
                selectedPeriod.endDate
              ).format(dateFormatToShow)}`
            : 'All Time'
        }
      />
      {isDatePickerOpen && (
        <div className={classes.root}>
          <div className={classes.dateRangeContainer}>
            <DateRangePicker
              onChange={handleDateRangeChange}
              showSelectionPreview
              inputRanges={[]}
              months={2}
              ranges={previewRange}
              showTime={!disableTimeRange}
              direction="horizontal"
              {...DateRangerPickerProps}
            />
            <div className={classes.dateRangeToolbar}>
              <FooterActionsControlsLegacy
                secondaryAction={handleDateRangeCancel}
                saveAction={false}
                secondaryProps={{ size: 'small' }}
                primaryProps={{
                  onClick: handleDateRangeApply,
                  text: 'Apply filters',
                  size: 'small'
                }}
                customThirdButton={
                  shouldShowPeriodClearButton && (
                    <FPActionButton
                      text="Clear"
                      variant="outlined"
                      onClick={handleClearPeriod}
                      size="small"
                    />
                  )
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
