import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { FeatureFlags } from 'UI/constants/featureFlags';

const useNewJobOrderFeatureFlags = () => {
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  return {
    canUseAssignmentSheetOnCreation: checkIfFeatureFlagEnabled(FeatureFlags.AssignmentSheetCreation)
  };
};

export default useNewJobOrderFeatureFlags;
