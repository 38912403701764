import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useGlobalStyles } from 'GlobalStyles';
import useProfile from 'hooks/profileActions';
import useFetchCatalogs from 'hooks/useFetchCatalogs';
import useFetchJsonSchema from 'hooks/useFetchJsonSchema';
import usePageTitle from 'hooks/usePageTitle';
import { useUserContext } from 'providers/UserProvider';
import { canUserOrStaffEditEntity } from 'services/Authorization';
import { JobOrderIncludes } from 'services/includes';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { FPCard } from 'UI/components/atoms/FPCard';
import { When } from 'UI/components/atoms/When';
import { DataSheetFooter } from 'UI/components/molecules/DataSheetFooter';
import FPFormStatus from 'UI/components/molecules/FPFormStatus';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import ContentPageLayout from 'UI/components/templates/ContentPageLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { PageTitles } from 'UI/constants/defaults';
import { EntityType, FormSchema } from 'UI/constants/entityTypes';
import { RequestStatus, UIStatus } from 'UI/constants/status';
import ErrorPage from 'UI/pages/ErrorPage';
import { SvgNoJobOrders } from 'UI/res';

import { ProgressLabel } from '../EditCandidateDataSheet/EditCandidateDataSheet.styled';

import {
  AnimationContainer,
  EditDataSheetCardContent,
  MainContainer,
  StyledGpacGeneratorForm
} from './EditDataSheet.styled';
import { useDataSheetSchema, useFetchDataSheet } from './hooks';

const HEADER_PROPS = {
  title: strings.inventory.jobOrders.editDataSheet.title,
  subheader: strings.inventory.jobOrders.editDataSheet.subheader,
  variant: 'section',
  actionMode: 'noAction'
};

const { decisionDialog: decisionDialogCopies } = strings.inventory.jobOrders.editDataSheet;

const { contentPageTitle } = strings.inventory.jobOrders.editDataSheet;

const EditDataSheet = ({ match, history }) => {
  usePageTitle({ title: PageTitles.EditDataSheet, isLoading: false });
  const jobOrderId = match.params.id;
  const [currentUser] = useUserContext();
  const { status: schemaStatus, schemaData } = useFetchJsonSchema(FormSchema.JobOrderDataSheet);
  const { status: dataSheetStatus, results } = useProfile({
    entity: EntityType.Joborder,
    profileId: jobOrderId,
    includes: JobOrderIncludes
  });

  const { id, title, whiteSheet, company, status } = results || {};
  const canUserEdit = canUserOrStaffEditEntity(currentUser, results) || !results;
  const { status: dataSheetFormStatus, dataSheet } = useFetchDataSheet(jobOrderId);
  const { catalogs, uiState: catalogsStatus } = useFetchCatalogs({ companyId: company?.id });
  const [dataSheetSchemaState, dataSheetSchemaActions] = useDataSheetSchema({
    jobOrder: {
      id,
      title
    },
    companyName: company?.name || '',
    canUserEdit,
    whiteSheet,
    whiteSheetStatus: status,
    dataSheet,
    catalogs,
    schemaData,
    user: currentUser,
    history
  });

  const globalClasses = useGlobalStyles();

  const finalHeaderProps = {
    ...HEADER_PROPS,
    title: (
      <Box display="flex" flexDirection="row" fontWeight="normal" alignItems="center">
        <ProgressLabel component="span">{HEADER_PROPS.title}</ProgressLabel>&nbsp;
        <When condition={!!dataSheetSchemaState.dataSheetProgress}>
          <FPFormStatus
            status={dataSheetSchemaState.dataSheetProgress?.key}
            totalQuestions={dataSheetSchemaState.dataSheetProgress?.total}
            totalAnsweredQuestions={dataSheetSchemaState.dataSheetProgress?.current}
            shouldShowTotal
          />
        </When>
      </Box>
    )
  };

  const CustomDialogActions = ({ defaultButtonsProps, ActionsMapper }) => {
    const { onClose: closeDialog } = defaultButtonsProps;

    return (
      <ActionsMapper
        buttons={[
          {
            variant: 'outlined',
            text: decisionDialogCopies.buttons.cancel,
            onClick: closeDialog
          },
          {
            variant: 'outlined',
            text: decisionDialogCopies.buttons.exitWithoutSaving,
            onClick: dataSheetSchemaActions.handleClose
          },
          {
            variant: 'contained',
            text: decisionDialogCopies.buttons.saveAndExit,
            onClick: dataSheetSchemaActions.handleOnSave
          }
        ]}
      />
    );
  };

  if (!canUserEdit) {
    return <ErrorPage error={HTTPStatusCodes.Unauthorized} />;
  }

  if (
    [dataSheetStatus, catalogsStatus, schemaStatus, dataSheetFormStatus].includes(
      RequestStatus.Loading
    )
  ) {
    return (
      <ContentPageLayout
        mode="mainLayout"
        text={contentPageTitle}
        titleLabelProps={{ backNavigation: true }}
      >
        <AnimationContainer>
          <CircularProgress />
        </AnimationContainer>
      </ContentPageLayout>
    );
  }

  if ([dataSheetStatus, catalogsStatus, schemaStatus].includes(RequestStatus.Error)) {
    return (
      <ContentPageLayout>
        <EmptyPlaceholder
          {...strings.shared.errors.serverError}
          customEmptyState={<SvgNoJobOrders height={400} />}
        >
          <FPActionButton text="Try again" onClick={() => window.location.reload()} />
        </EmptyPlaceholder>
      </ContentPageLayout>
    );
  }

  if (
    [dataSheetStatus, catalogsStatus, schemaStatus, dataSheetFormStatus].includes(
      RequestStatus.Idle
    )
  ) {
    return null;
  }

  return (
    <ContentPageLayout
      mode="mainLayout"
      text={contentPageTitle}
      titleLabelProps={{
        backNavigation: true,
        mode: 'customAction',
        onCustomBackClick: dataSheetSchemaActions.handleCancel
      }}
    >
      <MainContainer className={globalClasses.itemCreationDataWrapper}>
        <FPCard>
          <EditDataSheetCardContent variant="relaxed" headerProps={finalHeaderProps}>
            <StyledGpacGeneratorForm
              schema={dataSheetSchemaState.schemas.schema}
              uiSchema={dataSheetSchemaState.schemas.uiSchema}
              onSubmit={dataSheetSchemaActions.handleOnSubmit}
              onChange={dataSheetSchemaActions.handleOnChange}
              formData={dataSheetSchemaState.formData}
              onError={dataSheetSchemaActions.handleOnError}
              customValidate={dataSheetSchemaActions.handleCustomValidate}
              {...dataSheetSchemaState.commonStyledFormProps}
            />
          </EditDataSheetCardContent>
        </FPCard>
      </MainContainer>
      <DataSheetFooter
        isSaving={dataSheetSchemaState.status === UIStatus.Saving}
        canSave
        onSave={dataSheetSchemaActions.handleOnSave}
        onClose={dataSheetSchemaActions.handleCancel}
      />
      <DecisionDialog
        title={decisionDialogCopies.title}
        message={decisionDialogCopies.message}
        isOpened={dataSheetSchemaState.isDraftDialogOpen}
        onClose={dataSheetSchemaActions.toggleDraftDialog(false)}
        onConfirm={dataSheetSchemaActions.handleOnConfirm}
        CustomActions={CustomDialogActions}
        severity="warning"
        showSeverityIcon
        mustComplete
        withButtons="YesNo"
      />
    </ContentPageLayout>
  );
};

export default EditDataSheet;
