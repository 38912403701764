import { GLOBAL_SELECTORS_KEYS, PREPEND_OPTIONS_ALL } from 'features/command-center/constants';
import { parsePhoneNumber } from 'libphonenumber-js';
import { userHasRoles } from 'services/Authorization';
import { Roles } from 'UI/constants/roles';
import { loadFromLocalStorage } from 'UI/utils';

import { ALL_COMPANY_VISIBILITY_ROLES } from '../components/layout/DominateSheetLayout/DominateSheetLayout.constants';

/**
 * The function `getWeekRange` takes a week object as input and returns an object containing the start
 * and end dates of the week.
 * @returns The function `getWeekRange` returns an object with two properties: `startDate` and
 * `endDate`.
 */
export const getWeekRange = week => {
  if (!week) return {};

  const daysToAdd = 6;

  const startDate = week.date;
  const start = new Date(startDate);
  const end = new Date(start);
  end.setDate(end.getDate() + daysToAdd);

  const endDate = end.toISOString().split('T')[0];

  return {
    startDate,
    endDate
  };
};

export const capitalizeName = name => {
  if (!name) return null;
  return name
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getCoachInitialState = user => {
  const isAssistantRegionalDirector = userHasRoles([Roles.AssistantRegionalDirector]);
  const hasSuperiorRole = userHasRoles(ALL_COMPANY_VISIBILITY_ROLES);

  if (!isAssistantRegionalDirector || hasSuperiorRole)
    return (
      loadFromLocalStorage(GLOBAL_SELECTORS_KEYS.dominate.coach, null) || PREPEND_OPTIONS_ALL[0]
    );

  return {
    email: user?.teamRelation?.coachEmail,
    id: user?.teamRelation?.coachId
  };
};

export const formatInternationalPhone = number => {
  if (!number) return number;

  try {
    const parsed = parsePhoneNumber(number);
    return parsed.formatInternational();
  } catch (error) {
    return number;
  }
};
