import { makeStyles, styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  footerTitle: {
    display: 'flex'
  },
  label: {
    paddingLeft: theme.spacing(1)
  },
  list: {
    margin: theme.spacing(0.5)
  },
  title: {
    fontWeight: 'bold'
  }
}));

export const ActionContent = styled('div')({
  alignItems: 'center',
  height: '100%',
  justifyContent: 'flex-end',
  display: 'flex'
});

export const RefreshButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  textTransform: 'capitalize',
  color: theme.palette.primary.light,
  padding: theme.spacing(1.25, 2, 1.25, 2),
  fontSize: 14
}));
