// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import useUnload from 'hooks/unload';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import TextBox from 'UI/components/atoms/TextBox';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import IndustryFields from 'UI/components/molecules/IndustryFields';
import LocationForm from 'UI/components/organisms/LocationForm';
import { FORM_SPACING, getGridColumnSize } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { CompanyEntity } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import {
  EXT_PHONE_VALIDATION,
  NICKNAME_VALIDATION,
  PHONE_VALIDATION,
  REQUIRED_VALIDATION,
  URL_VALIDATION,
  VALIDATION_REGEXS
} from 'UI/utils';
import { areEmailsEqual, buildBasePropsForField } from 'UI/utils/forms';
import { OptionRenderers, Selectors } from 'UI/utils/renderers';

import PhoneNumbersForm from '../PhoneNumbersForm/PhoneNumbersForm';
import { DEFAULT_PHONE_ROW } from '../PhoneNumbersForm/utils';

import { FormFieldsMap } from './fields';

type CandidateFormProps = {
  disabled: boolean,
  isEditing: boolean,
  emailDisabled?: boolean,
  fetchingCompany: boolean,
  noMargin?: boolean,
  onValuesChanged: () => void,
  isFromProfile?: boolean
};

const chainedSelects = {
  [FormFieldsMap.State.key]: [FormFieldsMap.City.key, FormFieldsMap.Zip.key],
  [FormFieldsMap.City.key]: [FormFieldsMap.Zip.key]
};

const CandidateForm = ({
  disabled,
  isEditing,
  emailDisabled,
  fetchingCompany,
  noMargin,
  isFromProfile,
  onValuesChanged
}: CandidateFormProps) => {
  const location = useLocation();

  const { register, errors, setValue, watch, getValues, formState } = useFormContext();
  const formValues = watch();
  const { touched: fieldsTouched, dirtyFields } = formState;
  const touchedLength = Object.keys(fieldsTouched).length;
  const dirtyFieldsLength = dirtyFields.size;

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const shouldDisplayLegacyPhoneFields = !checkIfFeatureFlagEnabled(
    FeatureFlags.CandidatesMultiplePhones
  );

  useUnload(e => {
    e.preventDefault();
    onValuesChanged && onValuesChanged(getValues());
  });

  useEffect(() => {
    register({ name: FormFieldsMap.Company.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.Source.key }, REQUIRED_VALIDATION);
    if (shouldDisplayLegacyPhoneFields) {
      register({ name: FormFieldsMap.Phone.key }, { ...REQUIRED_VALIDATION, ...PHONE_VALIDATION });
      register({ name: FormFieldsMap.Mobile.key }, { ...PHONE_VALIDATION });
    }
    register({
      name: FormFieldsMap.Phones.key,
      value: [DEFAULT_PHONE_ROW]
    });
  }, [register, getValues, shouldDisplayLegacyPhoneFields]);

  useEffect(() => {
    onValuesChanged && onValuesChanged(getValues());
  }, [touchedLength, dirtyFieldsLength, onValuesChanged, getValues]);

  const handleComboChange = (name?: string, value: any) => {
    if (value?.createButton) return;

    setValue(name, value, true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => {
        setValue(chainedSelect, null);
      });
    }
    onValuesChanged && onValuesChanged(getValues());
  };

  const handlePhoneChange = (name?: string, value: any) => {
    setValue(name, value, true);
    onValuesChanged && onValuesChanged(getValues());
  };

  const { sm, xs, md, lg } = getGridColumnSize(isEditing);

  return (
    <Grid container className="creation-form-layout">
      <Grid container item spacing={FORM_SPACING}>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.Company.key, errors)}
            displayKey="name"
            fetching={fetchingCompany}
            getOptionSelected={Selectors.byId}
            onSelect={handleComboChange}
            placeholder="Company *"
            renderOption={OptionRenderers.globalSearchDefault('name')}
            selectedValue={formValues[FormFieldsMap.Company.key]}
            typeahead
            typeaheadLimit={50}
            typeaheadParams={{ entityType: CompanyEntity.id, isForConvertion: true }}
            url={Endpoints.Search}
            createButton={{
              concatKeyword: true,
              location,
              showAlways: true,
              text: 'Create Company',
              url: EntityRoutes.CompanyCreate
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.FirstName.key, errors)}
            label="First Name *"
            inputRef={register(REQUIRED_VALIDATION)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.LastName.key, errors)}
            label="Last Name *"
            inputRef={register(REQUIRED_VALIDATION)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Nickname.key, errors)}
            label="Nickname"
            inputRef={register(NICKNAME_VALIDATION)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Email.key, errors, noMargin, emailDisabled)}
            label="Email *"
            inputRef={register({
              ...REQUIRED_VALIDATION,
              pattern: {
                value: VALIDATION_REGEXS.EMAIL,
                message: 'Email must be valid'
              },
              validate: {
                notEqual: value =>
                  areEmailsEqual(value, watch(FormFieldsMap.PersonalEmail.key))
                    ? strings.shared.validations.otherEmail
                    : true
              }
            })}
          />
        </Grid>
        <IndustryFields
          isEditing={isEditing}
          disabled={disabled}
          onValuesChanged={onValuesChanged}
        />
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.Position.key, errors)}
            label="Title *"
            inputRef={register(REQUIRED_VALIDATION)}
            disabled={disabled}
          />
        </Grid>
        <LocationForm
          state={formValues[FormFieldsMap.State.key]}
          city={formValues[FormFieldsMap.City.key]}
          zip={formValues[FormFieldsMap.Zip.key]}
          handleComboChange={handleComboChange}
          isEditing={isEditing}
          disabled={disabled}
        />
        {shouldDisplayLegacyPhoneFields && (
          <>
            <Grid item xs={xs} sm={sm} md={md} lg={lg}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.Phone.key, errors)}
                label="Phone *"
                placeholder="(999)-999-9999"
                onChange={handlePhoneChange}
                value={formValues[FormFieldsMap.Phone.key] || ''}
                inputType="phone"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={xs} sm={sm} md={md} lg={lg}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.Ext.key, errors)}
                label="Ext"
                placeholder="999"
                inputRef={register({ ...EXT_PHONE_VALIDATION })}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={xs} sm={sm} md={md} lg={lg}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.Mobile.key, errors)}
                label="Other phone"
                placeholder="(999)-999-9999"
                onChange={handlePhoneChange}
                value={formValues[FormFieldsMap.Mobile.key] || ''}
                inputType="phone"
                disabled={disabled}
              />
            </Grid>
          </>
        )}
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.PersonalEmail.key, errors)}
            label="Other email"
            inputRef={register({
              pattern: {
                value: VALIDATION_REGEXS.EMAIL,
                message: 'Email must be valid'
              },
              validate: {
                notEqual: value =>
                  areEmailsEqual(value, watch(FormFieldsMap.Email.key))
                    ? strings.shared.validations.otherEmail
                    : true
              }
            })}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.Source.key, errors)}
            selectedValue={formValues[FormFieldsMap.Source.key]}
            placeholder="Source *"
            url={`${Endpoints.Candidates}/${Endpoints.SourceTypes}`}
            onSelect={handleComboChange}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.LinkedinUrl.key, errors)}
            label="LinkedIn URL"
            inputRef={register(URL_VALIDATION)}
            disabled={disabled}
          />
        </Grid>
        <input ref={register()} type="hidden" name="status_id" value="1" />
      </Grid>
      <When condition={!shouldDisplayLegacyPhoneFields}>
        <PhoneNumbersForm isEditing={isEditing} isFromProfile={isFromProfile} isRequired />
      </When>
    </Grid>
  );
};

CandidateForm.defaultProps = {
  disabled: false,
  isEditing: false,
  emailDisabled: false,
  fetchingCompany: false,
  noMargin: true,
  isFromProfile: false
};

export default CandidateForm;
