import {
  CallsConfig,
  CandidatesConfig,
  CompaniesConfig,
  FallOffsConfig,
  HealthDistributionAtRiskConfig,
  HealthDistributionBehindConfig,
  HealthDistributionHeadCountConfig,
  HealthDistributionInTrainingConfig,
  HealthDistributionOnTrackConfig,
  HealthDistributionUnder3MonthsConfig,
  HiringAuthoritiesConfig,
  JobOrdersConfig,
  NamesConfig,
  PlacementsConfig,
  RelatedCompaniesConfig,
  RelatedContactsConfig,
  SendoutsConfig,
  SendoversConfig,
  SentAgreementsConfig,
  SignedAgreementsConfig
} from './components';

export const DRAWER_KEYS = Object.freeze({
  Calls: 'Calls',
  CallsRelatedCompanies: 'CallsRelatedCompanies',
  CallsRelatedContacts: 'CallsRelatedContacts',
  Candidates: {
    Alpha: 'AlphaCandidates',
    CantHelp: 'CantHelpCandidates',
    Overall: 'Candidates',
    Poejo: 'PoejoCandidates',
    Total: 'Candidates'
  },
  Companies: {
    Client: 'ClientCompanies',
    NotSigned: 'NotSignedCompanies',
    Signed: 'SignedCompanies',
    Total: 'Companies'
  },
  FallOffs: 'FallOffs',
  HealthDistributionHeadcount: 'HealthDistributionHeadcount',
  HealthDistributionInTraining: 'HealthDistributionInTraining',
  HealthDistributionAtRisk: 'HealthDistributionAtRisk',
  HealthDistributionBehind: 'HealthDistributionBehind',
  HealthDistributionUnder3Months: 'HealthDistributionUnder3Months',
  HealthDistributionOnTrack: 'HealthDistributionOnTrack',
  HiringAuthorities: {
    Client: 'ClientHiringAuthorities',
    NotSigned: 'NotSignedHiringAuthorities',
    Signed: 'SignedHiringAuthorities',
    Total: 'HiringAuthorities'
  },
  JobOrders: {
    CantHelp: 'CantHelpJobOrders',
    Overall: 'JobOrders',
    Poejo: 'PoejoJobOrders',
    SearchAssignment: 'SearchAssignmentJobOrders',
    Total: 'JobOrders'
  },
  Names: {
    CandidateProspect: 'CandidateProspect',
    HiringAuthorityProspect: 'HiringAuthorityProspect',
    Overall: 'Names',
    Total: 'Names',
    UndefinedProspect: 'UndefinedProspect'
  },
  Contacts: {
    CandidateProspect: 'CandidateProspect',
    HiringAuthorityProspect: 'HiringAuthorityProspect',
    Overall: 'Names',
    Total: 'Names',
    UndefinedProspect: 'UndefinedProspect'
  },
  PhoneTime: 'PhoneTime',
  Placements: 'Placements',
  Sendouts: 'Sendouts',
  Sendovers: 'Sendovers',
  SentAgreements: 'SentAgrements',
  SignedAgreements: 'SignedAgreements'
});

export const DRAWERS_CONFIG = {
  [DRAWER_KEYS.Calls]: CallsConfig,
  [DRAWER_KEYS.CallsRelatedContacts]: RelatedContactsConfig,
  [DRAWER_KEYS.CallsRelatedCompanies]: RelatedCompaniesConfig,
  [DRAWER_KEYS.Candidates.Alpha]: CandidatesConfig,
  [DRAWER_KEYS.Candidates.CantHelp]: CandidatesConfig,
  [DRAWER_KEYS.Candidates.Overall]: CandidatesConfig,
  [DRAWER_KEYS.Candidates.Poejo]: CandidatesConfig,
  [DRAWER_KEYS.Candidates.Total]: CandidatesConfig,
  [DRAWER_KEYS.Companies.Client]: CompaniesConfig,
  [DRAWER_KEYS.Companies.NotSigned]: CompaniesConfig,
  [DRAWER_KEYS.Companies.Signed]: CompaniesConfig,
  [DRAWER_KEYS.Companies.Total]: CompaniesConfig,
  [DRAWER_KEYS.FallOffs]: FallOffsConfig,
  [DRAWER_KEYS.HealthDistributionAtRisk]: HealthDistributionAtRiskConfig,
  [DRAWER_KEYS.HealthDistributionBehind]: HealthDistributionBehindConfig,
  [DRAWER_KEYS.HealthDistributionHeadcount]: HealthDistributionHeadCountConfig,
  [DRAWER_KEYS.HealthDistributionInTraining]: HealthDistributionInTrainingConfig,
  [DRAWER_KEYS.HealthDistributionOnTrack]: HealthDistributionOnTrackConfig,
  [DRAWER_KEYS.HealthDistributionUnder3Months]: HealthDistributionUnder3MonthsConfig,
  [DRAWER_KEYS.HiringAuthorities.Client]: HiringAuthoritiesConfig,
  [DRAWER_KEYS.HiringAuthorities.NotSigned]: HiringAuthoritiesConfig,
  [DRAWER_KEYS.HiringAuthorities.Signed]: HiringAuthoritiesConfig,
  [DRAWER_KEYS.HiringAuthorities.Total]: HiringAuthoritiesConfig,
  [DRAWER_KEYS.JobOrders.CantHelp]: JobOrdersConfig,
  [DRAWER_KEYS.JobOrders.Overall]: JobOrdersConfig,
  [DRAWER_KEYS.JobOrders.Poejo]: JobOrdersConfig,
  [DRAWER_KEYS.JobOrders.SearchAssignment]: JobOrdersConfig,
  [DRAWER_KEYS.JobOrders.Total]: JobOrdersConfig,
  [DRAWER_KEYS.Names.CandidateProspect]: NamesConfig,
  [DRAWER_KEYS.Names.HiringAuthorityProspect]: NamesConfig,
  [DRAWER_KEYS.Names.Overall]: NamesConfig,
  [DRAWER_KEYS.Names.Total]: NamesConfig,
  [DRAWER_KEYS.Names.UndefinedProspect]: NamesConfig,
  [DRAWER_KEYS.PhoneTime]: CallsConfig,
  [DRAWER_KEYS.Placements]: PlacementsConfig,
  [DRAWER_KEYS.Sendouts]: SendoutsConfig,
  [DRAWER_KEYS.Sendovers]: SendoversConfig,
  [DRAWER_KEYS.SentAgreements]: SentAgreementsConfig,
  [DRAWER_KEYS.SignedAgreements]: SignedAgreementsConfig
};
