// @flow

import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { EntityRoutes } from 'routes/constants';
import strings from 'strings';
import type { Map } from 'types';
import TextBox from 'UI/components/atoms/TextBox';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import IndustryFields from 'UI/components/molecules/IndustryFields';
import LocationForm from 'UI/components/organisms/LocationForm';
import { FORM_SPACING, getGridColumnSize, minWidthTextBox } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import { FeatureFlags } from 'UI/constants/featureFlags';
import {
  EXT_PHONE_VALIDATION,
  NICKNAME_VALIDATION,
  PHONE_VALIDATION,
  REQUIRED_VALIDATION,
  URL_VALIDATION,
  VALIDATION_REGEXS
} from 'UI/utils';
import { areEmailsEqual, buildBasePropsForField } from 'UI/utils/forms';
import { OptionRenderers } from 'UI/utils/renderers';

import PhoneNumbersForm from '../PhoneNumbersForm/PhoneNumbersForm';
import { DEFAULT_PHONE_ROW } from '../PhoneNumbersForm/utils';

import { FormFieldsMap } from './fields';

type NameFormProps = {
  initialValues: Map,
  fetchingCompany: boolean,
  isEditing: boolean
};

const NameTypeId = 0;
const chainedSelects = {
  [FormFieldsMap.SPECIALTY.key]: [
    FormFieldsMap.SUBSPECIALTY.key,
    FormFieldsMap.FUNCTIONAL_TITLE.key
  ],
  [FormFieldsMap.STATE.key]: [FormFieldsMap.CITY.key, FormFieldsMap.ZIP.key],
  [FormFieldsMap.CITY.key]: [FormFieldsMap.ZIP.key]
};

const getPhoneInputType = phone =>
  phone && phone.length > PHONE_VALIDATION.maxLength.value ? 'text' : 'phone';

const NameForm = ({ initialValues, fetchingCompany, isEditing }: NameFormProps) => {
  const location = useLocation();

  const { register, errors, setValue, getValues, watch } = useFormContext();

  const formValues = watch();

  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const shouldDisplayLegacyPhoneFields = !checkIfFeatureFlagEnabled(
    FeatureFlags.ContactsMultiplePhones
  );

  useEffect(() => {
    register({ name: FormFieldsMap.COMPANY.key }, REQUIRED_VALIDATION);
    register({ name: FormFieldsMap.STATE.key });
    register({ name: FormFieldsMap.CITY.key });
    register({ name: FormFieldsMap.ZIP.key });
    register({ name: FormFieldsMap.SOURCE.key });
    register({ name: FormFieldsMap.NAME_STATUS.key });
    register({ name: FormFieldsMap.PHONE.key }, { ...PHONE_VALIDATION });
    if (shouldDisplayLegacyPhoneFields) {
      register({ name: FormFieldsMap.OTHER_PHONE.key }, { ...PHONE_VALIDATION });
    }
    register({
      name: FormFieldsMap.PHONES.key,
      value: [DEFAULT_PHONE_ROW]
    });
  }, [register, shouldDisplayLegacyPhoneFields]);

  useEffect(() => {
    if (!initialValues?.company_id) return;
    setValue(FormFieldsMap.COMPANY.key, initialValues.company_id);
  }, [initialValues.company_id, setValue]);

  const handleComboChange = (name?: string, value: any) => {
    setValue(name, value, true);

    if (name && chainedSelects[name]) {
      chainedSelects[name].forEach(chainedSelect => {
        setValue(chainedSelect, null);
      });
    }
  };

  const handlePhoneChange = (name?: string, value: any) => {
    setValue(name, value, true);
  };

  const { sm, xs, md, lg } = getGridColumnSize(isEditing);

  return (
    <Grid container className="creation-form-layout">
      <Grid container item spacing={FORM_SPACING}>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.COMPANY.key, errors)}
            fetching={fetchingCompany}
            selectedValue={formValues[FormFieldsMap.COMPANY.key]}
            displayKey="name"
            placeholder="Company *"
            typeahead
            typeaheadLimit={50}
            typeaheadParams={{ entityType: 'company', isForConvertion: true }}
            url={Endpoints.Search}
            createButton={{
              text: 'Create company',
              concatKeyword: true,
              url: EntityRoutes.CompanyCreate,
              showAlways: true,
              location
            }}
            renderOption={OptionRenderers.globalSearchDefault('name')}
            onSelect={handleComboChange}
            noMargin
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.FIRST_NAME.key, errors)}
            label="First Name *"
            inputRef={register(REQUIRED_VALIDATION)}
            minWidth={minWidthTextBox}
            noMargin
            inputProps={{ 'aria-label': 'first name' }}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.LAST_NAME.key, errors)}
            label="Last Name *"
            inputRef={register(REQUIRED_VALIDATION)}
            minWidth={minWidthTextBox}
            noMargin
            inputProps={{ 'aria-label': 'last name' }}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.NICKNAME.key, errors)}
            label="Nickname"
            inputRef={register(NICKNAME_VALIDATION)}
            minWidth={minWidthTextBox}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.EMAIL.key, errors)}
            label="Email"
            inputRef={register({
              pattern: {
                value: VALIDATION_REGEXS.EMAIL,
                message: 'Email must be valid'
              },
              validate: {
                notEqual: value =>
                  areEmailsEqual(value, watch(FormFieldsMap.OTHER_EMAIL.key))
                    ? strings.shared.validations.otherEmail
                    : true
              }
            })}
            minWidth={minWidthTextBox}
            noMargin
          />
        </Grid>
        <IndustryFields isEditing={isEditing} disabled={false} onSelect={handleComboChange} />
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.TITLE.key, errors)}
            label="Title *"
            inputRef={register(REQUIRED_VALIDATION)}
            minWidth={minWidthTextBox}
            noMargin
            inputProps={{ 'aria-label': 'name title' }}
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <AutocompleteSelect
            name={FormFieldsMap.NAME_STATUS.key}
            selectedValue={formValues[FormFieldsMap.NAME_STATUS.key]}
            placeholder="Status"
            url={Endpoints.SimpleStatuses.replace(':nameTypeId', NameTypeId)}
            onSelect={handleComboChange}
            noMargin
          />
        </Grid>
        {shouldDisplayLegacyPhoneFields && (
          <>
            <Grid item xs={xs} sm={sm} md={md} lg={lg}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.PHONE.key, errors)}
                label="Phone"
                placeholder="(999)-999-9999"
                minWidth={minWidthTextBox}
                onChange={handlePhoneChange}
                value={getValues('phone') || ''}
                inputType={getPhoneInputType(formValues.phone)}
                noMargin
              />
            </Grid>
            <Grid item xs={xs} sm={sm} md={md} lg={lg}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.EXT.key, errors)}
                label="Ext"
                placeholder="999"
                inputRef={register({ ...EXT_PHONE_VALIDATION })}
                minWidth={minWidthTextBox}
                noMargin
              />
            </Grid>
            <Grid item xs={xs} sm={sm} md={md} lg={lg}>
              <TextBox
                {...buildBasePropsForField(FormFieldsMap.OTHER_PHONE.key, errors)}
                label="Other phone"
                placeholder="(999)-999-9999"
                minWidth={minWidthTextBox}
                onChange={handlePhoneChange}
                value={getValues(FormFieldsMap.OTHER_PHONE.key) || ''}
                inputType={getPhoneInputType(formValues.mobile)}
                noMargin
              />
            </Grid>
          </>
        )}
        <LocationForm
          state={formValues[FormFieldsMap.STATE.key]}
          city={formValues[FormFieldsMap.CITY.key]}
          zip={formValues[FormFieldsMap.ZIP.key]}
          handleComboChange={handleComboChange}
          isEditing={isEditing}
          removeRequiredMark
        />
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.OTHER_EMAIL.key, errors)}
            label="Other email"
            inputRef={register({
              pattern: {
                value: VALIDATION_REGEXS.EMAIL,
                message: 'Email must be valid'
              },
              validate: {
                notEqual: value =>
                  areEmailsEqual(value, watch(FormFieldsMap.EMAIL.key))
                    ? strings.shared.validations.otherEmail
                    : true
              }
            })}
            minWidth={minWidthTextBox}
            noMargin
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <AutocompleteSelect
            {...buildBasePropsForField(FormFieldsMap.SOURCE.key, errors)}
            selectedValue={formValues[FormFieldsMap.SOURCE.key]}
            placeholder="Source"
            url={Endpoints.SourceTypes}
            onSelect={handleComboChange}
            noMargin
          />
        </Grid>
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
          <TextBox
            {...buildBasePropsForField(FormFieldsMap.LINKEDIN_URL.key, errors)}
            label="Source URL"
            inputRef={register(URL_VALIDATION)}
            minWidth={minWidthTextBox}
            noMargin
          />
        </Grid>
        <input ref={register()} type="hidden" name="status_id" value="1" />
      </Grid>
      {checkIfFeatureFlagEnabled(FeatureFlags.ContactsMultiplePhones) && (
        <PhoneNumbersForm isEditing={isEditing} />
      )}
    </Grid>
  );
};

NameForm.defaultProps = {
  initialValues: {},
  isEditing: false
};

export default NameForm;
