import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useScrollToBottom } from 'features/message-center/hooks';
import isEmpty from 'lodash/isEmpty';
import strings from 'strings';
import LoaderContent from 'UI/components/templates/DrawerContentLayout/LoaderContent';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { UIStatus } from 'UI/constants/status';
import { SMSEmptyState, SvgNoResults } from 'UI/res';

import { getDateLabel } from '../chat.utils';

import { Message } from './Message';
import { AbsoluteContainer, ChatContainer, DateTitle, JumpToLatestButton } from './Message.styled';

const COPIES = strings.messageCenter;

const ChatMessages = ({ messages = [], onClickRetry }) => {
  return (
    <>
      {messages.map(message => (
        <Message
          key={`${message?.messageId}-${message.date}`}
          onClickRetry={() => onClickRetry(message)}
          {...message}
        />
      ))}
    </>
  );
};

export const Chat = ({
  chat,
  chatStatus,
  onClickRetry,
  onFetchMoreMessages,
  triggerScrollToBottom
}) => {
  const isLoading = chatStatus === UIStatus.Loading;

  const { chatContainerRef, chatEndRef, showJumpButton, scrollToBottom } = useScrollToBottom({
    chat,
    triggerScrollToBottom,
    onFetchMoreMessages
  });

  if (chatStatus === UIStatus.Error)
    return (
      <EmptyPlaceholder
        title={strings.shared.errors.serverError.title}
        subtitle={strings.shared.errors.serverError.subtitle}
        customEmptyState={<SvgNoResults height={400} />}
      />
    );

  if (!isLoading && isEmpty(chat)) {
    return (
      <EmptyPlaceholder
        title={COPIES.emptyState.title}
        subtitle={COPIES.emptyState.subtitle}
        customEmptyState={<SMSEmptyState height={400} />}
      />
    );
  }

  return (
    <>
      <ChatContainer ref={chatContainerRef}>
        {isLoading && (
          <AbsoluteContainer>
            <LoaderContent color="primary" height={100} />
          </AbsoluteContainer>
        )}

        {Object.keys(chat).map(day => (
          <Fragment key={day}>
            <DateTitle variant="body2">{getDateLabel(day)}</DateTitle>
            <ChatMessages messages={chat[day]} onClickRetry={onClickRetry} />
          </Fragment>
        ))}
        <div ref={chatEndRef} />
      </ChatContainer>

      <JumpToLatestButton
        endIcon={<ArrowDownwardIcon />}
        onClick={scrollToBottom}
        showJumpButton={showJumpButton}
        variant="contained"
      >
        <Typography variant="body2">{COPIES.chat.goToLastMessage}</Typography>
      </JumpToLatestButton>
    </>
  );
};
