import { makeStyles } from '@material-ui/core/styles';
import { container, navBarHeight } from 'UI/constants/dimensions';
import { colors } from 'UI/res';

export const useStyles = makeStyles({
  conversationContainer: {
    backgroundColor: colors.sideBar,
    height: `calc(100vh - ${navBarHeight}px)`,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  chatContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    position: 'relative'
  },
  messageComposerContainer: {
    boxShadow: container.boxShadow,
    position: 'sticky',
    bottom: 0
  }
});
