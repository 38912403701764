import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Refresh } from '@material-ui/icons';
import clsx from 'clsx';
import { RefreshButton } from 'features/command-center/components/layout/Layout/components/Header/Header.styled';
import { Toolbar } from 'features/command-center/components/shared';
import { StatsCard } from 'features/command-center/components/shared/StatsCard';
import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from 'features/command-center/constants';
import useDatatable, { getColumnPreferences, getColumnsToRender } from 'hooks/datatable';
import usePageTitle from 'hooks/usePageTitle';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import FPIcon from 'UI/components/atoms/FPIcon';
import DataTable from 'UI/components/organisms/DataTable';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { useFixedDatatableStyles, useTableCard } from 'UI/globalStyles/DataTableStyles';
import { SvgInformationCircle } from 'UI/res';

import {
  COLUMNS,
  extraFilters,
  FiltersGroups,
  METRICS_DESCRIPTION,
  ORDER_BY_OPTIONS,
  PAGE_KEY
} from './DailyPerformance.constants';
import { ActionContent, useStyles } from './DailyPerformance.styles';
import { formatResponse } from './DailyPerformance.utils';

const COPIES = strings.commandCenter.dailyPerformance;

export const DailyPerformance = () => {
  const classes = useStyles();
  const fixedTableClasses = useFixedDatatableStyles();
  const tableClasses = useTableCard();

  const initialPreferences = getColumnPreferences(PAGE_KEY, 0, ORDER_BY_OPTIONS, COLUMNS);

  const {
    columnPreferences,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnOrderChange,
    handleColumnSortChange,
    handleDateChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handlePageChange,
    handlePeriodChange,
    handlePerPageChange,
    listState
  } = useDatatable({
    apiInstance: SECONDARY_API_CLIENT,
    columnsDefinitions: COLUMNS,
    endpoint: `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.productivityRecruiterPerformance}`,
    formatResponse,
    initialPreferences,
    key: PAGE_KEY,
    orderByOptions: ORDER_BY_OPTIONS,
    periodDefaultValue: null,
    sendDateWithTimezone: true,
    shouldScrollOnNavigate: true,
    dateParamName: 'cutoffDate'
  });

  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword } = listState;
  const { columns, columnOrder, orderBy, direction } = columnPreferences;
  const finalColumns = getColumnsToRender(columns);

  usePageTitle({ title: PageTitles.CommandCenterDailyPerformance, isLoading });

  const footer = (
    <div className={classes.footer}>
      <div className={classes.footerTitle}>
        <FPIcon
          role="img"
          aria-label="Info"
          component={SvgInformationCircle}
          onSetColor={palette => palette.info.main}
          size={18}
        />
        <Typography className={classes.label}>
          {COPIES.overallPerformanceReport.footer.title}
        </Typography>
      </div>
      <ul className={classes.list}>
        {METRICS_DESCRIPTION.map(item => (
          <li key={item.title}>
            <Typography className={classes.label}>
              <span className={classes.title}>{item.title} -</span> {item.content}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <FiltersLayout
      defaultRange={null}
      disableDateRange
      enableDatePicker
      enableStore={false}
      extraFiltersDefinition={extraFilters}
      extraSelector={
        <ActionContent>
          <RefreshButton
            type="button"
            startIcon={<Refresh width={20} height={20} />}
            onClick={getData}
          >
            Refresh Stats
          </RefreshButton>
        </ActionContent>
      }
      filters={filters}
      groups={FiltersGroups}
      isLoading={showWholeSkeleton}
      isSideMenuOpen={isSideMenuOpen}
      onDateChange={handleDateChange}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      onPeriodChange={handlePeriodChange}
      onSearch={handleFiltersApply}
      title={COPIES.title}
      titleLabelProps={backNavigateListConfig}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StatsCard
            header={COPIES.overallPerformanceReport.title}
            footer={footer}
            paddingContent={0}
          >
            <DataTable
              classes={{ responsiveBase: 'responsiveBase' }}
              className={clsx(tableClasses.tableRoot, fixedTableClasses.fixedList)}
              columnOrder={columnOrder?.length && columnOrder}
              columns={finalColumns}
              components={{ TableToolbar: Toolbar }}
              count={count ?? 0}
              data={data}
              draggableColumns={{
                enabled: true
              }}
              elevation={0}
              filter={false}
              loading={showWholeSkeleton}
              onColumnDisplayClick={handleColumnDisplayChange}
              onColumnOrderChange={handleColumnOrderChange}
              onColumnSortChange={handleColumnSortChange}
              onPageClick={handlePageChange}
              onPerPageClick={handlePerPageChange}
              onSearchTextChange={handleKeywordChange}
              onToggleFilters={handleFiltersToggle}
              page={page}
              refreshing={isLoading}
              rowsPerPage={perPage}
              searchText={keyword}
              selectableRows="none"
              selectToolbarPlacement="none"
              sortOrder={{ name: orderBy, direction }}
            />
          </StatsCard>
        </Grid>
      </Grid>
    </FiltersLayout>
  );
};
