import { parsePhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { DateFormats } from 'UI/constants/defaults';
import { localTimeFormatter } from 'UI/utils';

export const getInternalNumber = user => {
  if (!user?.personalInformation?.contact?.phone) return null;

  return `+1${user?.personalInformation?.contact?.phone}`;
};

export const getDatePart = dateString => localTimeFormatter(dateString, DateFormats.SimpleDate);

export const getDateLabel = date => {
  const day = moment(date, DateFormats.SimpleDate);

  if (!day.isValid()) {
    return 'Invalid Date';
  }

  const now = moment().startOf('day');
  const yesterday = now.clone().subtract(1, 'days');

  if (day.isSame(now, 'day')) {
    return 'Today';
  }

  if (day.isSame(yesterday, 'day')) {
    return 'Yesterday';
  }

  return day.format(DateFormats.SimpleDate);
};

const sortMessagesByDate = messages => {
  if (!Array.isArray(messages)) return [];

  return [...messages].sort((a, b) => new Date(a.date) - new Date(b.date));
};

export const groupMessagesByDate = messages => {
  if (!Array.isArray(messages)) return {};

  const sortedMessages = sortMessagesByDate(messages);

  const result = sortedMessages.reduce((acc, message) => {
    const day = getDatePart(message.date);
    if (!acc[day]) acc[day] = [];
    acc[day].push(message);
    return acc;
  }, {});

  return result;
};

export const mergeMessages = ({ originalChat, messages }) => {
  if (!Array.isArray(messages)) return originalChat;

  const mergedChat = messages.reduce(
    (acc, message) => {
      const day = getDatePart(message.date);
      acc[day] = acc[day] || originalChat[day] || [];
      acc[day].push(message);
      return acc;
    },
    { ...originalChat }
  );

  Object.keys(mergedChat).forEach(day => {
    mergedChat[day] = sortMessagesByDate(mergedChat[day]);
  });

  const sortedMergedChat = Object.keys(mergedChat)
    .sort((a, b) => new Date(a) - new Date(b))
    .reduce((acc, day) => {
      acc[day] = mergedChat[day];
      return acc;
    }, {});

  return sortedMergedChat;
};

export const addMessageToChat = (chat, message) => {
  const { day = null } = message;

  if (!day) return chat;

  return {
    ...chat,
    [day]: [...(chat[day] ?? []), message]
  };
};

export const updateMessageStatus = ({ chat, messageToUpdate, status, error }) => {
  const { day } = messageToUpdate;

  const updatedMessages = {
    ...chat,
    [day]: chat[day].map(message =>
      message.id === messageToUpdate.id
        ? {
            ...message,
            error,
            status
          }
        : message
    )
  };

  return updatedMessages;
};

export const formatInternationalPhone = number => {
  if (!number) return number;

  try {
    const parsed = parsePhoneNumber(number);
    return parsed.formatInternational();
  } catch (error) {
    return number;
  }
};
