import { COMMAND_CENTER_BASE_URL, ENDPOINTS } from 'features/command-center/constants';
import API, { SECONDARY_API_CLIENT } from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';
import { formatNumber } from 'UI/utils';

import { createQueryParams } from './utils';

export const getActivitySummary = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activitySummary}?${queryParams}`
  ).then(({ data }) => data);
};

export const getActivityHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getInventorySummaryByType = async (params = {}, type, version = 1) => {
  const queryParams = createQueryParams(params);
  const url =
    `${COMMAND_CENTER_BASE_URL}/v${version}/${ENDPOINTS.inventorySummaryByType}?${queryParams}`.replace(
      ':type',
      type
    );
  return SECONDARY_API_CLIENT.get(url).then(({ data }) => data);
};

export const getWeekActivity = async ({ groupBy = 'team', recruiter, team }) => {
  const defaultParams = {
    groupBy,
    metrics: 'inMails,bulks,peopleReached,posts,calls,phoneTime',
    recruiter,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityWeek}?${queryParams}`
  );
};

export const getYTDActivityPlacementsSummary = async ({
  groupBy = 'team',
  recruiter,
  team,
  version
}) => {
  const defaultParams = {
    groupBy,
    metrics: 'placements,fallOffs,placedRatio,avgFee',
    recruiter,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${version ? `v${version}/` : ''}${
      ENDPOINTS.activityPlacementsSummary
    }?${queryParams}`
  );
};

export const getYTDSendoutsAndSignedAgreementsSummary = async ({
  recruiter,
  team,
  startDate,
  endDate,
  version = 1
}) => {
  const defaultParams = {
    metrics: 'sendouts,signedFeeAgreements',
    startDate,
    endDate,
    recruiter,
    team
  };

  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${version ? `v${version}/` : ''}${
      ENDPOINTS.productivityTotals
    }?${queryParams}`
  );
};

export const getActivityPlacementsHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activityPlacementsHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getActivitySendoutsHistory = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/${ENDPOINTS.activitySendoutsHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getActivityWeekInventoryGrowth = async (params = {}) => {
  const queryParams = createQueryParams(params);
  return API.get(`${Endpoints.Dashboard}/totalInventory?${queryParams}`).then(({ data }) =>
    data.map(item => ({ title: item.title, value: formatNumber(item.total) }))
  );
};

export const getActiveInventory = async (params = {}, version = 1) => {
  const { metrics } = params;

  const defaultMetrics =
    'candidatesTotal,candidatesAlpha,candidatesPOEJO,candidatesCantHelp,jobOrdersTotal,jobOrdersPOEJO,jobOrdersSearchAssignment,jobOrdersCantHelp,namesTotal,candidateProspect,hiringAuthorityProspect,undefinedProspect,companiesTotal,companiesSigned,companiesClient,companiesNotSigned,hAuthoritiesTotal,hAuthoritiesSigned,hAuthoritiesClient,hAuthoritiesNotSigned';
  const defaultParams = {
    ...params,
    metrics: metrics ?? defaultMetrics
  };
  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/v${version}/${ENDPOINTS.inventorySummary}?${queryParams}`
  ).then(({ data }) => data);
};

export const getCallTrendsHistory = async (params = {}, version = 1) => {
  const queryParams = createQueryParams(params);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/v${version}/${ENDPOINTS.activityPhoneCallsHistory}?${queryParams}`
  ).then(({ data }) => data);
};

export const getPilById = async (params = {}) => {
  const { recruiter, ...rest } = params;
  const queryParams = createQueryParams(rest);
  return API.get(`${Endpoints.Digs}/by-employee-number/${recruiter}?${queryParams}`).then(
    ({ data }) => data
  );
};

export const getCallsSummary = async (params = {}, version = 1) => {
  const defaultParams = {
    callGroup: 'connected',
    summaryType: 'day',
    countType: 'byCall',
    ...params
  };
  const queryParams = createQueryParams(defaultParams);
  return SECONDARY_API_CLIENT.get(
    `${COMMAND_CENTER_BASE_URL}/v${version}/${ENDPOINTS.activityPhoneCallsSummary}?${queryParams}`
  ).then(({ data }) => data);
};
