import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showAlert } from 'actions/app';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import useFetchCatalogs from 'hooks/useFetchCatalogs';
import debounce from 'lodash/debounce';
import { useUserContext } from 'providers/UserProvider';
import { getCancelToken } from 'services/API';
import { canUserOrStaffEditEntity, Permissions } from 'services/Authorization';
import strings from 'strings';
import { FormSchema, PaymentMode } from 'UI/constants/entityTypes';
import { UIStatus } from 'UI/constants/status';
import {
  DATA_SHEET_QUESTIONS_PATHS,
  DATA_SHEET_QUESTIONS_PATHS_REQUIRED
} from 'UI/pages/EditDataSheet/EditDataSheet.constants';
import {
  addFeePercent,
  canChangeFeeAgreementField,
  createDataSheetFormData,
  formatDataSheetSchema,
  formatDataSheetUiSchema,
  removeFeePercentFromSchema
} from 'UI/pages/EditDataSheet/EditDataSheet.utils';
import { getErrorMessage } from 'UI/utils';

import {
  getAssignmentSheetById,
  saveAssignmentSheet,
  saveAssignmentSheetDraft
} from '../AssignmentSheetDrawer.services';
import {
  addFeeAgreementValuesToFormData,
  convertDraftToWhiteSheetFormat,
  formatDataSheetDataForAPI,
  getDataSheetCount,
  sanitizeFormData
} from '../AssignmentSheetDrawer.utils';

import useAssignmentSheetSchema from './useAssignmentSheetSchema';

const { uiMessages, title } = strings.candidates.editDataSheet;

const MILLISECONDS_TO_WAIT = 400;

const useAssignmentSheet = ({
  assignmentSheetId,
  companyId,
  whiteSheet,
  onSaveAssignmentSheet,
  onClose
}) => {
  const saveButtonRef = useRef(null);
  const [user] = useUserContext();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const cancelToken = useRef(null);
  const [formData, setFormData] = useState(null);
  const formDataRef = useRef(null);
  const updatesCountRef = useRef(0);

  const fetchAssignmentSheetById = useCallback(
    () => getAssignmentSheetById(assignmentSheetId),
    [assignmentSheetId]
  );

  const {
    state: { results: assignmentSheetData, error: errorData, status: statusAssignmentSheetData }
  } = useFetchWithStatusV2({
    promise: !formData && assignmentSheetId && fetchAssignmentSheetById,
    initialStatus: !formData && assignmentSheetId ? UIStatus.Loading : UIStatus.Success
  });

  const { catalogs, uiState: catalogsStatus } = useFetchCatalogs({ companyId });

  const [schemaState] = useAssignmentSheetSchema();
  const isFetching =
    statusAssignmentSheetData === UIStatus.Loading ||
    catalogsStatus === UIStatus.Loading ||
    schemaState.isFetching;

  useEffect(() => {
    const processData = async () => {
      if (isFetching) return;
      if (!errorData && assignmentSheetData?.schema.key) {
        const sanitizedData = sanitizeFormData(assignmentSheetData.data);
        const finalData = addFeeAgreementValuesToFormData(sanitizedData);
        formDataRef.current = finalData;
        setFormData(finalData);
        return;
      }

      if (whiteSheet && Object.keys(whiteSheet).length > 0) {
        const whiteSheetVersion = convertDraftToWhiteSheetFormat(whiteSheet);

        setFormData(prevFormData => {
          const finalData = createDataSheetFormData(
            prevFormData,
            whiteSheetVersion,
            catalogs?.feeAgreements.data.length > 0
          );
          formDataRef.current = finalData;
          return finalData;
        });

        return;
      }

      setFormData(prevFormData => {
        const sanitizedData = sanitizeFormData(prevFormData);
        const finalData = addFeeAgreementValuesToFormData(sanitizedData, {
          feeAgreements: catalogs?.feeAgreements?.data
        });
        formDataRef.current = finalData;
        return finalData;
      });
    };

    processData();
  }, [assignmentSheetData, catalogs, whiteSheet, errorData, isFetching]);

  const finalUiSchema = useMemo(() => {
    if (!schemaState.data?.ui) return null;
    const isThereFeeAgreement = !!formData?.section1?.feeAgreement;
    const canUserEdit = canUserOrStaffEditEntity(user, null);
    const dataSheet = {
      id: assignmentSheetData?.id
    };
    const canModifyFeeGuarantee = canChangeFeeAgreementField(
      canUserEdit,
      dataSheet,
      Permissions.FeeAgreements.ModifyGuarantee
    );

    const canModifyFeePercentage = canChangeFeeAgreementField(
      canUserEdit,
      dataSheet,
      Permissions.FeeAgreements.ModifyPercentage
    );

    const formattedUiSchema = formatDataSheetUiSchema(schemaState.data.ui, {
      canModifyFeeGuarantee,
      canModifyFeePercentage,
      isThereFeeAgreement
    });

    return formattedUiSchema;
  }, [assignmentSheetData, schemaState, user, formData]);

  const finalSchema = useMemo(() => {
    if (!schemaState.data?.structure) return null;
    if (!catalogs?.feeAgreements?.data) return schemaState.data.structure;
    const formattedSchema = formatDataSheetSchema(
      schemaState.data.structure,
      catalogs,
      whiteSheet ?? {}
    );

    const selectedFeeAgreement = catalogs.feeAgreements.data.find(
      feeAgreement => feeAgreement.id === formData?.section1?.feeAgreement
    );

    if (selectedFeeAgreement?.fee_agreement_payment_scheme_id === PaymentMode.Flat) {
      return removeFeePercentFromSchema(formattedSchema);
    }

    const schemaWithPercent = addFeePercent(formattedSchema);

    return schemaWithPercent;
  }, [schemaState, whiteSheet, catalogs, formData]);

  const onSaveClick = () => {
    if (!saveButtonRef.current) return;
    saveButtonRef.current.click();
  };

  const handleSaveDataSheet = useCallback(
    async (form, { shouldDisplayLoading = true, isDraft = false, shouldClose = true }) => {
      const sanitizedFormData = sanitizeFormData(form.formData);
      const finalData = addFeeAgreementValuesToFormData(sanitizedFormData, {
        feeAgreements: catalogs?.feeAgreements?.data
      });
      if (
        (finalData.section1?.feeAgreement !== formDataRef.current?.section1?.feeAgreement &&
          updatesCountRef.current > 0) ||
        !isDraft
      ) {
        setFormData(finalData);
      }

      formDataRef.current = finalData;
      updatesCountRef.current += 1;
      if (updatesCountRef.current === 1) return;

      const progress = getDataSheetCount({
        formData: finalData,
        questionsPaths: DATA_SHEET_QUESTIONS_PATHS,
        schema: finalSchema
      });

      const progressWithRequiredQuestions = getDataSheetCount({
        formData: finalData,
        questionsPaths: DATA_SHEET_QUESTIONS_PATHS_REQUIRED,
        schema: finalSchema
      });

      const data = formatDataSheetDataForAPI({
        ...(assignmentSheetId && { id: assignmentSheetId }),
        formData: finalData,
        schemaName: FormSchema.JobOrderDataSheet,
        user,
        progress,
        schemaVersion: schemaState.data.version
      });

      cancelToken.current && cancelToken.current.cancel(strings.shared.requests.cancelTokenMessage);
      cancelToken.current = getCancelToken();

      try {
        shouldDisplayLoading && setIsSaving(true);

        const response = await (isDraft ? saveAssignmentSheetDraft : saveAssignmentSheet)(data, {
          cancelToken: cancelToken.current.token
        });

        if ([HTTPStatusCodes.Ok, HTTPStatusCodes.Created].includes(response.status)) {
          response.data.id &&
            onSaveAssignmentSheet(response.data.id, progressWithRequiredQuestions);

          const finalMessage =
            progress.answered === progress.total
              ? uiMessages.complete.success
              : uiMessages.draft.success;

          shouldDisplayLoading &&
            dispatch(
              showAlert({
                severity: 'success',
                title: 'Success',
                body: finalMessage
              })
            );

          shouldDisplayLoading && setIsSaving(false);
          shouldClose && onClose();

          return;
        }

        const errorMessage =
          progress.answered === progress.total ? uiMessages.complete.error : uiMessages.draft.error;

        shouldDisplayLoading &&
          dispatch(
            showAlert({
              severity: 'error',
              title,
              body: errorMessage
            })
          );

        shouldDisplayLoading && setIsSaving(false);
      } catch (error) {
        shouldDisplayLoading &&
          dispatch(
            showAlert({
              severity: 'error',
              title,
              body: getErrorMessage(error)
            })
          );

        shouldDisplayLoading && setIsSaving(false);
      }
    },
    [
      assignmentSheetId,
      dispatch,
      schemaState,
      onSaveAssignmentSheet,
      user,
      onClose,
      finalSchema,
      catalogs
    ]
  );

  const debouncedSaveDataSheet = useCallback(debounce(handleSaveDataSheet, MILLISECONDS_TO_WAIT), [
    handleSaveDataSheet
  ]);

  const handleOnChange = useCallback(
    form => {
      debouncedSaveDataSheet(form, {
        shouldDisplayLoading: false,
        isDraft: true,
        shouldClose: false
      });
    },
    [debouncedSaveDataSheet]
  );

  const finalSchemaState = useMemo(() => {
    if (!schemaState.data) return null;
    return {
      ...schemaState,
      data: { ...schemaState.data, ui: finalUiSchema, structure: finalSchema }
    };
  }, [schemaState, finalUiSchema, finalSchema]);

  return [
    {
      saveButtonRef,
      isSaving,
      formData,
      assignmentSheetData,
      isFetchingAssignmentSheetData: isFetching,
      errorAssignmentSheetData: errorData,
      primaryButtonName: assignmentSheetId ? strings.shared.ui.update : strings.shared.ui.save,
      ...finalSchemaState
    },
    {
      onSaveClick,
      save: handleSaveDataSheet,
      handleOnChange
    }
  ];
};

export default useAssignmentSheet;
