import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextBox from 'UI/components/atoms/TextBox';
import { FilterRangeSufix } from 'UI/constants/defaults';

import { useStyles } from './InputRange.styles';

const InputRange = ({ label, valueFrom, valueTo, onChange, name, inputType = 'number' }) => {
  const styles = useStyles();
  const fromKey = `${name}${FilterRangeSufix.From}`;
  const toKey = `${name}${FilterRangeSufix.To}`;

  const range = {
    [fromKey]: valueFrom || '',
    [toKey]: valueTo || ''
  };

  const handleChange = (key, newValue) => {
    onChange(key, newValue);
  };

  return (
    <div aria-label={label} className={styles.container}>
      <FormHelperText>{label}</FormHelperText>

      <div className={styles.rangeContainer}>
        <TextBox
          disableDecimals
          disableNegatives
          inputType={inputType}
          label="From"
          name={fromKey}
          onChange={handleChange}
          value={range[fromKey]}
        />
        <TextBox
          disableDecimals
          disableNegatives
          inputType={inputType}
          label="To"
          name={toKey}
          onChange={handleChange}
          value={range[toKey]}
        />
      </div>
    </div>
  );
};

export default InputRange;
