import { FeeAgreementStatus } from 'UI/constants/status';
import { getInitials, getQuestionsCounts } from 'utils';

import { ANSWER, FEE_AGREEMENT_PERCENT, GUARANTEE_DAYS } from './AssignmentSheetDrawer.constants';

export const getDataSheetCount = ({ formData, questionsPaths, schema }) => {
  if (!formData) {
    return {
      total: 0,
      answered: 0
    };
  }

  let finalQuestionsPaths = { ...questionsPaths };

  if (formData.section2?.coverPosition === ANSWER.Negative) {
    const {
      'section2.coverPositionDetails': _coverPositionDetails,
      'section2.resultsYouHad': _resultsYouHad,
      ...rest
    } = finalQuestionsPaths;
    finalQuestionsPaths = { ...rest };
  }

  if (!('feeAgreement' in schema?.properties?.section1?.properties)) {
    delete finalQuestionsPaths['section1.feeAgreement'];
  }

  return getQuestionsCounts(formData, finalQuestionsPaths);
};

export const formatDataSheetDataForAPI = ({
  id,
  formData,
  schemaName,
  schemaVersion,
  progress,
  user
}) => {
  return {
    ...(id && { id }),
    data: formData,
    schema: {
      key: schemaName,
      version: schemaVersion
    },
    progress: {
      current: progress.answered,
      total: progress.total
    },
    trace: {
      user: {
        id: user.id,
        fullName: user.personalInformation.full_name,
        email: user.email,
        initials: getInitials(user.personalInformation.full_name)
      }
    }
  };
};

export const convertDraftToWhiteSheetFormat = draftData => {
  return {
    ...draftData,
    company_fee_agreement_id: draftData.companyFeeAgreement?.id,
    work_type_option_id: draftData.workTypeOption?.id,
    job_order_type_id: draftData.jobOrderType?.id
  };
};

export const sanitizeFormData = formData => {
  const { section2 = {} } = formData || {};

  if (section2?.coverPosition === ANSWER.NEGATIVE) {
    delete section2.coverPositionDetails;
    delete section2.resultsYouHad;
  }

  return {
    ...formData,
    section2
  };
};

export const addFeeAgreementValuesToFormData = (formDataToModify, { feeAgreements } = {}) => {
  const { section1 = {} } = formDataToModify || {};

  const signedAgreements = feeAgreements?.some(
    ({ fee_agreement_status_id: statusId }) => statusId === FeeAgreementStatus.Signed
  );

  const getResponseValue = value => {
    if (signedAgreements) {
      return ANSWER.Positive;
    }

    if ([ANSWER.Positive, ANSWER.Negative].includes(value)) {
      return value;
    }

    return ANSWER.Negative;
  };

  const getFeeAgreement = value => {
    if (value) return value;

    if (feeAgreements?.length > 0) {
      return feeAgreements[0].id;
    }

    return value;
  };

  const feeAgreementId = getFeeAgreement(section1.feeAgreement);
  const feeAgreement = feeAgreementId && feeAgreements?.find(({ id }) => id === feeAgreementId);

  return {
    ...formDataToModify,
    section1: {
      ...formDataToModify?.section1,
      feePercent: String(
        feeAgreement?.fee_percentage ?? section1.feePercent ?? FEE_AGREEMENT_PERCENT ?? ''
      ),
      feeGuaranteeDays: String(
        feeAgreement?.guarantee_days ?? section1.feeGuaranteeDays ?? GUARANTEE_DAYS ?? ''
      ),
      signedFeeAgreement: getResponseValue(section1.signedFeeAgreement),
      preparedToSign: section1.feeAgreement
        ? ANSWER.Positive
        : getResponseValue(section1.preparedToSign),
      feeAgreement: getFeeAgreement(section1.feeAgreement)
    }
  };
};
