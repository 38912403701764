import { makeStyles, styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const useDrawerStyles = makeStyles(theme => ({
  drawerLarge: {
    overflowY: 'hidden',
    width: 900,
    [theme.breakpoints.down(1024)]: {
      width: '70%'
    }
  },
  drawerContent: {
    padding: 0,
    flexGrow: 1,
    overflowY: 'auto'
  }
}));

export const ButtonAsLink = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  height: '100%',
  cursor: 'pointer',
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'inherit'
  }
}));

export const LinkContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center'
});
