import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InlineFilters } from 'features/command-center';
import { ENDPOINTS } from 'features/command-center/constants';

import { TABLE_CONFIG_BY_ENTITY_MAP } from './ActivityTable.constants';

export const ActivityTable = ({ entity, recruiterId, renderTable }) => {
  const [filters, setFilters] = useState({});

  const entityConfig = useMemo(() => TABLE_CONFIG_BY_ENTITY_MAP[entity], [entity]);

  const handleFiltersChange = useCallback(newFilters => {
    setFilters(newFilters);
  }, []);

  const endpoint = useMemo(
    () => `${ENDPOINTS.recruitersActivity}?entityType=${entity}`.replace(':userId', recruiterId),
    [entity, recruiterId]
  );

  return (
    <>
      <Grid item xs={12}>
        <InlineFilters
          persistenceKey={entityConfig.persistenceKey}
          filters={entityConfig.filtersConfig}
          onFiltersChange={handleFiltersChange}
        />
      </Grid>
      <Grid item xs={12}>
        {renderTable({ entityConfig, endpoint, filters, entity })}
      </Grid>
    </>
  );
};
