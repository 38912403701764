import { makeStyles } from '@material-ui/core/styles';
import { flexAlignCenterSpaceBetween } from 'UI/utils/styles';

const fieldsContainerHeight = 70;

export const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingTop: theme.spacing(2)
  },
  rowContainer: {
    padding: theme.spacing(1.5, 0)
  },
  phoneRow: {
    width: '100%'
  },
  fieldsContainer: {
    display: 'flex',
    width: '100%',
    height: fieldsContainerHeight
  },
  actionRowContainer: {
    ...flexAlignCenterSpaceBetween,
    padding: theme.spacing(1, 0.5),
    '& .Mui-checked': {
      color: theme.palette.primary.main
    }
  },
  radioText: {
    fontSize: theme.typography.fontSize
  },
  deleteRowContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 100,
    justifyContent: 'space-between'
  },
  removeText: {
    textTransform: 'capitalize',
    color: theme.palette.text.primary
  },
  titleContainer: {
    padding: theme.spacing(0, 0, 4, 0)
  }
}));
