import { makeStyles } from '@material-ui/core/styles';

export const useTableStyles = makeStyles(
  theme => {
    const defaultHeaderBackground = theme.palette.grey[50];
    return {
      denseCell: {
        '& .MuiTableCell-root': {
          padding: theme.spacing(1.5, 1)
        }
      },
      tableRoot: {
        '& .MuiTableHead-root': {
          backgroundColor: 'red'
        },
        '& .MuiTableHead-root .MuiTableCell-head': {
          backgroundColor: defaultHeaderBackground
        }
      }
    };
  },
  { classNamePrefix: 'collectionsList' }
);

export const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom: theme.spacing(1),
    whiteSpace: 'pre-wrap'
  }
}));
