import { INLINE_FILTERS_KEYS } from 'features/command-center/constants';
import {
  CandidateEntity,
  CompanyEntity,
  EntityType,
  HiringAuthorityEntity
} from 'UI/constants/entityTypes';

import { formatSeconds } from '../activity.utils';

import { CANDIDATE_COLUMNS } from './columns/candidates';
import { COMPANIES_COLUMNS } from './columns/companies';
import { HIRING_AUTHORITIES_COLUMNS } from './columns/hiringAuthorities';
import { NOT_REGISTERED_CALLS_COLUMNS } from './columns/notRegisteredCalls';
import { NAMES_COLUMNS } from './columns/prospects';
import { formatResponse } from './ActivityTable.utils';

export const ORDER_BY_OPTIONS = {
  column: 'createdAt',
  direction: 'desc'
};

export const NotRegisteredCallEntity = 'notRegisteredCall';

export const TABLE_CONFIG_BY_ENTITY_MAP = {
  [EntityType.Candidate]: {
    title: CandidateEntity.title,
    columns: CANDIDATE_COLUMNS,
    orderBy: ORDER_BY_OPTIONS,
    formatResponse: response =>
      formatResponse(response, {
        id: 'candidateId',
        name: 'candidateName',
        optedOut: 'isCandidateOptedOut'
      }),
    persistenceKey: INLINE_FILTERS_KEYS.activity.recruiterCandidates,
    filtersConfig: ['candidateTypes', 'candidateStatuses'],
    entityIdKey: 'candidateId'
  },
  [EntityType.Company]: {
    title: CompanyEntity.title,
    columns: COMPANIES_COLUMNS,
    orderBy: ORDER_BY_OPTIONS,
    formatResponse: response =>
      formatResponse(response, {
        id: 'companyId',
        name: 'companyName',
        optedOut: null
      }),
    persistenceKey: INLINE_FILTERS_KEYS.activity.recruiterCompanies,
    filtersConfig: ['companyTypes', 'numberOfContacts'],
    entityIdKey: 'companyId'
  },
  [EntityType.HiringAuthority]: {
    title: HiringAuthorityEntity.title,
    columns: HIRING_AUTHORITIES_COLUMNS,
    orderBy: ORDER_BY_OPTIONS,
    formatResponse: response =>
      formatResponse(response, {
        id: 'hiringAuthorityId',
        name: 'hiringAuthorityName',
        optedOut: 'isHiringAuthorityOptedOut'
      }),
    persistenceKey: INLINE_FILTERS_KEYS.activity.recruiterHiringAuthorities,
    filtersConfig: ['hiringAuthorityStatuses'],
    entityIdKey: 'hiringAuthorityId'
  },
  [EntityType.Contact]: {
    title: 'Prospects',
    columns: NAMES_COLUMNS,
    orderBy: ORDER_BY_OPTIONS,
    formatResponse: response =>
      formatResponse(response, {
        id: 'nameId',
        name: 'nameName',
        optedOut: 'isNameOptedOut'
      }),
    persistenceKey: INLINE_FILTERS_KEYS.activity.recruiterProspects,
    filtersConfig: ['prospectStatuses'],
    entityIdKey: 'nameId'
  },
  [NotRegisteredCallEntity]: {
    title: 'Not Registered Calls',
    columns: NOT_REGISTERED_CALLS_COLUMNS,
    orderBy: ORDER_BY_OPTIONS,
    formatResponse: response => {
      const formattedData = response.data.data.map(item => ({
        ...item,
        activityLogId: item.id,
        activityType: 'Call',
        callDuration: formatSeconds(item.callDuration),
        activityOutcomeTypeId: item.outcomeTypeId,
        activityOutcomeComments: item.comments,
        activityOutcome: item.activityOutcomeTypeTitle
          ? `${item.activityOutcomeTypeTitle} - ${item.activityOutcomeCategoryTitle}`
          : null,
        activityOutcomeObj: {
          typeId: item.activityOutcomeTypeId,
          typeTitle: item.activityOutcomeTypeTitle,
          categoryId: item.activityOutcomeCategoryId,
          categoryTitle: item.activityOutcomeCategoryTitle,
          activityOutcomeId: item.activityOutcomeId
        }
      }));

      return { ...response, data: { ...response.data, data: formattedData } };
    },
    persistenceKey: INLINE_FILTERS_KEYS.activity.notRegisteredCalls,
    filtersConfig: ['callDirections', 'callTypes', 'callDurations'],
    entityIdKey: 'activityLogId'
  }
};

export const ENTITY_URL_MAP = {
  candidate: 'candidates',
  hiringAuthority: 'hiring-authorities',
  company: 'companies',
  name: 'names',
  notRegisteredCall: 'not-registered-calls'
};
