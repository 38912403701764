import React, { useState } from 'react';
import { DRAWER_KEYS, SimplifiedTable } from 'features/command-center/components/shared';
import { COMMAND_CENTER_BASE_URL, TABLES_KEYS } from 'features/command-center/constants';
import { formatInternationalPhone } from 'features/command-center/utils';
import { EntityRoutes } from 'routes/constants';
import { SECONDARY_API_CLIENT } from 'services/API';
import strings from 'strings';
import DrawerContentLayout from 'UI/components/templates/DrawerContentLayout';
import { DataTableLink } from 'UI/utils/renderers';

import { CallsRelatedCompaniesDrawerContent } from '../CallsRelatedCompaniesDrawerContent';
import { CallsRelatedContactsDrawerContent } from '../CallsRelatedContactsDrawerContent';

import { COLUMNS, ORDER_BY_OPTIONS } from './CallsDrawerContent.constants';
import { ButtonAsLink, LinkContainer, useDrawerStyles } from './CallsDrawerContent.styled';
import { formatResponse } from './CallsDrawerContent.utils';

const ContactRoutes = {
  candidate: EntityRoutes.CandidateProfile,
  hiringAuthority: EntityRoutes.HiringAuthorityProfile,
  name: EntityRoutes.ContactProfile,
  company: EntityRoutes.CompanyProfile
};

export const CallsDrawerContent = ({ endpoint = '' }) => {
  const { drawerLarge, drawerContent } = useDrawerStyles();
  const [isRelatedContactsDrawerOpen, setIsRelatedContactsDrawerOpen] = useState(false);
  const [isRelatedCompaniesDrawerOpen, setIsRelatedCompaniesDrawerOpen] = useState(false);
  const [drawerEndpoint, setDrawerEndpoint] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const openDrawer = ({ activeDrawer, endpoint: currentDrawerEndpoint, externalPhoneNumber }) => {
    currentDrawerEndpoint && setDrawerEndpoint(currentDrawerEndpoint);
    externalPhoneNumber && setPhoneNumber(formatInternationalPhone(externalPhoneNumber));
    activeDrawer === DRAWER_KEYS.CallsRelatedContacts
      ? setIsRelatedContactsDrawerOpen(true)
      : setIsRelatedCompaniesDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsRelatedContactsDrawerOpen(false);
    setIsRelatedCompaniesDrawerOpen(false);
    setDrawerEndpoint(null);
  };

  const drawerHandlers = {
    relatedCompanies: ({ url, externalPhoneNumber }) => {
      openDrawer({
        activeDrawer: DRAWER_KEYS.CallsRelatedCompanies,
        endpoint: `${COMMAND_CENTER_BASE_URL}${url}`,
        externalPhoneNumber
      });
    },
    relatedContacts: ({ url, externalPhoneNumber }) => {
      openDrawer({
        activeDrawer: DRAWER_KEYS.CallsRelatedContacts,
        endpoint: `${COMMAND_CENTER_BASE_URL}${url}`,
        externalPhoneNumber
      });
    }
  };

  const extraRenderers = {
    itemLink: value => {
      if (!value) return null;

      const { id, entity, name } = value;
      const route = ContactRoutes?.[entity];

      return id && route ? (
        <DataTableLink id={id} text={name} route={route} enableInTabRedirect />
      ) : (
        name
      );
    },
    contactsDetails: value => {
      if (!value) return null;

      const { total, url, externalPhoneNumber } = value;

      return (
        <LinkContainer>
          {!url || total === 1 ? (
            total
          ) : (
            <ButtonAsLink
              disableRipple
              disableFocusRipple
              onClick={() => drawerHandlers.relatedContacts({ url, externalPhoneNumber })}
            >
              {total}
            </ButtonAsLink>
          )}
        </LinkContainer>
      );
    },
    companiesDetails: value => {
      if (!value) return null;

      const { total, url, externalPhoneNumber } = value;

      return (
        <LinkContainer>
          {!url || total === 1 ? (
            total
          ) : (
            <ButtonAsLink
              disableRipple
              disableFocusRipple
              onClick={() => drawerHandlers.relatedCompanies({ url, externalPhoneNumber })}
            >
              {total}
            </ButtonAsLink>
          )}
        </LinkContainer>
      );
    }
  };

  return (
    <>
      <SimplifiedTable
        apiInstance={SECONDARY_API_CLIENT}
        columns={COLUMNS}
        endpoint={endpoint}
        extraRenderers={extraRenderers}
        formatResponse={formatResponse}
        orderByOptions={ORDER_BY_OPTIONS}
        pageKey={TABLES_KEYS.drawerCalls}
        shouldUseSavedPagination={false}
        showWrapper={false}
      />

      {isRelatedContactsDrawerOpen && (
        <DrawerContentLayout
          title={`${strings.commandCenter.drawers.relatedContacts.title} ${phoneNumber}`}
          footerActionsProps={{
            hidePrimaryButton: true
          }}
          drawerProps={{
            open: isRelatedContactsDrawerOpen,
            classes: { paper: drawerLarge }
          }}
          contentProps={{ className: drawerContent }}
          onClose={handleCloseDrawer}
        >
          <CallsRelatedContactsDrawerContent endpoint={drawerEndpoint} />
        </DrawerContentLayout>
      )}

      {isRelatedCompaniesDrawerOpen && (
        <DrawerContentLayout
          title={`${strings.commandCenter.drawers.relatedCompanies.title} ${phoneNumber}`}
          footerActionsProps={{
            hidePrimaryButton: true
          }}
          drawerProps={{
            open: isRelatedCompaniesDrawerOpen,
            classes: { paper: drawerLarge }
          }}
          contentProps={{ className: drawerContent }}
          onClose={handleCloseDrawer}
        >
          <CallsRelatedCompaniesDrawerContent endpoint={drawerEndpoint} />
        </DrawerContentLayout>
      )}
    </>
  );
};
