// @flow
import { useCallback, useRef, useState } from 'react';
import useDatatable, { getColumnPreferences } from 'hooks/datatable';
import { useFetchWithStatusV2 } from 'hooks/fetchWithStatus';
import { filtersToParams } from 'selectors/app';
import strings from 'strings';
import { Endpoints } from 'UI/constants/endpoints';
import { UIStatus } from 'UI/constants/status';

const useActivitiesListing = ({
  pageKey = '',
  columns,
  formatResponse,
  orderByOptions,
  endpoint,
  initialFilters,
  entity
}) => {
  const [expandedRows, setExpandedRows] = useState([]);

  const hasUserExpandedRows = useRef(false);

  const { state } = useFetchWithStatusV2({
    endpoint: hasUserExpandedRows.current && `${Endpoints.ActivityOutcomes}?entityType=${entity}`
  });

  const outcomeOptions = state.status === UIStatus.Success ? state?.results : [];

  const handleExpansion = (curExpanded, allExpanded) => {
    hasUserExpandedRows.current = true;
    setExpandedRows(allExpanded.map(row => row.index));
  };

  const handleFormCancel = (id, rowIndex) =>
    setExpandedRows(prevState => prevState.filter(row => row !== rowIndex));

  const initialPreferences = getColumnPreferences(pageKey, 0, orderByOptions, columns, 0);

  const paramsExtender = useCallback(
    () => ({
      ...(initialFilters && filtersToParams(initialFilters))
    }),
    [initialFilters]
  );

  const dataTable = useDatatable({
    key: pageKey,
    columnsDefinitions: columns,
    endpoint,
    entityName: strings.collections.title,
    formatResponse,
    initialPreferences,
    numberOfActionColumns: 0,
    orderByOptions,
    shouldScrollOnNavigate: true,
    paramsExtender
  });

  const handleSubmitSucceed = rowIndex => {
    setExpandedRows(prevState => prevState.filter(row => row !== rowIndex));
    dataTable.getData();
  };

  return {
    dataTable,
    finalData: dataTable.data,
    handleExpansion,
    handleFormCancel,
    handleSubmitSucceed,
    expandedRows,
    outcomeOptions
  };
};

export default useActivitiesListing;
