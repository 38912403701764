import { THEME } from 'GlobalStyles';

const WORKING_HOURS_COLUMN_COLOR = THEME.palette.primary.main;
const NON_WORKING_HOURS_COLUMN_COLOR = THEME.palette.error.main;

export const DEFAULT_OPTIONS = {
  title: 'Calls',
  hAxis: {
    title: 'Time',
    ticks: [
      { v: 0, f: '12 AM' },
      { v: 1, f: '1 AM' },
      { v: 2, f: '2 AM' },
      { v: 3, f: '3 AM' },
      { v: 4, f: '4 AM' },
      { v: 5, f: '5 AM' },
      { v: 6, f: '6 AM' },
      { v: 7, f: '7 AM' },
      { v: 8, f: '8 AM' },
      { v: 9, f: '9 AM' },
      { v: 10, f: '10 AM' },
      { v: 11, f: '11 AM' },
      { v: 12, f: '12 PM' },
      { v: 13, f: '1 PM' },
      { v: 14, f: '2 PM' },
      { v: 15, f: '3 PM' },
      { v: 16, f: '4 PM' },
      { v: 17, f: '5 PM' },
      { v: 18, f: '6 PM' },
      { v: 19, f: '7 PM' },
      { v: 20, f: '8 PM' },
      { v: 21, f: '9 PM' },
      { v: 22, f: '10 PM' },
      { v: 23, f: '11 PM' }
    ]
  },
  vAxis: {
    title: ''
  },
  legend: { position: 'none' },
  seriesType: 'bars',
  tooltip: { isHtml: true },
  series: {
    0: { type: 'bars', color: WORKING_HOURS_COLUMN_COLOR }, // Working hours color
    1: { type: 'bars', color: NON_WORKING_HOURS_COLUMN_COLOR }, // Non-working hours color
    2: {
      type: 'steppedArea',
      color: '#d3e9f3',
      areaOpacity: 0.3,
      visibleInLegend: false,
      enableInteractivity: false
    },
    3: {
      type: 'steppedArea',
      color: '#d3e9f3',
      areaOpacity: 0.3,
      visibleInLegend: false,
      enableInteractivity: false
    }
  },
  isStacked: true,
  chartArea: { width: '85%', height: '70%' }
};

export const fakeFetchData = async () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve([
        { title: '12', value: 0 },
        { title: '1', value: 0 },
        { title: '2', value: 0 },
        { title: '3', value: 0 },
        { title: '4', value: 0 },
        { title: '5', value: 0 },
        { title: '6', value: 4 },
        { title: '7', value: 7 },
        { title: '8', value: 6 },
        { title: '9', value: 8 },
        { title: '10', value: 5 },
        { title: '11', value: 9 },
        { title: '12', value: 10 },
        { title: '1', value: 12 },
        { title: '2', value: 8 },
        { title: '3', value: 7 },
        { title: '4', value: 6 },
        { title: '5', value: 9 },
        { title: '6', value: 4 },
        { title: '7', value: 2 },
        { title: '8', value: 3 },
        { title: '9', value: 0 },
        { title: '10', value: 0 },
        { title: '11', value: 0 }
      ]);
    }, 2000);
  });
};
