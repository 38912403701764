import React from 'react';
import clsx from 'clsx';
import { useGlobalStyles } from 'GlobalStyles';
import { TableToolbar } from 'mui-datatables';
import { useTableCard } from 'UI/globalStyles/DataTableStyles';

export const Toolbar = props => {
  const globalClasses = useGlobalStyles();

  const tableClasses = useTableCard();
  return (
    <TableToolbar
      classes={{
        root: clsx(tableClasses.toolbar, globalClasses.tableToolbarCard),
        actions: tableClasses.actions
      }}
      {...props}
    />
  );
};
