import strings from 'strings';

import { CallsRelatedCompaniesDrawerContent } from './CallsRelatedCompaniesDrawerContent';

const { relatedCompanies } = strings.commandCenter.drawers;

export const RelatedCompaniesConfig = {
  title: relatedCompanies.title,
  component: CallsRelatedCompaniesDrawerContent
};
