import strings from 'strings';
import { CompanyEntity } from 'UI/constants/entityTypes';

import { OUTBOUND_DIRECTION } from './CallsDrawerContent.constants';

export const formatResponse = response => {
  const formattedData = response.data.data.map(item => ({
    ...item,
    location: item?.city && item?.stateSlug ? `${item?.city}, ${item?.stateSlug}` : '',
    externalPhoneNumber: item?.direction === OUTBOUND_DIRECTION ? item?.to : item?.from,
    startTime: {
      date: item?.startTime,
      offset: item?.timezoneOffset,
      timezone: item?.timezoneName
    },
    startTimeLocal: item?.startTime,
    totalContacts: {
      total: item?.totalContacts ?? 0,
      url: item?.contactsUrl ?? null,
      externalPhoneNumber: item?.direction === OUTBOUND_DIRECTION ? item?.to : item?.from
    },
    totalCompanies: {
      total: item?.totalCompanies ?? 0,
      url: item?.companiesUrl ?? null,
      externalPhoneNumber: item?.direction === OUTBOUND_DIRECTION ? item?.to : item?.from
    },
    relatedContacts: item?.totalContacts
      ? {
          id: item?.totalContacts > 1 ? null : item?.firstContactId,
          name:
            item?.totalContacts > 1
              ? strings.commandCenter.activity.calls.severalContacts
              : item?.firstContactName,
          entity: item?.totalContacts > 1 ? null : item?.firstContactEntity
        }
      : null,
    relatedCompanies: item?.totalCompanies
      ? {
          id: item?.totalCompanies > 1 ? null : item?.firstCompanyId,
          name:
            item?.totalCompanies > 1
              ? strings.commandCenter.activity.calls.severalCompanies
              : item?.firstCompanyName,
          entity: item?.totalCompanies > 1 ? null : CompanyEntity.id
        }
      : null
  }));

  return { ...response, data: { ...response.data, data: formattedData } };
};
