import { useCallback, useEffect, useRef, useState } from 'react';
import { getConversations, searchConversations } from 'features/message-center';
import { useUserContext } from 'providers/UserProvider';
import { UIStatus } from 'UI/constants/status';
import { getErrorMessage } from 'UI/utils';

import { getInternalNumber } from '../components/messageChat/chat.utils';

import { useNotificationHandler } from './useNotificationHandler';
import { updateConversationList } from './utils';

const CONVERSATIONS_LIMIT = 20;

export const useTextConversations = () => {
  const [user] = useUserContext();
  const lastItem = useRef(null);
  const [conversations, setConversations] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [uiState, setUiState] = useState({
    error: null,
    isSearchContactsDrawerOpen: false,
    isSearching: false,
    status: UIStatus.Default
  });

  const internalNumber = getInternalNumber(user);

  const fetchConversations = useCallback(
    async ({ searchKeyword = '' } = {}) => {
      try {
        setUiState(prev => ({ ...prev, status: UIStatus.Loading }));

        const fetchFunction = searchKeyword ? searchConversations : getConversations;
        const params = {
          limit: CONVERSATIONS_LIMIT,
          internalNumber,
          ...(searchKeyword && { search: searchKeyword })
        };

        const response = await fetchFunction(params);
        lastItem.current = response?.lastItem ?? null;

        if (searchKeyword) {
          setSearchResults(response?.chats ?? []);
        } else {
          setConversations(response?.chats ?? []);
        }
        setUiState(prev => ({ ...prev, status: UIStatus.Success, error: null }));
      } catch (err) {
        const error = getErrorMessage(err);
        setUiState(prev => ({ ...prev, status: UIStatus.Error, error }));
      }
    },
    [internalNumber]
  );

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  const handleUpdateConversation = useCallback(({ id, updates }) => {
    if (!id) return;

    setConversations(prev =>
      updateConversationList({
        conversations: prev,
        id,
        updates
      })
    );
  }, []);

  const handleNewNotification = useCallback(
    incomingMessage => {
      const id = incomingMessage?.conversationId;

      if (!id) return;

      const unreadMessages =
        selectedConversation?.id === incomingMessage.conversationId
          ? 0
          : incomingMessage.unreadMessages;

      handleUpdateConversation({
        id,
        updates: {
          ...incomingMessage,
          id,
          unreadMessages
        }
      });
    },
    [selectedConversation, handleUpdateConversation]
  );

  useNotificationHandler({
    onNewNotification: handleNewNotification
  });

  const handleClickConversation = conversation => {
    setSelectedConversation(conversation);
    setUiState(prev => ({ ...prev, error: null }));
  };

  const handleSearch = async (searchKeyword = '') => {
    setSearchResults([]);
    if (!searchKeyword) {
      setUiState(prev => ({ ...prev, isSearching: false }));
      return;
    }

    setUiState(prev => ({ ...prev, isSearching: true }));
    fetchConversations({ searchKeyword });
  };

  const handleRefresh = async externalNumer => {
    lastItem.current = null;
    setConversations([]);
    setSearchResults([]);
    setUiState(prev => ({ ...prev, isSearching: false }));
    await fetchConversations();

    const updatedSelectedConversation = conversations?.find(
      conversation => conversation.externalNumber === externalNumer
    );
    setSelectedConversation(updatedSelectedConversation || null);
  };

  const handleFetchMoreConversations = async () => {
    if (!lastItem.current || uiState.status === UIStatus.Loading) return;

    try {
      setUiState(prev => ({ ...prev, status: UIStatus.Loading }));

      const newParams = {
        internalNumber,
        lastItemDate: lastItem.current.date,
        lastItemInternalNumber: lastItem.current.internalNumber,
        lastItemExternalNumber: lastItem.current.externalNumber
      };

      const response = await getConversations(newParams);
      lastItem.current = response?.lastItem ?? null;
      setConversations(prev => [...prev, ...(response?.chats ?? [])]);
      setUiState(prev => ({ ...prev, status: UIStatus.Success }));
    } catch (e) {
      setUiState(prev => ({ ...prev, status: UIStatus.Error }));
    }
  };

  const handleCloseSearchContactsDrawer = () => {
    setUiState(prev => ({ ...prev, isSearchContactsDrawerOpen: false }));
  };

  const handleOpenSearchContactsDrawer = () => {
    setUiState(prev => ({ ...prev, isSearchContactsDrawerOpen: true }));
  };

  const displayedConversations = uiState.isSearching ? searchResults : conversations;

  const sortedConversationsByDate = displayedConversations.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return {
    conversations: sortedConversationsByDate,
    handleClickConversation,
    handleCloseSearchContactsDrawer,
    handleFetchMoreConversations,
    handleOpenSearchContactsDrawer,
    handleRefresh,
    handleSearch,
    handleUpdateConversation,
    selectedConversation,
    uiState
  };
};
