// @flow

import { COMMAND_CENTER_BASE_URL } from 'features/command-center/constants';
import { globalStyles } from 'GlobalStyles';
import { Permissions, userHasPermission, userHasSomeRoles } from 'services/Authorization';
import strings from 'strings';
import type { FiltersSection } from 'types/app';
import {
  statusRenderOption,
  statusStartAdornment
} from 'UI/components/molecules/AutocompleteSelect';
import {
  CompanyAssociationsOptions,
  FilterType,
  HideMode,
  TargetCompaniesOptions
} from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, entityTypes, FeeAgreementProcessesForFilters } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import {
  countryStateOptionLabel,
  getLastTwelveMonths,
  getLastTwentyFourMonths,
  getLastYear,
  getYearToDate,
  hasFeatureFlag,
  relocationsGetOptionLabel,
  relocationsGroupBy,
  valueOptionLabel,
  valueOptionSelected
} from 'UI/utils';
import { Groupers, LabelRenderers, OptionRenderers, Selectors } from 'UI/utils/renderers';

export type FilterDefinition = {
  apiVersion?: number,
  customStyle?: any,
  dependent?: string,
  disableClearable?: boolean,
  displayKey?: string,
  errorText?: string,
  groupBy?: any => string,
  hideMode?: 'hidden' | 'unmounted',
  idKey?: string,
  InputProps?: Object,
  isValid?: value => boolean,
  multiple?: boolean,
  name: string,
  options?: any[],
  paramName: string,
  renderOption?: any => any,
  showWhen?: any => boolean,
  startAdornment?: any => any,
  title?: string | ((any, FiltersSection) => string),
  type: string,
  typeahead?: boolean,
  typeaheadLimit?: number,
  typeaheadParams?: any,
  url?: string | (any => string),
  api?: string
};

export type FilterGroup = {
  id: string,
  name: string,
  filters: string[]
};

const StatusEndpoints = {
  [EntityType.Candidate]: Endpoints.CandidateStatuses,
  [EntityType.Joborder]: Endpoints.JobOrderStatuses,
  [EntityType.Placement]: Endpoints.PlacementStatuses,
  [EntityType.Sendouts]: Endpoints.SendoutsStatus
};

const TypeEndpoints = {
  [EntityType.Candidate]: Endpoints.CandidateTypes,
  [EntityType.Joborder]: Endpoints.JobOrderTypes,
  [EntityType.Company]: Endpoints.CompanyTypes,
  [EntityType.Sendouts]: Endpoints.SendoutsTypes
};

const SearchByPlaceholder = {
  [EntityType.Candidate]: 'Name',
  [EntityType.Company]: 'Company',
  [EntityType.Joborder]: 'Title'
};

const getSearchByText = (type: string) => SearchByPlaceholder[type] || 'keyword';

export const RegionalFilterDef = {
  name: 'regional',
  paramName: 'regionalId',
  title: 'Regional Director',
  type: FilterType.Autocomplete,
  displayKey: 'full_name',
  url: `${Endpoints.Users}?role_id=${Roles.RegionalDirector}`
};

export const CoachFilterDef = {
  name: 'coach',
  paramName: 'coachId',
  title: 'Team',
  type: FilterType.Autocomplete,
  displayKey: 'full_name',
  url: `${Endpoints.Users}?role_id=${Roles.Coach}`
};

export const RecruiterFilterDef = {
  name: 'recruiter',
  paramName: 'recruiterId',
  title: 'Recruiter',
  type: FilterType.Autocomplete,
  displayKey: 'full_name',
  url: (filters: any) =>
    filters.coach
      ? `${Endpoints.Recruiters}/byCoach?coachId=${filters.coach.id}`
      : `${Endpoints.Users}?role_id=${Roles.Recruiter}`
};

const collectionsBaseFilterConfig = {
  type: FilterType.Autocomplete,
  getOptionLabel: OptionRenderers.name,
  displayKey: 'fullName',
  api: 'secondary'
};

const hasSuperiorRole = user =>
  userHasSomeRoles(user, [
    Roles.Finance,
    Roles.Operations,
    Roles.ProductionDirector,
    Roles.Leadership
  ]);

export const AllFiltersDefinition: FilterDefinition[] = [
  {
    name: 'entityType',
    paramName: 'entityType',
    title: 'Type',
    type: FilterType.Select,
    options: entityTypes.filter(each => each.isActive),
    disableClearable: true,
    customStyle: globalStyles.inputSpacing
  },
  {
    name: 'keyword',
    paramName: 'keyword',
    title: (filters: any, section: FiltersSection) =>
      `Search by ${section === 'dig' ? 'Recruiter' : getSearchByText(filters.entityType?.id)}`,
    type: FilterType.Search,
    customStyle: globalStyles.inputSpacing
  },
  {
    name: 'industry',
    paramName: 'industryId',
    title: 'Industry',
    type: FilterType.Autocomplete,
    url: Endpoints.Industries
  },
  {
    name: 'specialty',
    paramName: 'specialtyId',
    title: 'Specialty',
    type: FilterType.Autocomplete,
    url: (filters: any) =>
      filters.industry ? `${Endpoints.Specialties}?industryId=${filters.industry.id}` : ''
  },
  {
    name: 'subspecialty',
    paramName: 'subspecialtyId',
    title: 'Subspecialty',
    type: FilterType.Autocomplete,
    url: (filters: any) =>
      filters.specialty ? `${Endpoints.Specialties}/${filters.specialty.id}/subspecialties` : '',
    hideMode: HideMode.Hidden
  },
  {
    name: 'position',
    paramName: 'positionId',
    title: 'Functional title',
    type: FilterType.Autocomplete,
    url: (filters: any) =>
      filters.specialty ? `${Endpoints.Positions}?specialtyId=${filters.specialty.id}` : ''
  },
  {
    name: 'title',
    paramName: 'title',
    title: 'Title',
    type: FilterType.Input
  },
  {
    name: 'status',
    paramName: 'statusId',
    title: (filters: any) => `${filters.entityType?.singular} Status`,
    type: FilterType.Autocomplete,
    url: (filters: any) => filters.entityType && StatusEndpoints[filters.entityType.id],
    showWhen: (filters: any) => filters.entityType && filters.entityType.id !== EntityType.Company,
    hideMode: HideMode.Unmounted,
    renderOption: statusRenderOption,
    startAdornment: statusStartAdornment
  },
  {
    name: 'type',
    paramName: 'typeId',
    title: (filters: any) => `${filters.entityType?.singular} Type`,
    type: FilterType.Autocomplete,
    url: (filters: any) => filters.entityType && TypeEndpoints[filters.entityType.id],
    hideMode: HideMode.Unmounted,
    renderOption: statusRenderOption,
    startAdornment: statusStartAdornment
  },
  RegionalFilterDef,
  CoachFilterDef,
  RecruiterFilterDef,
  {
    name: 'state',
    paramName: 'stateId',
    title: 'Country: State',
    type: FilterType.Autocomplete,
    url: `${Endpoints.States}`,
    groupBy: option => option.country_title,
    getOptionLabel: countryStateOptionLabel,
    renderOption: OptionRenderers.title,
    getOptionSelected: Selectors.byId
  },
  {
    name: 'zip',
    paramName: 'zip',
    title: 'Zip Code',
    type: FilterType.Input,
    dependent: 'radius'
  },
  {
    name: 'radius',
    paramName: 'radius',
    title: 'Radius around the zip in miles',
    type: FilterType.Input,
    showWhen: (filters: any) => !!filters.zip || filters.zips?.length,
    hideMode: HideMode.Unmounted,
    inputType: 'number',
    getOptionLabel: LabelRenderers.radius
  },
  {
    name: 'cityRadius',
    paramName: 'cityRadius',
    title: 'Radius around the city in miles',
    type: FilterType.Input,
    showWhen: (filters: any) => filters.cities?.length,
    hideMode: HideMode.Unmounted,
    inputType: 'number',
    getOptionLabel: LabelRenderers.radius
  },
  {
    name: 'guaranteeDays',
    paramName: 'guarantee_days',
    title: 'Days Under Guarantee',
    type: FilterType.Autocomplete,
    url: Endpoints.GuaranteeDays,
    getOptionLabel: valueOptionLabel,
    getOptionSelected: valueOptionSelected
  },
  {
    name: 'feeStatus',
    paramName: 'status_ids',
    title: 'Status',
    type: FilterType.Autocomplete,
    url: `${Endpoints.FeeAgreement}/${Endpoints.FeeAgreementStatuses}`,
    renderOption: statusRenderOption,
    startAdornment: statusStartAdornment
  },
  {
    name: 'feeProcess',
    paramName: 'signatureProcessTypeId',
    title: 'Process',
    type: FilterType.Select,
    options: FeeAgreementProcessesForFilters
  },
  {
    name: 'feeAgreementPaymentScheme',
    paramName: 'feeAgreementPaymentSchemeIds',
    title: 'Agreement Type',
    type: FilterType.Autocomplete,
    url: Endpoints.FeeAgreementPaymentSchemes,
    getOptionSelected: Selectors.byId,
    getOptionLabel: LabelRenderers.title,
    renderOption: OptionRenderers.title,
    multiple: true,
    showWhen: () => hasFeatureFlag(FeatureFlags.FilterByFeeAgreementPaymentScheme)
  },
  // MULTIPLE FILTERS
  {
    name: 'industries',
    paramName: 'industryIds',
    title: 'Industries',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Industries}?standalone=true`,
    multiple: true
  },
  {
    name: 'openToRelocation',
    paramName: 'openToRelocate',
    title: strings.shared.filters.openToRelocate,
    type: FilterType.Switch,
    outboundTransformer: value => value === strings.shared.filters.openToRelocate,
    showWhen: () => hasFeatureFlag(FeatureFlags.OpenToRelocationFilter)
  },
  {
    name: 'specialties',
    paramName: 'specialtyIds',
    title: 'Specialties',
    type: FilterType.Autocomplete,
    url: Endpoints.Specialties,
    typeahead: true,
    typeaheadLimit: 25,
    groupBy: Groupers.byIndustry,
    getOptionSelected: Selectors.byId,
    getOptionLabel: LabelRenderers.specialty,
    renderOption: OptionRenderers.title,
    multiple: true
  },
  {
    name: 'subspecialties',
    paramName: 'subspecialtyIds',
    title: 'Subspecialties',
    type: FilterType.Autocomplete,
    url: Endpoints.SubspecialtiesSearch,
    typeahead: true,
    typeaheadLimit: 25,
    getOptionSelected: Selectors.byId,
    getOptionLabel: LabelRenderers.subspecialty,
    renderOption: OptionRenderers.subspecialty,
    multiple: true
  },
  {
    name: 'positions',
    paramName: 'positionIds',
    title: 'Functional Titles',
    type: FilterType.Autocomplete,
    url: Endpoints.PositionsSearch,
    typeahead: true,
    typeaheadLimit: 25,
    getOptionSelected: Selectors.byId,
    renderOption: OptionRenderers.title,
    multiple: true
  },
  {
    name: 'regionals',
    paramName: 'regionalDirectorIds',
    title: 'Regional Director',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}?role_id=${Roles.RegionalDirector}`,
    multiple: true
  },
  {
    name: 'coaches',
    paramName: 'coachIds',
    title: 'Teams',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}?role_id=${Roles.Coach}`,
    multiple: true
  },
  {
    name: 'teams',
    paramName: 'teams',
    title: 'Teams',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}?role_id=${Roles.Coach}&extraColumns=external_employee_id`,
    disableListWrap: true,
    multiple: true,
    idKey: 'external_employee_id'
  },
  {
    name: 'recruiters',
    paramName: 'recruiterIds',
    title: 'Recruiters',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}?role_id=${Roles.Recruiter}`,
    multiple: true
  },
  {
    name: 'employeeNumbers',
    paramName: 'recruiters',
    title: 'Recruiters',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}?role_id=${Roles.Recruiter}&extraColumns=external_employee_id`,
    multiple: true,
    idKey: 'external_employee_id'
  },
  {
    name: 'recruitersAndCoaches',
    paramName: 'recruiterIds',
    title: 'Recruiters',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}?role_id=${Roles.Recruiter},${Roles.Coach}`,
    multiple: true
  },
  {
    name: 'countries',
    paramName: 'countryIds',
    title: 'Countries',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Countries}`,
    getOptionSelected: Selectors.byId,
    multiple: true
  },
  {
    name: 'states',
    paramName: 'stateIds',
    title: 'States',
    type: FilterType.Autocomplete,
    url: `${Endpoints.States}`,
    groupBy: Groupers.byCountry,
    getOptionSelected: Selectors.byId,
    multiple: true
  },
  {
    name: 'searchSpecificPILStates',
    paramName: 'searchSpecificPILStates',
    title: strings.shared.filters.searchSpecificPILStates,
    type: FilterType.Switch,
    outboundTransformer: value => value === strings.shared.filters.searchSpecificPILStates,
    showWhen: filters =>
      filters?.states?.length > 0 && hasFeatureFlag(FeatureFlags.SpecificPILLocation)
  },
  {
    name: 'cities',
    paramName: 'cityIds',
    title: 'Cities',
    type: FilterType.Autocomplete,
    url: `${Endpoints.CitiesSearch}`,
    groupBy: relocationsGroupBy,
    getOptionLabel: relocationsGetOptionLabel,
    getOptionSelected: Selectors.byId,
    renderOption: OptionRenderers.title,
    multiple: true,
    typeahead: true,
    typeaheadLimit: 125,
    typeaheadParams: {
      isState: 0
    },
    dependent: 'cityRadius'
  },
  {
    name: 'searchSpecificPILCities',
    paramName: 'searchSpecificPILCities',
    title: strings.shared.filters.searchSpecificPILCities,
    type: FilterType.Switch,
    outboundTransformer: value => value === strings.shared.filters.searchSpecificPILCities,
    showWhen: filters =>
      filters?.cities?.length > 0 && hasFeatureFlag(FeatureFlags.SpecificPILLocation)
  },
  {
    name: 'relocationDestinations',
    paramName: 'relocationCityIds',
    title: 'Relocation Destinations',
    type: FilterType.Autocomplete,
    url: Endpoints.CitiesSearch,
    groupBy: relocationsGroupBy,
    getOptionLabel: relocationsGetOptionLabel,
    getOptionSelected: Selectors.byId,
    renderOption: OptionRenderers.title,
    multiple: true,
    typeahead: true,
    typeaheadLimit: 125
  },
  {
    name: 'statuses',
    paramName: 'statusIds',
    title: (filters: any) => `${filters.entityType?.singular || ''} Status`,
    type: FilterType.Autocomplete,
    url: (filters: any, section: string) =>
      (filters.entityType && StatusEndpoints[filters.entityType.id]) ||
      StatusEndpoints[section] ||
      '',
    hideMode: HideMode.Unmounted,
    renderOption: statusRenderOption,
    multiple: true
  },
  {
    name: 'mapStatuses',
    paramName: 'statusIds',
    title: (filters: any) => `${filters.entityType?.singular || ''} Status`,
    type: FilterType.Autocomplete,
    url: (filters: any, section: string) =>
      (filters.entityType && StatusEndpoints[filters.entityType.id]) ||
      StatusEndpoints[section] ||
      '',
    showWhen: (filters: any) => filters.entityType && filters.entityType.id !== EntityType.Company,
    hideMode: HideMode.Unmounted,
    renderOption: statusRenderOption,
    multiple: true
  },
  {
    name: 'types',
    paramName: 'typeIds',
    title: 'Type',
    type: FilterType.Autocomplete,
    url: (filters: any, section: string) =>
      (filters.entityType && TypeEndpoints[filters.entityType.id]) || TypeEndpoints[section] || '',
    renderOption: statusRenderOption,
    multiple: true
  },
  {
    name: 'zips',
    paramName: 'zips',
    title: 'Zip Codes',
    type: FilterType.Autocomplete,
    typeahead: true,
    typeaheadLimit: 25,
    url: Endpoints.ZipCodes,
    multiple: true,
    renderOption: OptionRenderers.zipLocation,
    getOptionLabel: LabelRenderers.zipLocation,
    dependent: 'radius'
  },
  {
    name: 'candidate',
    paramName: 'candidateId',
    title: 'Candidate',
    type: FilterType.Autocomplete,
    typeahead: true,
    typeaheadLimit: 50,
    typeaheadParams: {
      entityType: entityTypes.find(each => each.id === EntityType.Candidate).id
    },
    url: Endpoints.Search,
    renderOption: OptionRenderers.title
  },
  {
    name: 'hiringAuthoritiesEmail',
    paramName: 'hiringAuthorityEmail',
    title: 'Hiring Authorities',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Search}`,
    typeahead: true,
    typeaheadLimit: 25,
    typeaheadParams: {
      entityType: 'hiringAuthorityOrName'
    },
    displayKey: 'email',
    idKey: 'email'
  },
  {
    name: 'hiringAuthority',
    paramName: 'hiringAuthorityId',
    title: 'Hiring Authority',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Search}`,
    typeahead: true,
    typeaheadLimit: 50,
    typeaheadParams: {
      entityType: 'hiringAuthorityOrName'
    },
    renderOption: OptionRenderers.displayHiringAuthority,
    getOptionLabel: LabelRenderers.hiringAuthority
  },
  {
    name: 'hiringAuthorities',
    paramName: 'hiringAuthorityIds',
    title: 'Hiring Authorities',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Search}`,
    typeahead: true,
    typeaheadLimit: 50,
    typeaheadParams: {
      entityType: 'hiringAuthorityOrName'
    },
    renderOption: OptionRenderers.displayHiringAuthority,
    getOptionLabel: LabelRenderers.hiringAuthority,
    getOptionSelected: Selectors.byId,
    multiple: true
  },
  {
    name: 'company',
    paramName: 'companyId',
    title: 'Company',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Search}`,
    typeahead: true,
    typeaheadLimit: 40,
    typeaheadParams: {
      entityType: 'company'
    },
    displayKey: 'name',
    renderOption: OptionRenderers.globalSearchDefault('name'),
    getOptionLabel: OptionRenderers.name
  },
  {
    name: 'roles',
    paramName: 'roleIds',
    title: 'Role',
    type: FilterType.Autocomplete,
    url: Endpoints.NameRoles,
    multiple: true,
    renderOption: OptionRenderers.title
  },
  {
    name: 'contactTypes',
    paramName: 'typeIds',
    title: 'Type',
    url: Endpoints.NameTypes,
    type: FilterType.Autocomplete,
    renderOption: OptionRenderers.title,
    groupBy: Groupers.byRole,
    getOptionLabel: LabelRenderers.contactStatus,
    multiple: true
  },
  {
    name: 'contactStatuses',
    paramName: 'statusIds',
    title: 'Status',
    multiple: true,
    type: FilterType.Autocomplete,
    groupBy: Groupers.byRole,
    url: Endpoints.NameStatus,
    renderOption: OptionRenderers.title,
    getOptionLabel: LabelRenderers.contactStatus,
    getOptionSelected: Selectors.byId
  },
  {
    name: 'offices',
    paramName: 'offices',
    title: 'Office Location',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Offices}`,
    groupBy: Groupers.byNameType,
    renderOption: OptionRenderers.officeLocation,
    multiple: true,
    idKey: 'address'
  },
  {
    name: 'managers',
    paramName: 'managerIds',
    title: 'Managers',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Managers}`,
    multiple: true
  },
  {
    name: 'companies',
    paramName: 'companyIds',
    title: 'Companies',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Search}`,
    typeahead: true,
    typeaheadLimit: 50,
    typeaheadParams: {
      entityType: 'company'
    },
    displayKey: 'name',
    renderOption: OptionRenderers.globalCompanySearch({
      displayKey: 'name',
      chipBaseClass: 'chip-company-type'
    }),
    multiple: true,
    getOptionSelected: Selectors.byId
  },
  {
    name: 'candidates',
    paramName: 'candidateIds',
    title: 'Candidates',
    type: FilterType.Autocomplete,
    url: `${Endpoints.Search}`,
    typeahead: true,
    typeaheadLimit: 50,
    typeaheadParams: {
      entityType: 'candidate'
    },
    renderOption: OptionRenderers.candidateInSearch,
    getOptionLabel: OptionRenderers.title,
    getOptionSelected: Selectors.byId,
    multiple: true
  },
  {
    name: 'companyTypes',
    paramName: 'companyTypeIds',
    title: 'Company Type',
    type: FilterType.Autocomplete,
    url: Endpoints.CompanyTypes,
    renderOption: OptionRenderers.title,
    multiple: true
  },
  {
    name: 'bulkTypes',
    paramName: 'scopeIds',
    title: 'Bulk Types',
    type: FilterType.Autocomplete,
    url: Endpoints.BulkEmailScope,
    renderOption: OptionRenderers.title,
    multiple: true
  },
  {
    name: 'bulkTemplates',
    paramName: 'templateIds',
    title: 'Bulk Templates',
    type: FilterType.Autocomplete,
    apiVersion: 2,
    typeahead: true,
    typeaheadLimit: 150,
    displayKey: 'name',
    url: Endpoints.BulkEmailsTemplatesSearch,
    getOptionSelected: Selectors.byId,
    getOptionLabel: LabelRenderers.nameOrEmpty,
    renderOption: OptionRenderers.templatesSearch('name'),
    multiple: true
  },
  {
    name: 'addedByIds',
    paramName: 'createdByIds',
    title: 'Added by',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}?role_id=${Roles.Recruiter}`,
    multiple: true
  },
  {
    name: 'isPrivate',
    paramName: 'isPrivate',
    title: 'Visibility',
    type: FilterType.Select,
    options: [
      {
        isPrivate: false,
        title: strings.searchProjects.mainPage.sideFilters.isPrivate.public
      },
      {
        isPrivate: true,
        title: strings.searchProjects.mainPage.sideFilters.isPrivate.private
      }
    ]
  },
  {
    name: 'isMasterList',
    paramName: 'isMasterList',
    title: 'Master List',
    type: FilterType.Select,
    showWhen: () =>
      hasFeatureFlag(FeatureFlags.SearchProjectsMasterListFields) &&
      userHasPermission(Permissions.SearchProjects.CanMarkAsMasterList),
    options: [
      {
        isMasterList: true,
        title: strings.searchProjects.mainPage.sideFilters.isMasterList.show
      },
      {
        isMasterList: false,
        title: strings.searchProjects.mainPage.sideFilters.isMasterList.exclude
      }
    ]
  },
  {
    name: 'searchProjectTypes',
    paramName: 'itemSearchProjectTypes',
    title: 'Item Type',
    type: FilterType.Autocomplete,
    url: Endpoints.SearchProjectsTypes,
    multiple: true
  },
  {
    name: 'sendoutTypes',
    paramName: 'typeId',
    title: 'Type',
    type: FilterType.Autocomplete,
    url: (filters: any, section: string) => TypeEndpoints[section] || '',
    renderOption: statusRenderOption
  },
  {
    name: 'sendoutStatuses',
    paramName: 'statusIds',
    title: 'Status',
    type: FilterType.Autocomplete,
    url: StatusEndpoints[EntityType.Sendouts],
    groupBy: Groupers.bySendoutStatuses,
    renderOption: statusRenderOption,
    getOptionLabel: option => `${option.type?.title || ''} › ${option.title}`,
    getOptionSelected: Selectors.byId,
    multiple: true
  },
  {
    name: 'phone',
    paramName: 'phone',
    title: 'Phone Number',
    displayLabel: 'Phone Number',
    type: FilterType.Input,
    inputType: 'phone'
  },
  {
    name: 'partialPhone',
    paramName: 'partialPhone',
    title: 'Phone Number',
    type: FilterType.Input,
    isValid: value => {
      const regex = /.*\d.*\d.*\d.*\d.*/;
      return value ? regex.test(value) : true;
    },
    errorText: strings.shared.validations.phoneDigits
  },
  {
    name: 'workTypes',
    paramName: 'workTypeIds',
    title: 'Work Preference',
    type: FilterType.Autocomplete,
    url: Endpoints.WorkTypes,
    multiple: true,
    showWhen: ({ entityType }) => entityType?.id !== EntityType.Company
  },
  {
    name: 'textSearch',
    paramName: 'textSearch',
    title: 'Keyword Search',
    placeholder: 'eg. project manager in construction',
    type: FilterType.Input,
    showWhen: () => hasFeatureFlag(FeatureFlags.KeywordSearch)
  },
  {
    name: 'channelPartners',
    paramName: 'channelPartnerIds',
    title: 'Channel Partner',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: Endpoints.Users,
    multiple: true
  },
  {
    name: 'userRoles',
    paramName: 'roleIds',
    title: 'Role',
    type: FilterType.Autocomplete,
    url: Endpoints.Roles,
    multiple: true
  },
  {
    name: 'userStatus',
    paramName: 'statusIds',
    title: 'Status',
    type: FilterType.Autocomplete,
    url: Endpoints.UserStatuses,
    multiple: true
  },
  {
    name: 'timezones',
    paramName: 'timezone',
    title: 'Time Zone',
    displayKey: 'timezone',
    type: FilterType.Autocomplete,
    url: Endpoints.UserTimeZones,
    multiple: true
  },
  {
    name: 'departments',
    paramName: 'departmentIds',
    title: 'Departments',
    displayKey: 'title',
    type: FilterType.Autocomplete,
    url: Endpoints.Departments,
    multiple: true
  },
  {
    name: 'userStates',
    paramName: 'stateIds',
    title: 'Person State',
    type: FilterType.Autocomplete,
    url: `${Endpoints.States}`,
    groupBy: option => option.country_title,
    getOptionLabel: countryStateOptionLabel,
    getOptionSelected: Selectors.byId,
    multiple: true,
    renderOption: OptionRenderers.title
  },
  {
    name: 'userCities',
    paramName: 'cityIds',
    title: 'Person City',
    type: FilterType.Autocomplete,
    url: `${Endpoints.CitiesSearch}`,
    groupBy: relocationsGroupBy,
    getOptionLabel: relocationsGetOptionLabel,
    getOptionSelected: Selectors.byId,
    renderOption: OptionRenderers.title,
    multiple: true,
    typeahead: true,
    typeaheadLimit: 125,
    typeaheadParams: {
      isState: 0
    },
    dependent: 'cityRadius'
  },
  {
    name: 'pilStates',
    paramName: 'pilStateIds',
    title: 'PIL States',
    type: FilterType.Autocomplete,
    url: `${Endpoints.States}`,
    groupBy: option => option.country_title,
    getOptionLabel: countryStateOptionLabel,
    getOptionSelected: Selectors.byId,
    multiple: true,
    renderOption: OptionRenderers.title
  },
  {
    name: 'searchSpecificStates',
    paramName: 'searchSpecificPILStates',
    title: strings.shared.filters.searchSpecificPILStates,
    type: FilterType.Switch,
    outboundTransformer: value => value === strings.shared.filters.searchSpecificPILStates,
    showWhen: filters =>
      filters?.pilStates?.length > 0 && hasFeatureFlag(FeatureFlags.SpecificPILLocation)
  },
  {
    name: 'pilCities',
    paramName: 'pilCityIds',
    title: 'PIL Cities',
    type: FilterType.Autocomplete,
    url: `${Endpoints.CitiesSearch}`,
    groupBy: relocationsGroupBy,
    getOptionLabel: relocationsGetOptionLabel,
    getOptionSelected: Selectors.byId,
    renderOption: OptionRenderers.title,
    multiple: true,
    typeahead: true,
    typeaheadLimit: 125,
    typeaheadParams: {
      isState: 0
    }
  },
  {
    name: 'searchSpecificCities',
    paramName: 'searchSpecificPILCities',
    title: strings.shared.filters.searchSpecificPILCities,
    type: FilterType.Switch,
    outboundTransformer: value => value === strings.shared.filters.searchSpecificPILCities,
    showWhen: filters =>
      filters?.pilCities?.length > 0 && hasFeatureFlag(FeatureFlags.SpecificPILLocation)
  },
  {
    name: 'optOut',
    paramName: 'optedOut',
    title: strings.shared.filters.optOutPlaceHolder,
    type: FilterType.Select,
    options: [
      {
        id: 0,
        title: strings.shared.filters.excludeOptOut
      },
      {
        id: 1,
        title: strings.shared.filters.showOptOut
      }
    ],
    renderOption: OptionRenderers.title,
    showWhen: () => hasFeatureFlag(FeatureFlags.OptOutFilter)
  },
  {
    name: 'validEmail',
    paramName: 'validEmails',
    title: strings.shared.filters.emailValidationPlaceHolder,
    type: FilterType.Select,
    options: [
      {
        id: 0,
        title: strings.shared.filters.invalidEmails
      },
      {
        id: 1,
        title: strings.shared.filters.validEmails
      }
    ],
    renderOption: OptionRenderers.title,
    showWhen: () => hasFeatureFlag(FeatureFlags.EmailValidationColumn)
  },
  {
    name: 'emailVerdicts',
    paramName: 'emailVerdicts',
    title: strings.shared.filters.emailValidationPlaceHolder,
    type: FilterType.Autocomplete,
    apiVersion: 1,
    url: Endpoints.EmailVerdicts,
    idKey: 'verdict',
    multiple: true,
    renderOption: OptionRenderers.title,
    showWhen: () => hasFeatureFlag(FeatureFlags.EmailValidationColumn)
  },
  {
    name: 'personalEmailVerdicts',
    paramName: 'personalEmailVerdicts',
    title: `Personal ${strings.shared.filters.emailValidationPlaceHolder}`,
    type: FilterType.Autocomplete,
    apiVersion: 1,
    url: Endpoints.EmailVerdicts,
    idKey: 'verdict',
    multiple: true,
    renderOption: OptionRenderers.title,
    showWhen: () => hasFeatureFlag(FeatureFlags.PersonalEmailVerdictFilter)
  },
  {
    name: 'referrals',
    paramName: 'referralIds',
    title: 'Channel Partner',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: `${Endpoints.Users}/${Endpoints.Roster}`,
    typeaheadParams: (filters, _, user) => {
      const params = {};
      userHasSomeRoles(user, [Roles.Coach]) && (params.coachIds = user.id);
      userHasSomeRoles(user, [Roles.RegionalDirector]) && (params.regionalDirectorIds = user.id);

      return params;
    },
    typeahead: true,
    typeaheadLimit: 25,
    multiple: false,
    showWhen: (_, user) =>
      userHasSomeRoles(user, [
        Roles.Coach,
        Roles.RegionalDirector,
        Roles.DataCoordinator,
        Roles.Finance,
        Roles.Operations,
        Roles.ProductionDirector,
        Roles.Leadership,
        Roles.Staff
      ])
  },
  {
    name: 'referred',
    paramName: 'refereeIds',
    title: 'Referred Recruiter',
    type: FilterType.Autocomplete,
    displayKey: 'full_name',
    url: Endpoints.Users,
    typeahead: true,
    typeaheadLimit: 25,
    multiple: false
  },
  {
    name: 'candidateSourceTypes',
    paramName: 'candidateSourceTypeIds',
    title: 'Candidate Sources',
    type: FilterType.Autocomplete,
    url: Endpoints.CandidateSourceTypes,
    getOptionSelected: Selectors.byId,
    renderOption: OptionRenderers.title,
    multiple: true
  },
  {
    name: 'jobOrderSourceTypes',
    paramName: 'jobOrderSourceTypeIds',
    title: 'Job Order Sources',
    type: FilterType.Autocomplete,
    url: Endpoints.JobOrderSourceTypes,
    getOptionSelected: Selectors.byId,
    renderOption: OptionRenderers.title,
    multiple: true
  },
  {
    name: 'collectionsCommitToPay',
    paramName: 'commitToPay',
    title: strings.collections.fields.committedToPay,
    type: FilterType.Switch,
    outboundTransformer: value => value === strings.collections.fields.committedToPay
  },
  {
    name: 'collectionsShowTermCoaches',
    paramName: 'showTermCoaches',
    title: strings.collections.filters.terms,
    type: FilterType.Switch,
    outboundTransformer: value => value === strings.collections.filters.terms,
    showWhen: (filters, user) => hasSuperiorRole(user)
  },
  {
    name: 'collectionsShowSearchInSplit',
    paramName: 'searchInSplits',
    title: strings.collections.filters.searchInSplit,
    type: FilterType.Switch,
    outboundTransformer: value => value === strings.collections.filters.searchInSplit,
    showWhen: (filters, user) => {
      const isUserRecruiter = userHasSomeRoles(user, [Roles.Recruiter]);
      const hasTeamFilter = filters?.collectionsCurrentTeam?.length > 0;

      return isUserRecruiter || (hasSuperiorRole(user) && hasTeamFilter);
    }
  },
  {
    name: 'collectionsStatus',
    paramName: 'invoiceType',
    title: strings.collections.filters.status,
    type: FilterType.Autocomplete,
    url: Endpoints.CollectionsInvoiceTypes,
    api: 'secondary'
  },
  {
    name: 'collectionsCurrentTeam',
    paramName: 'teams',
    title: strings.collections.columns.currentCoach,
    url: Endpoints.CollectionsTeams,
    showWhen: (filters, user) => hasSuperiorRole(user),
    multiple: true,
    ...collectionsBaseFilterConfig
  },
  {
    name: 'collectionsTeam',
    paramName: 'teamsOnInvoice',
    title: strings.collections.columns.coach,
    url: Endpoints.CollectionsTeamsOnInvoices,
    showWhen: (filters, user) => hasSuperiorRole(user),
    multiple: true,
    ...collectionsBaseFilterConfig
  },
  {
    name: 'rangeByNumberOfContacts',
    paramName: 'numberOfContacts',
    title: strings.shared.filters.numberOfContacts,
    type: FilterType.Range,
    showWhen: () => hasFeatureFlag(FeatureFlags.FilterByNumberOfContactsRange),
    displayLabel: 'Contacts'
  },
  {
    name: 'rangeByNumberOfEmployees',
    paramName: 'employees',
    title: strings.shared.filters.numberOfEmployees,
    type: FilterType.Range,
    showWhen: () => hasFeatureFlag(FeatureFlags.FilterByNumberOfEmployeesRange),
    displayLabel: 'Employees'
  },
  {
    name: 'rangeByRevenue',
    paramName: 'revenue',
    title: strings.shared.filters.revenue,
    type: FilterType.Range,
    showWhen: () => hasFeatureFlag(FeatureFlags.FilterByRevenueRange),
    displayLabel: 'Revenue'
  },
  {
    name: 'targetCompanies',
    paramName: 'isPriorityTarget',
    title: 'Target Companies',
    type: FilterType.Select,
    options: TargetCompaniesOptions,
    showWhen: () => hasFeatureFlag(FeatureFlags.CompaniesTargetPriority)
  },
  {
    name: 'website',
    paramName: 'website',
    title: 'Website',
    type: FilterType.Input,
    showWhen: () => hasFeatureFlag(FeatureFlags.WebsiteFilter)
  },
  {
    name: 'companyAssociations',
    paramName: 'isHeadQuarter',
    title: 'Company Associations',
    type: FilterType.Select,
    options: CompanyAssociationsOptions,
    showWhen: () => hasFeatureFlag(FeatureFlags.CompaniesHeadquarterColumn)
  },
  {
    name: 'healthStatuses',
    paramName: 'statuses',
    title: 'Health Status',
    type: FilterType.Autocomplete,
    displayKey: 'title',
    api: 'secondary',
    url: `${COMMAND_CENTER_BASE_URL}/catalog/health-statuses`,
    disableListWrap: true,
    multiple: true,
    idKey: 'title'
  },
  {
    name: 'startDate',
    type: 'date',
    paramName: 'startDate',
    title: 'Start Date',
    disableTimeRange: true
  }
];

export const YearToDateRange = {
  label: 'Year to Date',
  range: getYearToDate
};

export const LastYearRange = {
  label: 'Last Year',
  range: getLastYear
};

export const Last12Months = {
  label: 'Last 12 Months',
  range: getLastTwelveMonths
};

export const Last24Months = {
  label: 'Last 24 Months',
  range: getLastTwentyFourMonths
};

/* Creating an array of objects. Each object has a label and a range property. */
export const additionalPeriodRanges = [YearToDateRange, LastYearRange, Last12Months, Last24Months];

export const getFilterDefinitionByName = (name: string) =>
  AllFiltersDefinition.find(item => item.name === name);
