import React from 'react';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import AttachFile from '@material-ui/icons/AttachFile';
import { showAlert } from 'actions/app';
import useFileUpload from 'hooks/fileUpload';
import strings from 'strings';
import FPIconButton from 'UI/components/atoms/FPIconButton';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType } from 'UI/constants/entityTypes';
import { formatBytes } from 'UI/utils';

import { LoaderContainer } from './Message.styled';

const MAX_FILE_SIZE = 1024 * 1024 * 3; // 3 MB

export const AttachmentUploader = ({
  name = 'attachment',
  category = {
    module: EntityType.Contact,
    required: false,
    title: 'Select File'
  },
  acceptFiles = ['image/png', 'image/jpg', 'image/jpeg', '.pdf', '.docx', '.doc'],
  endpoint = Endpoints.Files,
  defaultEntityType = EntityType.Contact,
  onClick,
  disabled
}) => {
  const dispatch = useDispatch();

  const handleUploadStarted = (fieldName, file) => {
    if (file.size >= MAX_FILE_SIZE) {
      dispatch(
        showAlert({
          message: strings.fileManager.sizeFile.title,
          severity: 'error',
          body: strings.formatString(strings.fileManager.sizeFile.message, {
            fileName: file.name,
            maxFileSize: formatBytes(MAX_FILE_SIZE),
            severity: 'error'
          })
        })
      );

      throw new Error(strings.fileManager.sizeFile.title);
    }
  };

  const { fileRef, file, handleFileChange, selectOrUploadFile } = useFileUpload({
    initialValue: null,
    category,
    endpoint,
    fieldName: name,
    onChange: onClick,
    softDeleteForExistingItems: true,
    defaultEntityType,
    onUploadStarted: handleUploadStarted
  });

  if (file?.isUploading)
    return (
      <LoaderContainer>
        <CircularProgress size={26} />
      </LoaderContainer>
    );

  return (
    <>
      <FPIconButton
        disabled={disabled}
        onClick={selectOrUploadFile}
        tooltipProps={{
          title: strings.messageCenter.messageComposer.attachment.tooltip.upload
        }}
      >
        <AttachFile color="primary" />
      </FPIconButton>
      <input
        ref={fileRef}
        type="file"
        accept={acceptFiles.join(',')}
        hidden
        onChange={handleFileChange}
      />
    </>
  );
};
