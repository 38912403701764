import { JSON_SCHEMA_CLIENT } from 'services/API';
import { Endpoints } from 'UI/constants/endpoints';

export const saveDataSheet = async dataSheet => {
  return JSON_SCHEMA_CLIENT.put(`/${Endpoints.DataSheetSaveCompleted}`, dataSheet);
};

export const getDataSheetByJobOrderId = jobOrderId => {
  return JSON_SCHEMA_CLIENT.get(`/${Endpoints.DataSheetByJobOrderId.replace(':id', jobOrderId)}`);
};
