import { THEME } from 'GlobalStyles';

import { createCustomTooltip } from '../../finance/finance.utils';

import { BASE_CHART_COLORS } from './InventoryAdded.constants';

export const calculateVerticalAxisTicks = data => {
  const allValues = data
    .slice(1)
    .flatMap(row =>
      row.slice(1).filter((value, index) => index % 2 === 0 && typeof value === 'number')
    );

  const maxValue = Math.max(...allValues);

  if (maxValue === 0) {
    return [0, 1, 2, 3];
  }

  if (maxValue <= 5) {
    return Array.from({ length: maxValue + 2 }, (_, i) => i);
  }

  const magnitude = 10 ** Math.floor(Math.log10(maxValue));
  let interval = magnitude / 2;

  if (maxValue / interval > 10) {
    interval = magnitude;
  } else if (maxValue / interval <= 5) {
    interval = magnitude / 5;
  }

  const roundedMax = Math.ceil(maxValue / interval) * interval;

  const ticks = [];
  for (let i = 0; i <= roundedMax; i += interval) {
    ticks.push(i);
  }

  return ticks;
};

export const formatInventoryLineChartResponse = (response, config) => {
  if (!response) {
    return [config.headers, ['', ...Array(config.headers.length - 1).fill(0)]];
  }

  const formattedData = [
    [
      config.dateLabel,
      ...config.categories.flatMap(category => [
        category,
        {
          role: 'annotationText',
          type: 'string'
        },
        { role: 'tooltip', type: 'string', p: { html: true } }
      ])
    ]
  ];

  const total = response?.overall ?? response?.total;

  total.forEach((item, index) => {
    const { date_label, date } = item;
    const row = [date_label];

    config.categories.forEach(category => {
      const categoryData = response[config.dataMappings[category]];
      let value = 0;
      let url = '';

      if (categoryData && Array.isArray(categoryData) && categoryData[index]) {
        value = categoryData[index].total || 0;
        url = categoryData[index].url ?? null;
      }

      row.push(value);
      row.push(url ?? '');
      row.push(
        createCustomTooltip({
          title: category,
          value,
          category: date
        })
      );
    });

    formattedData.push(row);
  });

  return formattedData;
};

export const getChartColors = entity => {
  const colorSchemes = {
    joborder: [
      THEME.palette.common.black,
      THEME.palette.success.main,
      THEME.palette.warning.main,
      THEME.palette.error.main,
      THEME.palette.primary.main,
      THEME.palette.warning.main
    ],
    candidate: [
      THEME.palette.common.black,
      THEME.palette.success.main,
      THEME.palette.warning.main,
      THEME.palette.error.main,
      THEME.palette.primary.main,
      THEME.palette.warning.main
    ],
    company: [
      THEME.palette.common.black,
      THEME.palette.success.main,
      THEME.palette.warning.main,
      THEME.palette.primary.main,
      THEME.palette.warning.main,
      THEME.palette.error.main
    ]
  };

  return colorSchemes[entity] || BASE_CHART_COLORS;
};
