import {
  Permissions,
  SearchProjectCollaborationsRoles,
  userHasPermission
} from 'services/Authorization';
import { collabsMapper } from 'UI/components/organisms/SearchProjects/CollaborationsForm';
import { SearchProjectPrivacyScopes } from 'UI/constants/defaults';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { Roles } from 'UI/constants/roles';
import { dateFormatter, hasFeatureFlag } from 'UI/utils';

/**
 * @param {string} date - Date to be formatted
 * @param {function} callback - Exposes date formats in order to avoid
 * importing const where needed
 * @returns Custom Formatted date with fallback in case there's no date
 */
export const getQueryShortFormatDate = (date, callback) => {
  const callbackHelper = callback || (format => format.SimpleDateHyphen);
  return date ? dateFormatter(date, callbackHelper) : '- -';
};

/**
 * @param {Array} currentUserRoles
 * @param {'Owner'|'Collaborator'|'Guest'} [collaborationRole]
 * @returns {Object}
 */
export const getManagementPermissions = (
  currentUserRoles,
  collaborationRole = SearchProjectCollaborationsRoles.Guest
) => {
  const rolesWithFullPermissions = [
    Roles.DataCoordinator,
    Roles.Operations,
    Roles.ProductionDirector,
    Roles.Leadership
  ];
  const hasSomeRoleWithFullPermission = currentUserRoles.some(userRole =>
    rolesWithFullPermissions.includes(userRole.role_id)
  );

  const isOwner = collaborationRole === SearchProjectCollaborationsRoles.Owner;
  const isCollaborator = collaborationRole === SearchProjectCollaborationsRoles.Collaborator;
  const hasFullPermissions = isOwner || hasSomeRoleWithFullPermission;

  const managementPermissions = {
    searchProject: {
      canEdit: hasFullPermissions,
      canSplit: hasFullPermissions || isCollaborator,
      canArchive: hasFullPermissions,
      canViewHistoryLog: hasFullPermissions || isCollaborator
    },
    canManageCollaborations: hasFullPermissions,
    canManageItems: hasFullPermissions || isCollaborator
  };

  return managementPermissions;
};

export const getMappedSearchProjectData = searchProjectData => {
  const mappedData = {
    archivedAt: searchProjectData.archivedAt,
    createdAt: searchProjectData.createdAt,
    daysBeforeDeletion: searchProjectData.daysBeforeDeletion,
    id: searchProjectData.id,
    isArchived: searchProjectData.isArchived,
    isPrivate: searchProjectData.isPrivate,
    lastBulkSentDate: searchProjectData.sentDate,
    name: searchProjectData.name,
    privacyScope: SearchProjectPrivacyScopes.getPrivacyState(searchProjectData.isPrivate),
    totalItems: searchProjectData.totalItems
  };

  if (hasFeatureFlag(FeatureFlags.SearchProjectsFolders)) {
    mappedData.folder = { id: searchProjectData.folder.id, name: searchProjectData.folder.name };
  }

  if (userHasPermission(Permissions.SearchProjects.CanMarkAsMasterList)) {
    mappedData.isMasterList = searchProjectData.isMasterList;
    mappedData.geoPolygon = searchProjectData.geoPolygon;
  }

  return mappedData;
};

export const getFilteredCollabs = (currentUserId, collabs) => {
  const mappedCollaborators = collabs.map(collab =>
    collabsMapper({ ...collab, isCurrentOwner: collab.user_id === currentUserId })
  );
  const owners = mappedCollaborators.filter(({ isOwner }) => isOwner);
  const collaborators = mappedCollaborators.filter(
    ({ detail }) => detail === SearchProjectCollaborationsRoles.Collaborator
  );

  return [...owners, ...collaborators];
};
