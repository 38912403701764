export const NOT_REGISTERED_CALLS_COLUMNS = [
  {
    name: 'activityOutcomeTypeId',
    label: 'Activity Outcome Type Id',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'activityOutcomeObj',
    label: 'Activity Outcome Object',
    options: {
      sort: false,
      display: 'excluded'
    }
  },
  {
    name: 'activityLogId',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'activityType',
    label: 'Type',
    options: {
      sort: true,
      display: 'excluded'
    }
  },
  {
    name: 'date',
    label: 'Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  },
  {
    name: 'to',
    label: 'To',
    options: {
      sort: false,
      display: true,
      renderer: 'phoneLinkRC'
    }
  },
  {
    name: 'callDirection',
    label: 'Direction',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'callType',
    label: 'Call Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'callDuration',
    label: 'Duration',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'activityOutcome',
    label: 'Outcome',
    options: {
      sort: true,
      display: false
    }
  },
  {
    name: 'activityOutcomeComments',
    label: 'Comments',
    options: {
      sort: true,
      display: false
    }
  }
];
