// @flow
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import strings from 'strings';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import { LimitLocalDataOfSelectors } from 'UI/constants/defaults';
import { getGridColumnSize } from 'UI/constants/dimensions';
import { Endpoints } from 'UI/constants/endpoints';
import {
  countryStateOptionLabel,
  idOptionSelected,
  REQUIRED_VALIDATION,
  titleOptionLabel
} from 'UI/utils';

const {
  creationItems: { required, noRequired }
} = strings;

type LocationFormProps = {
  state: any,
  city: any,
  zip: any,
  handleComboChange: () => any,
  isEditing: boolean,
  removeRequiredMark: boolean,
  disabled: boolean
};

export const getCustomPropsForTypeAhead = (value, type) => {
  let isTypeAhead;
  const props = {};
  switch (type) {
    case 'city':
      {
        isTypeAhead = value?.total_cities > LimitLocalDataOfSelectors.city;
        const ternaryCityEndpoint = isTypeAhead
          ? Endpoints.Cities
          : `${Endpoints.Cities}?stateId=${value?.id}`;
        props.typeaheadParams = isTypeAhead ? { stateId: value?.id } : null;
        props.url = value?.id ? ternaryCityEndpoint : '';
      }
      break;
    case 'zip': {
      isTypeAhead = value?.total_zips > LimitLocalDataOfSelectors.zip;
      props.typeaheadParams = isTypeAhead ? { cityId: value?.id } : null;
      break;
    }
    default:
      return {};
  }
  props.typeahead = isTypeAhead;
  props.typeaheadLimit = isTypeAhead ? 50 : null;
  return props;
};

const LocationForm = ({
  state,
  city,
  zip,
  handleComboChange,
  isEditing,
  removeRequiredMark,
  disabled
}: LocationFormProps) => {
  const { register, errors } = useFormContext();

  useEffect(() => {
    register({ name: 'state_id' }, REQUIRED_VALIDATION);
    register({ name: 'city_id' }, REQUIRED_VALIDATION);
    register({ name: 'zip' }, REQUIRED_VALIDATION);
  }, [register]);

  const getPlaceholder = key => (removeRequiredMark ? noRequired[key] : required[key]);

  const { sm, xs, md, lg } = getGridColumnSize(isEditing);

  return (
    <>
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <AutocompleteSelect
          name="state_id"
          selectedValue={state}
          placeholder={getPlaceholder('country')}
          error={!!errors.state_id}
          errorText={errors.state_id && errors.state_id.message}
          url={Endpoints.States}
          onSelect={handleComboChange}
          groupBy={option => option.country_title}
          getOptionLabel={countryStateOptionLabel}
          renderOption={titleOptionLabel}
          getOptionSelected={idOptionSelected}
          noMargin
          autocomplete="nothing"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <AutocompleteSelect
          name="city_id"
          placeholder={getPlaceholder('city')}
          error={!!errors.city_id}
          errorText={errors.city_id && errors.city_id.message}
          selectedValue={city}
          onSelect={handleComboChange}
          getOptionSelected={idOptionSelected}
          {...getCustomPropsForTypeAhead(state, 'city')}
          noMargin
          autocomplete="nothing"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <AutocompleteSelect
          name="zip"
          placeholder={getPlaceholder('zip')}
          error={!!errors.zip}
          errorText={errors.zip && errors.zip.message}
          url={city?.id ? `${Endpoints.Cities}/${city.id}/${Endpoints.Zips}` : ''}
          selectedValue={zip}
          onSelect={handleComboChange}
          getOptionSelected={idOptionSelected}
          {...getCustomPropsForTypeAhead(city, 'zip')}
          noMargin
          autocomplete="nothing"
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

LocationForm.defaultProps = {
  disabled: false,
  isEditing: false,
  removeRequiredMark: false
};

export default LocationForm;
