import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

/**
 * The `createQueryParams` function creates a query string from an object of parameters, omitting any
 * parameters with `null` or `undefined` values.
 * @returns The function `createQueryParams` is returning a string representation of the query
 * parameters after filtering out any parameters that are `null` or `undefined`.
 */
export const createQueryParams = params => {
  // Note: here the spaces are replaced with + instead of %20
  return new URLSearchParams(omitBy(params, isNil)).toString();
};
