import React, { useCallback } from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { InlineFilters } from 'features/command-center';
import { ActivityTable } from 'features/command-center/components/activity/ActivityTable';
import { ActivitiesListing } from 'features/command-center/components/activity/ActivityTable/ActivitiesListing';
import { NotRegisteredCallEntity } from 'features/command-center/components/activity/ActivityTable/ActivityTable.constants';
import {
  buildDateRangeFilters,
  processNumberOfContactsFilters
} from 'features/command-center/components/activity/ActivityTable/ActivityTable.utils';
import { useInlineFiltersContext } from 'features/command-center/components/shared/InlineFilters/InlineFiltersProvider';
import { INLINE_FILTERS_KEYS } from 'features/command-center/constants';
import { useRecruiterInfoContext } from 'features/command-center/contexts';
import omit from 'lodash/omit';
import { useFeatureFlags } from 'providers/FeatureFlagsProvider';
import { useUserContext } from 'providers/UserProvider';
import { EntityType } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { getDefaultDashboardPeriod } from 'UI/utils';

const GLOBAL_FILTERS = [
  'dateRange',
  'activityType',
  'emailVerdicts',
  'industries',
  'specialties',
  'subspecialties',
  'countries',
  'state',
  'cities'
];

const extraFilters = [
  {
    type: 'dateRange',
    name: 'dateRange',
    placeholder: 'Date Range',
    defaultRange: getDefaultDashboardPeriod()
  }
];

export const Activity = ({ recruiterId }) => {
  const { filters, handleFiltersChange } = useInlineFiltersContext();
  const [currentUser] = useUserContext();
  const { recruiterData } = useRecruiterInfoContext();
  const { checkIfFeatureFlagEnabled } = useFeatureFlags();

  const isOwner = currentUser.id === recruiterData?.userId;

  const isInlineEditionEnabled =
    isOwner && checkIfFeatureFlagEnabled(FeatureFlags.ActivityOutcomeInlineEdition);

  const renderTable = useCallback(
    ({ entity, entityConfig, endpoint, filters: filterValues }) => {
      const getFinalFilters = () => {
        const processedFilters = processNumberOfContactsFilters(filterValues);
        const dateRangeFilters = buildDateRangeFilters(
          filters.dateRange?.value || getDefaultDashboardPeriod()
        );

        return {
          ...omit(filters, 'dateRange'),
          ...processedFilters,
          ...dateRangeFilters
        };
      };

      const finalFilters = getFinalFilters();

      return (
        <ActivitiesListing
          entity={entity}
          entityConfig={entityConfig}
          endpoint={endpoint}
          columnsDefinitions={entityConfig.columns}
          filters={finalFilters}
          canExpandRow={isInlineEditionEnabled}
        />
      );
    },
    [filters, isInlineEditionEnabled]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <InlineFilters
          persistenceKey={INLINE_FILTERS_KEYS.activity.recruiterGlobal}
          filters={GLOBAL_FILTERS}
          extraFilters={extraFilters}
          onFiltersChange={handleFiltersChange}
        />
        <Divider />
      </Grid>

      <Grid item container xs={12}>
        <ActivityTable
          entity={EntityType.Candidate}
          recruiterId={recruiterId}
          renderTable={renderTable}
        />
      </Grid>

      <Grid item container xs={12}>
        <ActivityTable
          entity={EntityType.HiringAuthority}
          recruiterId={recruiterId}
          renderTable={renderTable}
        />
      </Grid>

      <Grid item container xs={12}>
        <ActivityTable
          entity={EntityType.Contact}
          recruiterId={recruiterId}
          renderTable={renderTable}
        />
      </Grid>

      <Grid item container xs={12}>
        <ActivityTable
          entity={EntityType.Company}
          recruiterId={recruiterId}
          renderTable={renderTable}
        />
      </Grid>

      <Grid item container xs={12}>
        <ActivityTable
          entity={NotRegisteredCallEntity}
          recruiterId={recruiterId}
          renderTable={renderTable}
        />
      </Grid>
    </Grid>
  );
};
