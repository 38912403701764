// @flow
import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { HTTPStatusCodes } from 'constants/httpStatusCodes';
import { useGlobalStyles } from 'GlobalStyles';
import usePageTitle from 'hooks/usePageTitle';
import strings from 'strings';
import FPActionButton from 'UI/components/atoms/FPActionButton';
import { FPCard } from 'UI/components/atoms/FPCard';
import { DataSheetFooter } from 'UI/components/molecules/DataSheetFooter';
import FPFormStatus from 'UI/components/molecules/FPFormStatus';
import DecisionDialog from 'UI/components/organisms/DecisionDialog';
import ContentPageLayout, {
  ContentPageLayoutModes
} from 'UI/components/templates/ContentPageLayout';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { PageTitles } from 'UI/constants/defaults';
import { RequestStatus, UIStatus } from 'UI/constants/status';
import { SvgNoJobOrders } from 'UI/res';

import ErrorPage from '../ErrorPage';

import useCandidateDataSheet from './hooks/useCandidateDataSheet';
import { EditCandidateDataSheetStrings } from './EditCandidateDataSheet.constants';
import {
  AnimationContainer,
  EditDataSheetCardContent,
  MainContainer,
  ProgressLabel,
  StyledForm
} from './EditCandidateDataSheet.styled';

const { contentPageTitle } = strings.candidates.editDataSheet;
const HEADER_PROPS = {
  title: strings.candidates.editDataSheet.title,
  subheader: strings.candidates.editDataSheet.subheader,
  variant: 'section',
  actionMode: 'noAction'
};

type EditCandidateDataSheetProps = {
  match: {
    params: {
      id: string
    }
  },
  history: {
    push: Function
  }
};

const EditCandidateDataSheet = ({ match, history }: EditCandidateDataSheetProps) => {
  usePageTitle({ title: PageTitles.EditCandidateDataSheet, isLoading: false });
  const globalClasses = useGlobalStyles();
  const {
    parsedSchemaData,
    uiStatus,
    formData,
    canSave,
    commonStyledFormProps,
    dataSheetProgress,
    isSaving,
    isDraftDialogOpen,
    canUserEdit,
    handleClose,
    handleOnChange,
    handleOnSubmit,
    handleOnSave,
    handleOnError,
    handleOnConfirm,
    handleCancel,
    toggleDraftDialog
  } = useCandidateDataSheet({
    profileId: match.params.id,
    history
  });

  if (!canUserEdit) {
    return <ErrorPage error={HTTPStatusCodes.Unauthorized} />;
  }

  if (uiStatus === UIStatus.Loading || !parsedSchemaData) {
    return (
      <ContentPageLayout
        mode="mainLayout"
        text={contentPageTitle}
        titleLabelProps={{ backNavigation: true }}
      >
        <AnimationContainer>
          <CircularProgress />
        </AnimationContainer>
      </ContentPageLayout>
    );
  }

  if (uiStatus === RequestStatus.Error) {
    return (
      <ContentPageLayout>
        <EmptyPlaceholder
          {...strings.shared.errors.serverError}
          customEmptyState={<SvgNoJobOrders height={400} />}
        >
          <FPActionButton text="Try again" onClick={() => window.location.reload()} />
        </EmptyPlaceholder>
      </ContentPageLayout>
    );
  }

  const finalHeaderProps = {
    ...HEADER_PROPS,
    title: (
      <Box display="flex" flexDirection="row" fontWeight="normal" alignItems="center">
        <ProgressLabel component="span">{HEADER_PROPS.title}</ProgressLabel>&nbsp;
        {dataSheetProgress && (
          <FPFormStatus
            status={dataSheetProgress.key}
            totalQuestions={dataSheetProgress.total}
            totalAnsweredQuestions={dataSheetProgress.current}
            shouldShowTotal
          />
        )}
      </Box>
    )
  };

  const CustomDialogActions = ({ defaultButtonsProps, ActionsMapper }) => {
    const { onClose: closeDialog } = defaultButtonsProps;

    return (
      <ActionsMapper
        buttons={[
          {
            variant: 'outlined',
            text: EditCandidateDataSheetStrings.decisionDialog.buttons.cancel,
            onClick: closeDialog
          },
          {
            variant: 'outlined',
            text: EditCandidateDataSheetStrings.decisionDialog.buttons.exitWithoutSaving,
            onClick: handleClose
          },
          {
            variant: 'contained',
            text: EditCandidateDataSheetStrings.decisionDialog.buttons.saveAndExit,
            onClick: handleOnSave
          }
        ]}
      />
    );
  };

  return (
    <ContentPageLayout
      mode={ContentPageLayoutModes.Main}
      text={contentPageTitle}
      titleLabelProps={{
        backNavigation: true,
        mode: 'customAction',
        onCustomBackClick: handleCancel
      }}
    >
      <MainContainer className={globalClasses.itemCreationDataWrapper}>
        <FPCard>
          <EditDataSheetCardContent variant="relaxed" headerProps={finalHeaderProps}>
            <Alert severity="warning">{strings.candidates.profile.dataSheet.hint}</Alert>
            <StyledForm
              schema={parsedSchemaData.structure}
              uiSchema={parsedSchemaData.ui}
              formData={formData}
              onSubmit={handleOnSubmit}
              onChange={handleOnChange}
              onError={handleOnError}
              {...commonStyledFormProps}
            />
          </EditDataSheetCardContent>
        </FPCard>
      </MainContainer>
      <DataSheetFooter
        isSaving={isSaving}
        canSave={canSave}
        onSave={handleOnSave}
        onClose={handleCancel}
      />
      <DecisionDialog
        title={EditCandidateDataSheetStrings.decisionDialog.title}
        message={EditCandidateDataSheetStrings.decisionDialog.message}
        isOpened={isDraftDialogOpen}
        onClose={toggleDraftDialog(false)}
        onConfirm={handleOnConfirm}
        CustomActions={CustomDialogActions}
        severity="warning"
        showSeverityIcon
        mustComplete
        withButtons="YesNo"
      />
    </ContentPageLayout>
  );
};

export default EditCandidateDataSheet;
