// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBulkEmailToInitial, startRemovingAllAttachments } from 'actions/bulkEmail';
import useDatatable, {
  buildButtonRendererDefinitionByObject,
  getColumnPreferences,
  getColumnsToRender
} from 'hooks/datatable';
import { useUrlParamsUpdater } from 'hooks/urlParamsUpdater';
import usePageTitle from 'hooks/usePageTitle';
import { When } from 'UI/components/atoms/When';
import AutocompleteSelect from 'UI/components/molecules/AutocompleteSelect';
import BulkEmailDrawer from 'UI/components/organisms/BulkEmailDrawer';
import DataTable from 'UI/components/organisms/DataTable';
import RestoreSearchProjectDialog from 'UI/components/organisms/SearchProjects/RestoreSearchProjectDialog.jsx';
import FiltersLayout from 'UI/components/templates/FiltersLayout';
import { backNavigateListConfig } from 'UI/constants/config';
import { PageTitles } from 'UI/constants/defaults';
import { Endpoints } from 'UI/constants/endpoints';
import { EntityType, ListingFilters } from 'UI/constants/entityTypes';
import { FeatureFlags } from 'UI/constants/featureFlags';
import { hasFeatureFlag } from 'UI/utils';

import {
  ArchiveSearchProjectColumnsDefinitions,
  ColumnsDefinitions,
  FiltersGroup,
  VirtualProps
} from './columns';

const PAGE_KEY = 'searchProjects-list';

const orderByOptions = {
  column: 'created_at',
  direction: 'desc'
};

const archivedUserFilter = { id: 4, title: 'My Archived Search Projects' };
const userFilters = [
  { id: 1, title: 'My Search Projects' },
  { id: 3, title: 'My Collaborations' },
  { id: 2, title: 'All Search Projects' }
];

const SearchProject = ({ history, location }) => {
  usePageTitle({ title: PageTitles.SearchProject });
  const isArchiveFeatureActive = hasFeatureFlag(FeatureFlags.SearchProjectsArchive);
  const finalColumnsDefinitions = isArchiveFeatureActive
    ? [...ColumnsDefinitions, ...ArchiveSearchProjectColumnsDefinitions]
    : ColumnsDefinitions;
  const initialPreferences = getColumnPreferences(
    PAGE_KEY,
    0,
    orderByOptions,
    finalColumnsDefinitions,
    0,
    location.search
  );

  const [searchProject, setSearchProject] = useState(null);
  const [uiState, setUiState] = useState({
    isBulkDrawerOpen: false,
    isRestoreSearchProjectDialogOpen: false,
    fetchingDialog: false
  });

  const extendParams = useCallback(({ userFilter: usrFilter }, extendFilters) => {
    const optionalFilters = {};
    Object.keys(extendFilters).forEach(key => {
      optionalFilters[key] = extendFilters[key].value[key];
    });

    return {
      searchProjectFilter: usrFilter?.id,
      ...optionalFilters
    };
  }, []);

  const {
    columnOrder,
    columnPreferences,
    count,
    data,
    filters,
    getData,
    handleColumnDisplayChange,
    handleColumnSortChange,
    handleFiltersApply,
    handleFiltersChange,
    handleFiltersToggle,
    handleKeywordChange,
    handleOneFilterChange,
    handlePageChange,
    handlePerPageChange,
    listState
  } = useDatatable({
    columnsDefinitions: finalColumnsDefinitions,
    endpoint: Endpoints.SearchProjects,
    entityName: 'Search Projects',
    entityType: EntityType.SearchProjects,
    excludeUserFilters: [ListingFilters.FreeGame, ListingFilters.MyDig, ListingFilters.MyTeam],
    initialPreferences,
    key: PAGE_KEY,
    orderByOptions,
    paramsExtender: extendParams,
    shouldScrollOnNavigate: true,
    virtualProps: VirtualProps
  });

  const { columns, orderBy, direction } = columnPreferences;
  useUrlParamsUpdater({ history, location, orderBy });

  const { isLoading, showWholeSkeleton, isSideMenuOpen, page, perPage, keyword, userFilter } =
    listState;

  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(setBulkEmailToInitial());
      dispatch(startRemovingAllAttachments());
    },
    [dispatch]
  );

  const handleSendBulk = ({ rowIndex }) => {
    const {
      name: { id, name },
      total_items
    } = data[rowIndex];
    const searchProjectAux = { id, name, totalItems: total_items };
    setSearchProject(searchProjectAux);
    setUiState(prev => ({ ...prev, isBulkDrawerOpen: true }));
  };

  const handleOpenRestoreSearchProjectDialog = ({ rowIndex }) => {
    const {
      id,
      name: { name },
      archived_at
    } = data[rowIndex];
    setSearchProject({ id, name, archivedAt: archived_at });
    setUiState(prev => ({ ...prev, isRestoreSearchProjectDialogOpen: true }));
  };

  const handleCloseRestoreSearchProjectDialog = () => {
    setSearchProject(null);
    setUiState(prev => ({ ...prev, isRestoreSearchProjectDialogOpen: false }));
  };

  const handleCloseBulkEmailDrawer = () => {
    setSearchProject(null);
    setUiState(prev => ({ ...prev, isBulkDrawerOpen: false }));
  };

  const buttonOptionsToRender =
    listState?.userFilter?.id === archivedUserFilter.id
      ? {
          buttonName: 'Restore',
          onClick: handleOpenRestoreSearchProjectDialog
        }
      : {
          buttonName: 'Send Bulk',
          onClick: handleSendBulk,
          additionalPropsBuilder: rowIndex => {
            const { total_items: spTotalItems, bulkable_items } = data[rowIndex];
            const hasntBulkableItems = spTotalItems === 0 || bulkable_items === 0;
            return { disabled: hasntBulkableItems };
          }
        };

  const finalColumns = getColumnsToRender([
    ...columns,
    buildButtonRendererDefinitionByObject(buttonOptionsToRender)
  ]);

  return (
    <FiltersLayout
      title="Search Projects"
      section={EntityType.SearchProjects}
      listSelector={
        <AutocompleteSelect
          name="userFilter"
          placeholder="Search Projects to Show"
          selectedValue={userFilter || userFilters[0]}
          onSelect={handleOneFilterChange}
          defaultOptions={
            isArchiveFeatureActive ? [...userFilters, archivedUserFilter] : userFilters
          }
        />
      }
      filters={filters}
      disableDateRange
      isSideMenuOpen={isSideMenuOpen}
      isLoading={isLoading}
      groups={FiltersGroup}
      enableStore={false}
      onSearch={handleFiltersApply}
      onFiltersChange={handleFiltersChange}
      onMenuToggle={handleFiltersToggle}
      titleLabelProps={backNavigateListConfig}
    >
      <DataTable
        isExpandable
        loading={showWholeSkeleton}
        refreshing={isLoading}
        data={data}
        columns={finalColumns}
        count={count}
        page={page}
        filter={false}
        rowsPerPage={perPage}
        searchText={keyword}
        enableCustomFilters
        draggableColumns={{
          enabled: true
        }}
        sortOrder={{ name: orderBy, direction }}
        columnOrder={columnOrder?.length ? columnOrder : undefined}
        onToggleFilters={handleFiltersToggle}
        onSearchTextChange={handleKeywordChange}
        onColumnSortChange={handleColumnSortChange}
        onPerPageClick={handlePerPageChange}
        onPageClick={handlePageChange}
        onColumnDisplayClick={handleColumnDisplayChange}
        selectableRows="none"
      />
      <When condition={uiState.isBulkDrawerOpen}>
        <BulkEmailDrawer searchProject={searchProject} onCloseDrawer={handleCloseBulkEmailDrawer} />
      </When>
      <When condition={uiState.isRestoreSearchProjectDialogOpen}>
        <RestoreSearchProjectDialog
          fetchingDialog={uiState.fetchingDialog}
          onCloseDialog={handleCloseRestoreSearchProjectDialog}
          onFetchingDialog={fetchingDialog => setUiState(prev => ({ ...prev, fetchingDialog }))}
          onSuccess={() => getData()}
          searchProject={searchProject}
        />
      </When>
    </FiltersLayout>
  );
};

export default SearchProject;
