import { EntityRoutes } from 'routes/constants';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'fullName',
    label: 'Full Name',
    options: {
      sort: true,
      display: true,
      renderer: 'contactLink'
    }
  },
  {
    name: 'role',
    label: 'Role',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'status',
    label: 'Item Type',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'company',
    label: 'Company',
    options: {
      sort: true,
      display: true,
      renderer: {
        id: 'linkBuilder',
        config: {
          route: EntityRoutes.CompanyProfile,
          idKey: 'companyId',
          textKey: 'company',
          enableInTabRedirect: true
        }
      }
    }
  },
  {
    name: 'lastActivityDate',
    label: 'Last Activity Date',
    options: {
      sort: true,
      display: true,
      renderer: 'date'
    }
  }
];

export const VIRTUAL_PROPS = [
  {
    propName: 'company',
    attributes: ['companyId', 'company']
  },
  {
    propName: 'fullName',
    attributes: ['id', 'fullName', 'originTableId', 'roleId']
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'id',
  direction: 'asc'
};
