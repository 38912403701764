import GpacGeneratorForm from '@gogpac/form-generator';
import { styled } from '@material-ui/core';
import { FPCardContent } from 'UI/components/atoms/FPCard';

export const MainContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(6)
}));

export const AnimationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%'
});

export const EditDataSheetCardContent = styled(FPCardContent)(({ theme }) => ({
  maxWidth: theme.typography.pxToRem(700)
}));

export const StyledGpacGeneratorForm = styled(GpacGeneratorForm)(({ theme }) => ({
  '& div[id*="section3_skills__title"]': {
    margin: 0
  },
  '& h6[id*="section3_skills__description"]': {
    marginTop: '0 !important',
    marginBottom: theme.spacing(1)
  }
}));
