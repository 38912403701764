import React from 'react';
import strings from 'strings';
import { When } from 'UI/components/atoms/When';
import FPQuickView from 'UI/components/organisms/inventoryProfiles/FPQuickView';
import EmptyPlaceholder from 'UI/components/templates/EmptyPlaceholder';
import { UIStatus } from 'UI/constants/status';
import { SvgDefaultEmptyState } from 'UI/res';

import { Chat, MessageComposer } from '../messageChat/components';

import { useStyles } from './styles';

export const ConversationContent = ({
  chat,
  chatStatus,
  handleClickRetry,
  handleClickSend,
  handleFetchMoreMessages,
  hasMoreMessages,
  isQuickViewOpen,
  toggleQuickViewOpen,
  triggerScrollToBottom,
  selectedConversationInList
}) => {
  const classes = useStyles();

  if (!selectedConversationInList)
    return (
      <EmptyPlaceholder
        subtitle={strings.messageCenter.emptyState.unselected}
        customEmptyState={<SvgDefaultEmptyState height={400} />}
      />
    );

  const {
    externalNumber = '',
    fullName = '',
    entityId = '',
    entityType = ''
  } = selectedConversationInList;

  return (
    <>
      <div className={classes.conversationContainer}>
        <div className={classes.chatContainer}>
          <Chat
            chat={chat}
            chatStatus={chatStatus}
            hasMoreMessages={hasMoreMessages}
            onClickRetry={handleClickRetry}
            onFetchMoreMessages={handleFetchMoreMessages}
            triggerScrollToBottom={triggerScrollToBottom}
          />
        </div>

        <div className={classes.messageComposerContainer}>
          <MessageComposer
            disabled={chatStatus !== UIStatus.Success}
            entityId={entityId}
            entityType={entityType}
            externalNumber={externalNumber}
            onClickSend={handleClickSend}
            recipientFullName={fullName}
          />
        </div>
      </div>

      <When condition={entityId && entityType && isQuickViewOpen}>
        <FPQuickView
          id={entityId}
          drawerProps={{
            open: isQuickViewOpen
          }}
          entityType={entityType}
          onClose={toggleQuickViewOpen}
        />
      </When>
    </>
  );
};
