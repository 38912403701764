// @flow

export const FormFieldsMap = {
  ActivityOutcome: {
    key: 'activityOutcome'
  },
  ActivityOutcomeComments: {
    key: 'activityOutcomeComments'
  }
};

export const FormFields = [
  FormFieldsMap.ActivityOutcome.key,
  FormFieldsMap.ActivityOutcomeComments.key
];
