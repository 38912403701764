export const FORM_FIELDS_MAP = {
  name: {
    key: 'name'
  },
  parentFolder: {
    key: 'parentFolder'
  },
  privacyScope: {
    key: 'scope'
  },
  addEmployees: {
    key: 'addEmployees'
  },
  addHiringAuthorities: {
    key: 'addHiringAuthorities'
  },
  extractContacts: {
    key: 'extractContacts'
  },
  isMasterList: {
    key: 'isMasterList'
  },
  geoPolygon: {
    key: 'geoPolygon'
  }
};
