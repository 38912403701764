import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  footerTitle: {
    display: 'flex'
  },
  label: {
    paddingLeft: theme.spacing(1)
  },
  list: {
    margin: theme.spacing(0.5)
  },
  title: {
    fontWeight: 'bold'
  }
}));
