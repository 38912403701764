import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getRecruiterInfo } from 'features/command-center/services';

const RecruiterInfoContext = React.createContext();

export const RecruiterInfoProvider = ({ children, recruiterId, onRecruiterLoaded }) => {
  const [recruiterData, setRecruiterData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getData = useCallback(async () => {
    const requestParams = { employeeIds: recruiterId };
    try {
      setIsLoading(true);
      const response = await getRecruiterInfo(requestParams);
      setRecruiterData(response?.data ?? response);
      onRecruiterLoaded && onRecruiterLoaded(response?.data ?? response);
    } finally {
      setIsLoading(false);
    }
  }, [recruiterId, onRecruiterLoaded]);

  useEffect(() => {
    getData();
  }, [getData]);

  const context = useMemo(() => {
    return {
      recruiterData,
      isLoading
    };
  }, [recruiterData, isLoading]);

  return <RecruiterInfoContext.Provider value={context}>{children}</RecruiterInfoContext.Provider>;
};

export const useRecruiterInfoContext = () => {
  const context = useContext(RecruiterInfoContext);
  if (!context) {
    throw new Error('useRecruiterInfoContext must be used within a RecruiterInfoContext');
  }
  return context;
};
