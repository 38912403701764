export const OUTBOUND_DIRECTION = 'Outbound';

export const COLUMNS = [
  {
    name: 'id',
    options: {
      filter: false,
      display: 'excluded'
    }
  },
  {
    name: 'fullName',
    label: 'Created By',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'externalPhoneNumber',
    label: 'To',
    options: {
      sort: true,
      display: true,
      renderer: 'phoneLinkRCWithCountryCode'
    }
  },
  {
    name: 'relatedContacts',
    label: 'Registered Contacts',
    options: {
      sort: false,
      display: true,
      renderer: 'itemLink'
    }
  },
  {
    name: 'totalContacts',
    label: 'Phone Contacts',
    options: {
      sort: false,
      display: true,
      renderer: 'contactsDetails'
    }
  },
  {
    name: 'relatedCompanies',
    label: 'Registered Companies',
    options: {
      sort: false,
      display: true,
      renderer: 'itemLink'
    }
  },
  {
    name: 'totalCompanies',
    label: 'Phone Companies',
    options: {
      sort: false,
      display: true,
      renderer: 'companiesDetails'
    }
  },
  {
    name: 'startTime',
    label: 'Start Time',
    options: {
      sort: true,
      display: true,
      renderer: 'dateWithTimezone'
    }
  },
  {
    name: 'startTimeLocal',
    label: 'Start Time (Local)',
    options: {
      sort: true,
      display: false,
      renderer: 'date'
    }
  },
  {
    name: 'direction',
    label: 'Direction',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'result',
    label: 'Call Result',
    options: {
      sort: true,
      display: true
    }
  },
  {
    name: 'duration',
    label: 'Duration',
    options: {
      sort: true,
      display: true,
      renderer: 'phoneTime'
    }
  }
];

export const ORDER_BY_OPTIONS = {
  column: 'startTime',
  direction: 'asc'
};
